import { MicroserviceNames } from '../types/names';

import { isTestingEnv, isProductionEnv, isDevelopEnv } from '@shippypro/utils';

/**
 * Returns the right url configuration for a specific Microservice, depending on the current environment.
 * Will error out if an unknown Microservice is passed or has not been configured yet.
 *
 * @param {MicroserviceNames} name The name of the Microservice
 * @returns {string} The URL of the Microservice
 */
export const getMicroserviceUrl = (name: MicroserviceNames) => {
  const isProduction = isProductionEnv();
  const isTesting = isTestingEnv() || isDevelopEnv();

  switch (name) {
    case MicroserviceNames.ORDERS_SERVICE:
      return isProduction
        ? 'https://order.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://order.services.shp-dev.com/api/v1'
          : 'https://order-service.shippypro.local/api/v1';
    case MicroserviceNames.PRIVACY_ENGINE:
      return isProduction
        ? 'https://privacy.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://privacy.services.shp-dev.com/api/v1'
          : 'http://localhost:8000/api/v1';
    case MicroserviceNames.PROFILES_ENGINE:
      return isProduction
        ? 'https://profiles.services.shippypro.com/api'
        : isTesting
          ? 'https://profiles.services.shp-dev.com/api'
          : 'https://profiles-engine.shippypro.local/api';
    case MicroserviceNames.SEARCH_ENGINE:
      return isProduction
        ? 'https://search.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://search.services.shp-dev.com/api/v1'
          : 'https://search-service.shippypro.local/api/v1';
    case MicroserviceNames.VALIDATION_ENGINE:
      return isProduction
        ? 'https://validation.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://validation.services.shp-dev.com/api/v1'
          : 'https://validation-engine.shippypro.local/api/v1';
    case MicroserviceNames.DOCUMENTS_ENGINE:
      return isProduction
        ? 'https://document.services.shippypro.com/api/v2'
        : isTesting
          ? 'https://document.services.shp-dev.com/api/v2'
          : 'https://documents-service.shippypro.local/api/v2';
    case MicroserviceNames.ANALYTICS_ENGINE:
      return isProduction
        ? 'https://analytics.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://analytics.services.shp-dev.com/api/v1'
          : // Most of the time the testing microservice is used to develop the Analytics UI locally
            'https://analytics.services.shp-dev.com/api/v1'; // 'http://localhost:4000/api/v1';
    case MicroserviceNames.WORKFLOW_ENGINE:
      return isProduction
        ? 'https://workflow.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://workflow.services.shp-dev.com/api/v1'
          : 'https://workflow-engine.shippypro.local/api/v1';
    case MicroserviceNames.AUTHNZ_ENGINE:
      return isProduction
        ? 'https://auth.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://auth.services.shp-dev.com/api/v1'
          : 'https://auth.shippypro.local/api/v1';
    default:
      throw new Error('⚠️ Unknown/Misconfigured service name');
  }
};
