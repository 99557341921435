import {
  createContext,
  useContext,
  useRef,
  useCallback,
  memo,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  ReactPortal,
} from 'react';
import { createPortal } from 'react-dom';

type PageHeaderContextType = {
  leftContentRef: MutableRefObject<HTMLDivElement | null>;
  centerContentRef: MutableRefObject<HTMLDivElement | null>;
  rightContentRef: MutableRefObject<HTMLDivElement | null>;
  setContent: (
    contentRef: MutableRefObject<HTMLDivElement | null>,
    element: ReactNode,
  ) => ReactPortal | undefined;
};

const PageHeaderContext = createContext<PageHeaderContextType | null>(null);

export const usePageHeaderContext = () => {
  const context = useContext(PageHeaderContext);
  if (!context)
    throw new Error(
      'usePageHeaderContext must be used within a PageHeaderContextProvider',
    );
  return context;
};

export const PageHeaderContextProvider = memo(
  ({ children }: PropsWithChildren) => {
    const leftContentRef = useRef<HTMLDivElement | null>(null);
    const centerContentRef = useRef<HTMLDivElement | null>(null);
    const rightContentRef = useRef<HTMLDivElement | null>(null);

    const setContent = useCallback(
      (
        contentRef: MutableRefObject<HTMLDivElement | null>,
        element: ReactNode,
      ) => {
        if (!contentRef.current) return;
        return createPortal(element, contentRef.current);
      },
      [],
    );

    return (
      <PageHeaderContext.Provider
        value={{
          leftContentRef,
          centerContentRef,
          rightContentRef,
          setContent,
        }}
      >
        {children}
      </PageHeaderContext.Provider>
    );
  },
);
