import styled from 'styled-components';
import Select from 'react-select';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const HeaderNavigation = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 7px;
  background: var(--shp-color-bg);
  border: 1px solid var(--shp-color-genericborder-input);
  border-radius: 5px;

  &:not(:disabled):hover {
    border-color: var(--shp-color-bg-dark-highlight);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const HeaderDropdownSelectors = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownSelect = styled(Select)`
  .react-select__control {
    background-color: var(--shp-color-bg);
    padding: 5px;
    min-height: unset;
    border: 1px solid transparent;
    cursor: pointer;

    :hover {
      border-color: transparent;
    }

    .react-select__value-container {
      padding: 0;

      .react-select__single-value {
        color: var(--shp-color-text-tab-title);
        font-size: 14px;
        font-weight: 500;
      }
    }

    &.react-select__control--menu-is-open {
      border-color: var(--shp-color-bg-dark-highlight);

      .react-select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }

    .react-select__dropdown-indicator {
      color: var(--shp-color-text-tab-title);
      padding: 0;
      transition: transform 0.2s;

      svg {
        width: 12px !important;
        height: 12px !important;
      }

      &:hover {
        color: var(--shp-color-text-tab-title);
      }
    }
  }

  .react-select__menu {
    width: unset;

    .react-select__menu-list {
      max-height: 240px;

      .react-select__option {
        padding: 15px;
        color: var(--shp-color-text-tab-title);
        font-size: 14px;
        
        &.react-select__option--is-focused {
          background-color: var(--shp-color-bg-highlight);
        }
        
        &.react-select__option--is-selected {
          background-color: var(--shp-color-bg-table-selected);
          font-weight: 600;
      }
    }
  }
`;

export const DropdownSelectOption = styled.div`
  padding-right: 8px;
  display: flex;
  align-items: center;
  gap: 20px;
`;
