import { FC } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Documentation, Order } from '@web/types/order';

import { TooltipWrapperByID } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { toCapital } from '@shippypro/design-system-web/functions';

import { Spinner } from 'reactstrap';
import genericFileImage from '@web/assets/img/icons/gen-file.svg';

import { parseFileExtension } from '@web/hooks/useParseFileExtension';
import { usePrintSingleOrder } from '@web/hooks/orders/usePrintSingleOrder';

import { downloadFile } from '@web/utils/functions';

export type DocumentationIconProps = {
  order: Order;
  handleDocs: (order: Order) => void;
  compactView?: boolean;
};

const DocumentationIcon: FC<DocumentationIconProps> = ({
  order,
  handleDocs,
  compactView = false,
}) => {
  const { t } = useTranslation(),
    transCommon = translations.common,
    transOrder = translations.order,
    transStatus = transOrder.statuses;

  const { printOrder, isLoading: isPrinting } = usePrintSingleOrder();

  const { documentation, id, error_message, is_printed, order_status } = order;

  // Generating the different lists for labels and non-labels documents
  let labelsArray: Documentation[] = [],
    otherDocsArray: Documentation[] = [];
  documentation?.forEach(doc => {
    if (doc.type === 'label' || doc.type === 'return_label') {
      labelsArray.push(doc);
    } else {
      otherDocsArray.push(doc);
    }
  });

  // Set up utils variables
  const hasLabel = labelsArray.length > 0;
  const hasOtherDoc = otherDocsArray.length > 0;
  const hasMore = documentation && documentation.length > 1;

  // Get the mainDoc
  const mainDoc = hasLabel
    ? labelsArray[0]
    : hasOtherDoc
      ? otherDocsArray[0]
      : null;

  // File extension and image is dynamic only for labels (epl - zpl - pdf)
  // For other documents we return a fallback with a generic file img
  const { ext, img } = parseFileExtension(
    hasLabel ? mainDoc?.file_url ?? '' : '',
  );

  // Setting tooltip dynamic id for error icons
  const docErrorId = `order-${id}-documentation-error`;

  const onClick =
    !mainDoc && order_status === '2'
      ? undefined
      : () => {
          // Print if the order has ONLY 1 document
          if (!hasMore && mainDoc && !isPrinting) {
            /* istanbul ignore if */
            if (mainDoc.type === 'label') {
              // Download file for epl as we can't convert this format in pdf
              if (mainDoc.file_url.slice(-4) === '.epl') {
                downloadFile(mainDoc.file_url);
                return;
              }
              // Send printing request for pdf label
              printOrder(order);
              /* istanbul ignore else */
            } else {
              // Opens the file in another browser tab (for non-labels document)
              window.open(mainDoc.file_url);
            }
          } else if (mainDoc) {
            // opens the drawer with the list of documents' previews (we enter here if order has more than 1 document)
            handleDocs(order);
          }
        };

  return (
    <div
      className={classnames('w-fit flex flex-col justify-center !gap-2', {
        'cursor-pointer': mainDoc || !error_message,
      })}
      onClick={onClick}
    >
      {/* Labels top section */}
      {hasLabel ? (
        <div className="flex !gap-1 items-center documentation-icon">
          <img
            src={img}
            alt={`${labelsArray[0].type}-${ext}`}
            width={25}
            height={31}
            loading="lazy"
          />
          {is_printed && (
            <TooltipWrapperByID target="printId" text={t(transStatus.printed)}>
              <span className="cursor-default print">
                <IconHelper
                  icon="IconPrinter"
                  size={15}
                  className="icon-printed"
                />
              </span>
            </TooltipWrapperByID>
          )}
          {compactView && hasMore && (
            <span className="!ml-2">+ {documentation!.length - 1}</span>
          )}
          {!compactView && labelsArray.length > 1 && (
            <span className="!ml-2">+ {labelsArray.length - 1}</span>
          )}
        </div>
      ) : order_status === '2' ? (
        <TooltipWrapperByID target={docErrorId} text={error_message ?? ''}>
          <IconHelper
            icon="IconAlertTriangle"
            size={24}
            className="text-danger"
          />
        </TooltipWrapperByID>
      ) : (
        <Spinner color="grey" size="sm" data-tag="allowRowEvents" />
      )}

      {/* Other documents bottom section */}
      {!compactView && hasOtherDoc && (
        <div className="'w-full flex items-center !gap-1 documentation-more">
          <img
            src={genericFileImage}
            alt="label-generic"
            width={25}
            height={31}
            loading="lazy"
          />
          <span className="documentation-more-number">
            {otherDocsArray.length === 1
              ? toCapital(otherDocsArray[0].type ?? '')
              : `+ ${otherDocsArray.length - 1}`}
          </span>
        </div>
      )}
    </div>
  );
};

export default DocumentationIcon;
