import { Order } from '@web/types/order';
import {
  AdditionalInfoName,
  AdditionalInfoOrderType,
} from '@web/types/order/additional-info';

/**
 * **[HOOK]** This hook returns the order type of an order,
 * taken from different sources (I.E. Amazon Info, etc...)
 *
 * @param {Order} order The order to get the type from
 * @returns {ReturnType} The order type info of the order, if any
 */
const useGetOrderOrderType =
  /* istanbul ignore next */
  (order: Order | null | undefined) => {
    return getOrderOrderType(order);
  };

export const getOrderOrderType = (order: Order | null | undefined) => {
  return order?.additional_info
    ?.find(info => info.additional_info_name === AdditionalInfoName.OrderType)
    ?.additional_info_value.split(',')
    ?.filter(type => type !== AdditionalInfoOrderType.consumer)
    ?.join(', ');
};

export default useGetOrderOrderType;
