import { FC } from 'react';

// ########################## [DESIGN SYSTEM]
import { Color } from '@shippypro/design-system-web/enums';

// ########################## [PAGE COMPONENTS]
import BadgeWithCustomHover from './BadgeWithCustomHover';

interface IItemsCountBadgeProps {
  count: number;
  tooltipText?: string;
  target?: string;
}

/**
 * Displays a simple items count badge with a tooltip
 *
 * @author Marcello Aguzzi <marcello.aguzzi@shippypro.com>
 */
export const ItemsCountBadge: FC<IItemsCountBadgeProps> = ({
  count,
  tooltipText,
  target,
}) => {
  return (
    <BadgeWithCustomHover
      color={Color.secondary}
      className="w-fit cursor-default flex items-center shrink-0 !bg-[color:--shp-color-bg-highlight] !text-purpleish"
      hasHover={!!tooltipText}
      label={count}
      target={target ?? 'order-itemscount'}
      dataTest="orders-items-count-badge"
    >
      {tooltipText}
    </BadgeWithCustomHover>
  );
};
