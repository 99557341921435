import { endOfDay } from 'date-fns';

import { formatLocale } from '@shippypro/design-system-web/functions';

export const formatDate = (
  date: string | number,
  customFormat?: string,
  removeTime: boolean = false,
): string => {
  let timeFormat = 'HH:mm a';
  return formatLocale(
    typeof date === 'number' ? new Date(date * 1000) : date,
    `${customFormat ?? 'MMM dd,'} ${removeTime ? '' : timeFormat}`,
    window.navigator.language
      ? window.navigator.language.substring(0, 2)
      : 'en',
  );
};

export const convertDateToUnixTimestamp = (date: string | Date): string => {
  /* istanbul ignore if */
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return (date.getTime() / 1000).toString();
};

/* istanbul ignore next */
export const convertDateToISOString = (date: string | Date) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return endOfDay(date).toISOString().slice(0, 10);
};

export const isValidDate = (date: string): boolean => {
  return !isNaN(Date.parse(date));
};
