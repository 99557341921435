export function arrayToQueryString(
  arr: Array<string | number>,
  queryStringKey: string,
): string {
  let str: string[] = [];
  arr.forEach(arrValue => {
    str.push(
      encodeURIComponent(queryStringKey) + '[]=' + encodeURIComponent(arrValue),
    );
  });
  return str.join('&');
}
