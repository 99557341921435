import React, { FC } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import {
  Bar,
  BarChart as DefaultBarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { IBarChartDataProps } from './types';
import { Dot } from '@ds-web/components';
import { toLocalizedDecimal } from '@ds-web/utils/functions';

/**
 * A component that render a bar chart.
 *
 * @interface IAreaChartDataProps
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const BarChart: FC<IBarChartDataProps<string, string>> = ({
  data,
  XDataKey = 'xName',
  YDataKey = 'yValue',
  allowDecimals = false,
  areaProp = {
    type: 'monotone',
    fillOpacity: 1,
    fill: '#7349DD',
    isAnimationActive: false,
  },
  handleLineClick,
}) => {
  const { t } = useTranslation(),
    transCommon = translations.common;

  return (
    <ResponsiveContainer width="98%" height="100%">
      <DefaultBarChart
        data={data}
        margin={{ right: 0, left: 0 }}
        onClick={e =>
          handleLineClick &&
          handleLineClick({
            date: e?.activeLabel ?? '',
            segment: '',
            payload: e?.activePayload?.[0],
          })
        }
      >
        <CartesianGrid vertical={false} />
        <XAxis dataKey={XDataKey} />
        <YAxis
          dataKey={YDataKey}
          tickFormatter={value => toLocalizedDecimal(value, 0, 0)}
          allowDecimals={allowDecimals}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={data =>
            data.active &&
            data.payload && (
              <div className="recharts-custom-tooltip">
                <p className="font-light">{data.label}</p>
                <div className="active">
                  {data.payload.map(i => {
                    return (
                      <div className="flex items-center" key={i.dataKey}>
                        <Dot
                          dotClassName="!relative !size-[10px] !mb-0 pt-[2px] mr-[6px]"
                          color={i.payload.fill ?? i.color}
                        />
                        <span className="flex font-bold text-xl text-black">
                          {i.payload[i.dataKey ?? 0]}
                        </span>
                      </div>
                    );
                  })}
                </div>
                {handleLineClick && (
                  <>
                    <hr />
                    <p className="text-sm font-light">
                      {t(transCommon.showDetailPreview)}
                    </p>
                  </>
                )}
              </div>
            )
          }
        />
        <Bar
          type={areaProp.type}
          dataKey={YDataKey}
          fillOpacity={areaProp.fillOpacity}
          fill={areaProp.fill}
          radius={[3, 3, 0, 0]}
          isAnimationActive={areaProp.isAnimationActive}
          className={classnames({
            'cursor-pointer bar-active': handleLineClick,
          })}
        />
      </DefaultBarChart>
    </ResponsiveContainer>
  );
};
