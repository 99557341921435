import React, { FC, useCallback, useState } from 'react';

import {
  PieChart as DefaultPieChart,
  Pie as DefaultPie,
  ResponsiveContainer,
  Sector,
} from 'recharts';

import { IPieChartDataProps } from './types';
import { PieSectorDataItem } from 'recharts/types/polar/Pie';

/**
 * A component that render a custom pie chart.
 *
 * @interface IPieChartDataProps
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const PieChart: FC<IPieChartDataProps<string, string>> = ({
  data,
  nameKey = 'name',
  valueKey = 'value',
  cx = '50%',
  cy = '50%',
  innerRadius = 60,
  outerRadius = 80,
  innerStroke = 'white',
  innerFill = '#7349DD',
  centerString,
  handleLineClick,
}) => {
  // State to track the currently active index of the pie slice
  const [activeIndex, setActiveIndex] = useState<number>(0);

  // Event handler for mouse enter events on the pie slices
  const onPieEnter = (_: void, index: number) => {
    // Update the active index to the index of the slice being hovered
    setActiveIndex(index);
  };

  // Memoized callback to render the active shape with custom styling
  const renderActiveShape = useCallback(
    (props: PieSectorDataItem) => {
      // Constants and calculations for rendering sector shapes
      const RADIAN = Math.PI / 180; // Constant to convert degrees to radians
      const {
        cx = 50,
        cy = 50,
        midAngle = 0,
        innerRadius = 60,
        startAngle,
        endAngle,
        fill = innerFill,
        payload,
        percent = 0,
        value,
      } = props;

      // Calculate the position for drawing connectors and text labels
      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;

      // Determine text alignment based on the position
      const textAnchor = cos >= 0 ? 'start' : 'end';

      // Return elements to represent the active slice
      return (
        <g>
          {/* Display the center text or fallback to the payload name */}
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
            {centerString ?? payload?.[nameKey] ?? ''}
          </text>
          {/* Main sector representing the pie slice */}
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          {/* Highlight the active slice by adding an additional sector around it */}
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          {/* Connector path from the pie slice to the text labels */}
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          />
          {/* Circle at the end of the connector line */}
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          {/* Display the value of the slice */}
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
          >{`${value}`}</text>
          {/* Display the name and percentage of the slice */}
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
            textLength={payload?.[nameKey].length > 10 ? '140px' : 'auto'}
          >
            {`${payload?.[nameKey]} (${(percent * 100).toFixed(2)}%)`}
          </text>
        </g>
      );
    },
    [centerString, innerFill, nameKey, outerRadius],
  );

  return (
    <ResponsiveContainer width="98%" height="99%">
      <DefaultPieChart>
        <DefaultPie
          data={data}
          dataKey={valueKey}
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          onMouseEnter={onPieEnter}
          stroke={innerStroke}
          fill={innerFill}
          onClick={e =>
            handleLineClick &&
            handleLineClick({
              date: '',
              segment: e.name,
              payload: e,
            })
          }
        />
      </DefaultPieChart>
    </ResponsiveContainer>
  );
};
