import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';

import { isNullOrEmpty } from '@shippypro/design-system-web/functions';

export function objectToQueryString(obj: object, prefix?: string) {
  let str: string[] = [];

  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      let key = prefix ? prefix + '[' + prop + ']' : prop,
        value = obj[prop];

      if (!isNullOrEmpty(value)) {
        if (typeof value === 'object') {
          if (Object.prototype.toString.call(value) === '[object Date]') {
            value = value.toISOString();

            str.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
          } else {
            str.push(objectToQueryString(value, key));
          }
        } else {
          str.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
        }
      }
    }
  }

  return str.filter(Boolean).join('&');
}

export const mapObjectKeysToSnakeCase = (object: Record<string, any>) =>
  mapKeys(object, (_, key) => snakeCase(key));

export const mapObjectKeysToCamelCase = (object: Record<string, any>) =>
  mapKeys(object, (_, key) => camelCase(key));
