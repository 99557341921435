import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import { IDefaultTooltipProps } from '@ds-web/components/molecules/ui/charts/multi-chart-tooltip/types';

import { Dot } from '@ds-web/components';

/**
 * Default tooltip for multi bar charts
 * @param data
 * @param isAreaClickable
 * @return {JSX.Element}
 *
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
const DefaultTooltip: FC<IDefaultTooltipProps> = ({
  data,
  isAreaClickable = false,
}) => {
  const { t } = useTranslation(),
    transCommon = translations.common;

  return (
    <div className="recharts-custom-tooltip">
      <p className="font-light">{data.label}</p>
      <div className="active">
        {data?.payload?.map(i => {
          return (
            <div className="flex items-center" key={i.dataKey}>
              <Dot
                dotClassName="!relative !size-[10px] !mb-0 pt-[2px] mr-[6px]"
                color={i.payload.fill ?? i.color}
              />
              <span className="flex font-bold text-xl text-black">
                {i.payload[i.dataKey ?? 0]}
              </span>
            </div>
          );
        })}
      </div>
      {isAreaClickable && (
        <>
          <hr />
          <p className="text-sm font-light">
            {t(transCommon.showDetailPreview)}
          </p>
        </>
      )}
    </div>
  );
};

export default DefaultTooltip;
