import { IconHelper } from '@ds-web-iconhelper';
import {
  UncontrolledTooltip as Tooltip,
  PopoverBody,
} from '@ds-web/components';

import QuickFilterTag from './QuickFilter.Tag';
import * as S from './QuickFilter.styles';
import { QuickFilterProps } from './QuickFilter.props';

export const QuickFilter = ({
  filterKey,
  icon,
  name,
  value,
  setValue,
  onClear,
  onSubmit,
  mode = 'default',
  highlighted,
  tooltipText,
  popoverContent,
  onPopoverClose,
}: QuickFilterProps) => {
  const id = `quick-filter-${filterKey}`;
  const hasValue = Array.isArray(value) ? value.length > 0 : !!value;
  const isTagMode = mode === 'tag' || typeof value === 'boolean';

  if (isTagMode) {
    return (
      <QuickFilterTag
        filterKey={filterKey}
        name={name}
        value={value}
        setValue={setValue}
        onClear={onClear}
        onSubmit={onSubmit}
        standalone
        highlighted={highlighted}
        tooltipText={tooltipText}
      />
    );
  }

  return (
    <S.QuickFilterContainer
      id={id}
      data-test={id}
      data-testid={id}
      active={hasValue}
    >
      {!!icon && (
        <IconHelper
          size={20}
          icon={icon}
          color="#364959"
          data-testid={`${id}--icon`}
        />
      )}
      {!!name && (
        <S.QuickFilterLabel
          withDot={highlighted && !hasValue}
          data-testid={`${id}--label`}
        >
          {name}
        </S.QuickFilterLabel>
      )}
      {!!hasValue && (
        <QuickFilterTag
          filterKey={filterKey}
          name={name}
          value={value}
          onClear={onClear}
        />
      )}
      {!!tooltipText && !hasValue && (
        <Tooltip placement="top" target={id} data-testid={`${id}--tooltip`}>
          {tooltipText}
        </Tooltip>
      )}
      {!!popoverContent && (
        <S.QuickFilterPopover
          target={id}
          placement="bottom-start"
          onPopoverClose={onPopoverClose}
        >
          <PopoverBody>{popoverContent}</PopoverBody>
        </S.QuickFilterPopover>
      )}
    </S.QuickFilterContainer>
  );
};

export type { QuickFilterProps };
