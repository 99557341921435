import { useEffect } from 'react';

import { isTestingEnv } from '@shippypro/utils';

import {
  refiner,
  mixpanel,
  userflow,
  Attributes,
} from '@web/utils/@user-tracking';
import useLDFlag from '@web/hooks/useLDFlags';

import { useGetUser } from './api/useGetUser';
import { useGetRefinerFields } from './api/useGetRefinerFields';
import { useGetSetupData } from './api/useGetSetupData';
import useManageHotjar from './useManageHotjar';

const useIdentifyUserForTrackings = () => {
  const { user } = useGetUser();
  const { fields } = useGetRefinerFields();
  const { optimizerPremiumVersion } = useLDFlag();
  const {
    data: [onboardingData],
  } = useGetSetupData();

  // Initializing Hotjar
  useManageHotjar();

  // Initializing Refiner vars
  useEffect(() => {
    if (user && user.id && fields) {
      refiner('identifyUser', {
        id: user.id,
        name: user.full_name,
        ...fields,
      });
    }
  }, [fields, user]);

  // Initializing Mixpanel and Userflow
  useEffect(() => {
    if (user && user.id && fields && onboardingData) {
      const attrs: Attributes = {
        company_name: onboardingData.companyName,
        language: fields['Language'] as string,
        country: fields['Country'] as string,
        connected_marketplaces_count:
          parseInt(fields['ConnectedMarketplacesCount'] as string, 10) ?? null,
        connected_marketplaces:
          (fields['ConnectedMarketplaces'] as string)?.split?.(',') ?? null,
        connected_couriers_count:
          parseInt(fields['ConnectedCouriersCount'] as string, 10) ?? null,
        connected_couriers:
          (fields['ConnectedCarriers'] as string)?.split?.(',') ?? null,
        is_whitelabel_user: (fields?.['IsWhiteLabelUser'] as boolean) ?? null,
        last_payment_date: fields?.['LastPaymentDate']?.toString() ?? null,
        last_payment_expire_date:
          fields?.['LastPaymentExpireDate']?.toString() ?? null,
        first_payment_date: fields?.['FirstPaymentDate']?.toString() ?? null,
        payment_method: (fields?.['PaymentMethod'] as string) ?? null,
        plan_name: (fields?.['PlanName'] as string) ?? null,
        plan_order_range_id:
          parseInt(fields?.['PlanOrderRangeID'] as string, 10) ?? null,
        signup_date: fields?.['SignupDate']?.toString() ?? null,
        subscription_type: (fields?.['SubscriptionType'] as string) ?? null,
        optimizer_premium_user: !!optimizerPremiumVersion,
      };

      mixpanel.identify(user.id.toString());
      mixpanel.people.set({ ...attrs, $email: user.email });

      if (!isTestingEnv()) {
        userflow.identify(user.id.toString(), {
          ...attrs,
          email: user.email,
        });
      }
    }
  }, [fields, user, onboardingData, optimizerPremiumVersion]);
};

export default useIdentifyUserForTrackings;
