// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';
import StyledContainer from './styles';

// ########################## [TYPES]
import { IPageWithTabs } from '@ds-web/components/templates/with-tabs/types';

// ########################## [PAGE COMPONENTS]
import { SectionWithTopTabsNavigator } from './components/body/SectionWithTopTabsNavigator';

export interface ISectionWithTopTabsProps extends IPageWithTabs {
  dataTest?: string;
  fullHeight?: boolean;
  unpadCards?: boolean;
}

/**
 * [LAYOUT SECTION WITH TOP TABS] This components holds the main layout arrangement for a page
 * with a section with just navigation tabs at the top.
 *
 * @interface ISectionWithTopTabs
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const SectionWithTopTabs: FC<ISectionWithTopTabsProps> = memo(
  ({
    dataTest,
    tabs,
    activeTabIndex,
    toggle,
    unpadCards = true,
    fullHeight = true,
    loadInvisibleTabs = false,
  }) => {
    return (
      <StyledContainer
        data-test={dataTest}
        className="flex flex-col flex-nowrap"
        $fullHeight={fullHeight}
        $unpadCards={unpadCards}
      >
        <SectionWithTopTabsNavigator
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          toggle={toggle}
          loadInvisibleTabs={loadInvisibleTabs}
        />
      </StyledContainer>
    );
  },
);

SectionWithTopTabs.displayName = 'SectionWithTopTabs_memoized';
