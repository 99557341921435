import { FC, useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { translations } from '@shippypro/translations';
import { useTranslation } from 'react-i18next';

import {
  CopyButton,
  MailToButton,
  HoverableItemWithCopy,
} from '@shippypro/design-system-web';

import { Address } from '@web/types/common/address';

import { useGetCountries } from '@web/features/_global/hooks/api/useGetCountries';
import { useIsShipmentDutiable } from '@web/hooks/orders/useIsShipmentDutiable';

export type AddressWithActionOnHoverProps = {
  address: Address;
  showCountry?: boolean;
};

/** TODO: move to design system
 * This component renders address data (name, company, etc.) revealing actions
 * on hover (copy / send mail)
 *
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
export const AddressWithActionOnHover: FC<AddressWithActionOnHoverProps> = ({
  address,
  showCountry = false,
}) => {
  const { t } = useTranslation(),
    transOrder = translations.order.info,
    transDetailsCard = transOrder.trackingDialog.detailsCard;

  const fullAddress = [
    [address.street1, address.street2].filter(Boolean).join(', '),
    [address.zip, address.city].filter(Boolean).join(' - '),
    [address.state, address.country].filter(Boolean).join(', '),
  ].filter(Boolean);

  const { countries } = useGetCountries();
  const country = useMemo(
    () => (countries ?? []).find(c => c.alpha_2_code === address.country),
    [address.country, countries],
  );
  const isDutiableCallback = useIsShipmentDutiable();

  return (
    <>
      <HoverableItemWithCopy textToCopy={address.name}>
        {address.name ? (
          <strong>{address.name}</strong>
        ) : (
          <span className="text-muted">{t(transOrder.nameMissing)}</span>
        )}
      </HoverableItemWithCopy>
      {address.company && (
        <HoverableItemWithCopy
          textToCopy={address.company}
          className="text-[color:--shp-color-text-body]"
        >
          {address.company}
        </HoverableItemWithCopy>
      )}

      <div className="flex flex-wrap w-full justify-between items-end">
        <div className="grow">
          <HoverableItemWithCopy
            textToCopy={fullAddress.join(' ')}
            className="text-[color:--shp-color-text-body]"
          >
            <div className="truncate">
              {fullAddress.map((addressPart, i) => (
                <div className="truncate" key={i}>
                  {addressPart}
                </div>
              ))}
            </div>
          </HoverableItemWithCopy>

          {showCountry && (
            <div className="flex !gap-2 !px-2">
              {country && (
                <span className="flex items-center !gap-1 text-[color:--shp-color-text-body]">
                  <ReactCountryFlag
                    className="!w-5 !h-5"
                    countryCode={country.alpha_2_code}
                    title={country.alpha_2_code}
                    svg
                  />{' '}
                  <span>{country.alpha_3_code}</span>
                </span>
              )}
              {isDutiableCallback(address) && (
                <span className="text-[color:--shp-color-text-body]">
                  {t(transDetailsCard.extraEU)}
                </span>
              )}
            </div>
          )}
        </div>
        <div>
          <HoverableItemWithCopy textToCopy={address.phone}>
            {address.phone ?? (
              <span className="text-muted">{t(transOrder.phoneMissing)}</span>
            )}
          </HoverableItemWithCopy>

          <HoverableItemWithCopy
            textToCopy={address.email}
            reveals={
              <div className="flex space-x-1 text-[color:--shp-color-text-body]">
                <MailToButton email={address.email} />
                <CopyButton value={address.email} />
              </div>
            }
          >
            {address.email ?? (
              <span className="text-muted">{t(transOrder.emailMissing)}</span>
            )}
          </HoverableItemWithCopy>
        </div>
      </div>
    </>
  );
};
