import { FC, memo } from 'react';
import classnames from 'classnames';
import { Spinner } from 'reactstrap';

import { UncontrolledTooltip } from '@ds-web/components/atoms/ui/tooltip/UncontrolledTooltip';
import { Button } from '@ds-web/components/atoms/ui/buttons/button/Button';

import { IconHelper } from '@ds-web-iconhelper';
import { StyleType } from '@ds-web/tokens/enums';

import { IDrawerProps } from './types';

const DEFAULT_HEADER_HEIGHT = 52;

/**
 * The main `<Drawer />` component, that can shrink the content of the rest of the page when opened.
 * It switched to the basic `<OffCanvas />` component on mobile.
 *
 * @interface IDrawerProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Drawer: FC<IDrawerProps> = memo(
  ({
    header,
    headerHeight = DEFAULT_HEADER_HEIGHT,
    showButtons = true,
    leftBtnTxt,
    rightBtnTxt,
    loadingTxt,
    handleClose,
    handleLeft,
    handleRight,
    isDangerous = false,
    loading = false,
    disableLeft,
    disableRight,
    colorLeft,
    colorRight,
    bigButton,
    children,
  }) => {
    return (
      <div
        className={classnames(
          'main-drawer max-h-inherit h-full position-sticky top-0 bg-white z-20',
          {
            'border-danger': isDangerous,
            'border-primary': !isDangerous,
          },
        )}
      >
        <div
          className={classnames(
            'drawer-header p-1 flex w-full justify-between items-center z-50 text-white fill-white',
            {
              'bg-danger': isDangerous,
              'bg-primary': !isDangerous,
            },
          )}
        >
          {header}
          <IconHelper
            icon="IconX"
            id="drawer-close-icon"
            className="drawer-btn--close ml-3 hover:cursor-pointer"
            onClick={handleClose}
          />
        </div>

        <div
          className="drawer-body flex flex-col justify-between overflow-y-auto"
          style={{ height: `calc(100% - ${headerHeight}px)` }}
        >
          <div className="p-2 h-full overflow-y-auto">{children}</div>

          {bigButton && (
            <>
              <div
                id="big-button"
                className={`${
                  bigButton.className ?? ''
                } page-drawer-footer !px-6 !py-4`}
              >
                <Button
                  color={bigButton.color || 'primary'}
                  onClick={() => bigButton.handleClick()}
                  disabled={loading || bigButton.disabled}
                  className="w-full flex items-center justify-center gap-1"
                >
                  {loading && !isDangerous ? (
                    <>
                      <Spinner color="white" size="sm" />
                      <span className="ml-50">
                        {loadingTxt || bigButton.txt}
                      </span>
                    </>
                  ) : (
                    <>
                      {bigButton.icon && <IconHelper icon={bigButton.icon} />}
                      {bigButton.txt}
                    </>
                  )}
                </Button>
              </div>
              {!!bigButton.bigButtonTooltipText && (
                <UncontrolledTooltip target="big-button" placement="top">
                  {bigButton.bigButtonTooltipText}
                </UncontrolledTooltip>
              )}
            </>
          )}
          {showButtons && !bigButton && (
            <div className="page-drawer-footer !px-6 d-flex justify-content-end align-items-center !py-7">
              <Button
                color={colorLeft || 'primary'}
                onClick={() => handleLeft && handleLeft()}
                disabled={loading || disableLeft}
              >
                {loading && !isDangerous ? (
                  <>
                    <Spinner color="white" size="sm" />
                    <span className="ml-50">{loadingTxt}</span>
                  </>
                ) : (
                  leftBtnTxt
                )}
              </Button>
              <Button
                className="ml-10"
                color={colorRight || 'danger'}
                styleType={StyleType.outline}
                outline
                onClick={() => handleRight && handleRight()}
                disabled={loading || disableRight}
              >
                {loading && isDangerous ? (
                  <>
                    <Spinner color="white" size="sm" />
                    <span className="ml-50">{loadingTxt}</span>
                  </>
                ) : (
                  rightBtnTxt
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  },
);
