import { createContext } from 'react';

import { IMFAContext } from '@shippypro/foundation/settings/types';

/**
 * This context will provide all the necessary data for MFA inside the `Security` tab
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const MFAContext = createContext<IMFAContext | null>(null);

export default MFAContext;
