import { useInstanceTranslations } from '@web/hooks/translations/useInstanceTranslations';

import TranslationsContext from '@web/contexts/translations';

export const TranslationsProvider = ({ children }) => {
  const translations = useInstanceTranslations();

  return (
    <TranslationsContext.Provider value={translations}>
      {children}
    </TranslationsContext.Provider>
  );
};
