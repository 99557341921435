import * as Toastify from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Avatar } from '@ds-web/components/atoms/ui/avatar/Avatar';
import { IconHelper } from '@ds-web-iconhelper';
import { Color, BaseColor, SizeWithXXL } from '@ds-web/tokens/enums';

import { ILiteToastrProps, IToastrProps } from './types';

const ToastrTemplate = ({ ...rest }: IToastrProps) => {
  let icon = rest.icon || null;

  if (!icon) {
    switch (rest.color) {
      case Color.primary:
        icon = <IconHelper icon="IconBell" stroke={2} />;
        break;
      case Color.success:
        icon = <IconHelper icon="IconCheck" stroke={2} />;
        break;
      case Color.info:
        icon = <IconHelper icon="IconInfoCircle" stroke={2} />;
        break;
      case Color.warning:
        icon = <IconHelper icon="IconAlertTriangle" stroke={2} />;
        break;
      case Color.danger:
        icon = <IconHelper icon="IconX" stroke={2} />;
        break;
      default:
        icon = <IconHelper icon="IconCircleDot" stroke={2} />;
        break;
    }
  }

  return (
    <>
      <div className="toastify-header">
        <div className="title-wrapper">
          <Avatar
            size={SizeWithXXL.sm}
            color={rest.color as unknown as BaseColor}
            icon={icon}
            withAvatarContentPadding={false}
          />
          <h6 className="toast-title">{rest.title}</h6>
        </div>
      </div>
      <div className="toastify-body">
        <span aria-label="toast-text">{rest.desc}</span>
      </div>
    </>
  );
};

const ToastrSecondaryTemplate = ({ title }: ILiteToastrProps) => {
  return (
    <div className="toast-cnt flex justify-between items-center">
      <h6 className="toast-title">{title}</h6>
      <IconHelper icon="IconX" size={20} className="toast-icon" />
    </div>
  );
};

export const Toastr = {
  Template: ToastrTemplate,
  SecondaryTemplate: ToastrSecondaryTemplate,
  ...Toastify,
};

export default Toastr;
