import { FC, memo } from 'react';

interface IAlertIconProps {
  isMaintenance: boolean;
}

/**
 * **[COMPONENT]** The alert-icon component.
 * The Icon of the StatusPage Alert. Can be  or  mode.
 *
 * @interface IAlertIconProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const AlertIcon: FC<IAlertIconProps> = memo(({ isMaintenance }) => {
  return (
    <div className="status-alert-icon">
      {isMaintenance ? (
        <div id="status-alert-icon-maintenance">
          <svg
            width="2em"
            height="2em"
            viewBox="0 0 21 21"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id="status-embed-svg-info-icon"
          >
            <defs>
              <path
                d="M2,12 C2,6.47666667 6.47666667,2 12,2 C17.5233333,2 22,6.47666667 22,12 C22,17.5233333 17.5233333,22 12,22 C6.47666667,22 2,17.5233333 2,12 Z M4,12 C4,16.4187638 7.58123617,20 12,20 C16.4187638,20 20,16.4187638 20,12 C20,7.58123617 16.4187638,4 12,4 C7.58123617,4 4,7.58123617 4,12 Z M11,11.0029293 C11,10.4490268 11.4438648,10 12,10 C12.5522847,10 13,10.4378814 13,11.0029293 L13,15.9970707 C13,16.5509732 12.5561352,17 12,17 C11.4477153,17 11,16.5621186 11,15.9970707 L11,11.0029293 Z M12,9 C11.4477153,9 11,8.55228475 11,8 C11,7.44771525 11.4477153,7 12,7 C12.5522847,7 13,7.44771525 13,8 C13,8.55228475 12.5522847,9 12,9 Z"
                id="path-info"
              ></path>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-702.000000, -945.000000)">
                <g
                  id="global/info"
                  transform="translate(700.500000, 943.500000)"
                >
                  <mask id="mask-info" fill="white">
                    <use xlinkHref="#path-info"></use>
                  </mask>
                  <use
                    id="Combined-Shape"
                    fill="#42526E"
                    fillRule="nonzero"
                    xlinkHref="#path-info"
                  ></use>
                  <g
                    id="Neutral-/-N000"
                    mask="url(#mask-info)"
                    fill="#FFFFFF"
                    fillRule="evenodd"
                  >
                    <polygon points="0 24 24 24 24 0 0 0"></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      ) : (
        <div id="status-alert-icon-incident">
          <svg
            width="2em"
            height="2em"
            viewBox="0 0 17 17"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id="status-embed-svg-exclamation-icon"
          >
            <defs>
              <path
                d="M13.4161506,4.41651608 L19.5838494,10.5844619 C20.3671375,11.3677813 20.3659678,12.6346542 19.5838494,13.4167144 L13.4161506,19.5839547 C12.6328625,20.3671845 11.3659678,20.3660149 10.5838494,19.5839547 L4.41615055,13.4167144 C3.63286252,12.6334846 3.6340322,11.3666116 4.41615055,10.5844619 L10.5838494,4.41651608 C11.3671375,3.63319669 12.6340322,3.63436641 13.4161506,4.41651608 Z M12,14 C12.552,14 13,13.552 13,13 L13,8 C13,7.448 12.552,7 12,7 C11.448,7 11,7.448 11,8 L11,13 C11,13.552 11.448,14 12,14 Z M12,17 C12.552,17 13,16.552 13,16 C13,15.448 12.552,15 12,15 C11.448,15 11,15.448 11,16 C11,16.552 11.448,17 12,17 Z"
                id="path-error"
              ></path>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-706.000000, -763.000000)">
                <g
                  id="global/error"
                  transform="translate(702.500000, 759.500000)"
                >
                  <mask id="mask-error" fill="white">
                    <use xlinkHref="#path-error"></use>
                  </mask>
                  <use
                    id="Combined-Shape"
                    fill="#42526E"
                    fillRule="evenodd"
                    xlinkHref="#path-error"
                  ></use>
                  <g
                    id="Neutral-/-N000"
                    mask="url(#mask-error)"
                    fill="#FFFFFF"
                    fillRule="evenodd"
                  >
                    <polygon points="0 24 24 24 24 0 0 0"></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      )}
    </div>
  );
});

export default AlertIcon;
