import { useCallback } from 'react';

import { useGetSenderInfo } from '@web/features/_global/hooks/api/useGetSenderInfo';
import { useIsCountryEu } from './useIsCountryEu';
import { Address } from '@web/types/common/address';
import { useIsWindsorFrameworkDutiable } from './useIsWindsorFrameworkDutiable';

export type ShipmentDutiableCallback = (
  addressTo?: Address,
  addressFrom?: Address,
) => boolean;

/**
 * This hook receives two countries from an order (sender & recipient) and checks if said shipment is 'dutiable'.
 * With 'dutiable' we mean: "A Shipment whose countries of origin and destination differ AND at least one of them is not in the EU".
 * From https://shippypro.atlassian.net/browse/PROD-8558 the hook now take the entire address of the recipient and the country of the sender
 * to check if the shipment is dutiable with the Windsor Framework (under FF).
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {ShipmentDutiableCallback} A callback function that actually gives back the check
 */
export function useIsShipmentDutiable(): ShipmentDutiableCallback {
  const { senderInfo } = useGetSenderInfo();

  const isEuCallback = useIsCountryEu();
  const windsorFrameworkCheckCallback = useIsWindsorFrameworkDutiable();

  const senderAddress = senderInfo?.address;
  return useCallback(
    (addressTo?: Address, addressFrom?: Address) => {
      const parsedCountryFrom = addressFrom?.country ?? senderAddress?.country;
      const countryTo = addressTo?.country;

      // Check if countryTo or parsedCountryFrom is empty
      // countryTo normally it should be blank when starting a manual shipment for example
      if (!countryTo || !parsedCountryFrom) {
        return false;
      } else {
        return (
          (parsedCountryFrom !== countryTo &&
            (!isEuCallback(parsedCountryFrom, false) ||
              !isEuCallback(countryTo, false))) ||
          // Check if the shipment is dutiable with the Windsor Framework PROD-8558 (under FF)
          windsorFrameworkCheckCallback(addressTo, addressFrom)
        );
      }
    },
    [isEuCallback, windsorFrameworkCheckCallback, senderAddress],
  );
}
