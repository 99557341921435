// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useMemo } from 'react';

// ########################## [TRANSLATIONS]
import { translations } from '@shippypro/translations';
import { useTranslation } from 'react-i18next';

// ########################## [STATE MANAGEMENT]
import { parseAPIError } from '@web/utils/@reduxjs/rtk-query';

// ########################## [DESIGN SYSTEM]
import { showErrorToastr } from '@shippypro/design-system-web/functions';

// ########################## [TYPES]
import {
  EntityType,
  VerifyCurrentPasswordMutationPayload,
} from '@shippypro/foundation/settings/types';

// ########################## [HOOKS]
import { useVerifyCurrentPasswordMutation } from '@shippypro/foundation/settings/api';

/**
 * Returns RTK Query hook with renamed data field.
 * Updates one or more profile details based on entity type and entity id.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useVerifyCurrentPassword = (userId: number) => {
  const { t } = useTranslation(),
    transCommon = translations.common;

  const [
    fetchMore,
    { data, error, isLoading, isSuccess, isError, ...results },
  ] = useVerifyCurrentPasswordMutation();

  const verifyCurrentPassword = useCallback(
    async (currentPassword: string, entityType?: EntityType) => {
      const apiPayload: VerifyCurrentPasswordMutationPayload = {
        entity_type: entityType ?? EntityType.User,
        entity_id: userId,
        password: currentPassword,
      };

      const result = await fetchMore(apiPayload);
      if ('data' in result) {
        const data = result?.data;
        if (data && !data.success && data.message) {
          showErrorToastr(t(transCommon.errorFound), data.message);
        }
        return data;
      } else return null;
    },
    [fetchMore, t, transCommon.errorFound, userId],
  );

  return useMemo(
    () => ({
      verifyCurrentPassword,
      data,
      unparsedError: error,
      error: parseAPIError(error),
      isLoading,
      isSuccess,
      isError,
      ...results,
    }),
    [
      data,
      error,
      isError,
      isLoading,
      isSuccess,
      results,
      verifyCurrentPassword,
    ],
  );
};
