import { PropsWithChildren, memo } from 'react';
import styled from 'styled-components';

export type PageTitleProps = {
  bordered?: boolean;
} & PropsWithChildren;

const StyledTitle = styled.h1<Omit<PageTitleProps, 'children'>>`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1.2rem;
  color: var(--shp-color-genericui-primary);
  ${({ bordered }) =>
    bordered && 'border-right: 1px solid #E5E7EB; padding-right: 0.75rem;'};
`;

export const PageTitle = memo(
  ({ children, bordered = false }: PageTitleProps) => {
    return (
      <StyledTitle data-test="page-title" bordered={bordered}>
        {children}
      </StyledTitle>
    );
  },
);

PageTitle.displayName = 'PageTitle_memoized';
