import type { Parcel } from '@web/types/order/parcel';
import { ErrorCategory } from '@web/types/order/error';
import type { Attribute } from '../common/attribute';
import type { Address } from '../common/address';
import type { Documentation } from './documentation';
import type { Item } from './item';
import type { Note } from './note';
import type { Payment } from './payment';
import type { Shipment } from './shipment';
import { AdditionalInfo } from './additional-info';

export enum OrderCategory {
  toShip = 'toShip',
  shipped = 'shipped',
  error = 'error',
  archived = 'archived',
  deleted = 'deleted',
}

export type Order = {
  id: number;
  shippypro_id: number | null;
  external_id: string;
  items_count: number | null;
  description: string | null;
  status: string | null;
  order_status: string | null;
  additional_info: AdditionalInfo[] | null;
  documentation: Documentation[] | null;
  marketplace_id: number | null;
  marketplace_platform: string;
  marketplace_updated: boolean;
  carrier_id: number | null;
  carrier_name: string | null;
  carrier_closeout: boolean | null;
  address: Address;
  address_from: Address | null;
  total_amount: string | null;
  currency: string;
  created_at: number;
  updated_at: number;
  shipment_date: number | null;
  is_normal_return: boolean | null;
  is_printed: number | null;
  hidden: boolean;
  archived: boolean;
  merged: boolean | null;
  splitted: boolean | null;
  error_message: string | null;
  error_category: ErrorCategory | null;
  items: Item[] | null;
  notes: Note | null;
  incoterm: string | null;
  payment: Payment;
  shipping_service: string | null;
  shipment: Shipment | null;
  attributes: Attribute[] | null;
  parcels: Parcel[] | null;
  bill_account_number: string | null;
  ebay_user_id: string | null;
  ebay_sale_id: string | null;
};

export type OrderTimeline = {
  id: number;
  code: string;
  category: string;
  description: string;
  actor_user_id: number | null;
  created_at: number;
};
