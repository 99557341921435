import React, { FC } from 'react';
import classnames from 'classnames';

import {
  Bar,
  BarChart as DefaultBarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { IVerticalBarChartDataProps } from './types';
import { Dot } from '@ds-web/components';

/**
 * A component that render a vertical bar chart.
 *
 * @interface IAreaChartDataProps
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const VerticalBarChart: FC<
  IVerticalBarChartDataProps<string, string>
> = ({
  data,
  XDataKey = 'xName',
  YDataKey = 'yValue',
  areaProp = {
    type: 'monotone',
    stroke: '#7349DD',
    fillOpacity: 1,
    fill: 'url(#colorShp)',
    isAnimationActive: false,
  },
  handleLineClick,
  TooltipFooterInfoComponent,
}) => {
  return (
    <ResponsiveContainer width="98%" height="100%">
      <DefaultBarChart
        data={data}
        margin={{ right: 0, left: 0 }}
        layout="vertical"
        onClick={e =>
          handleLineClick &&
          handleLineClick({
            date: e?.activeLabel ?? '',
            segment: '',
            payload: e?.activePayload?.[0],
          })
        }
      >
        <CartesianGrid horizontal={false} />
        <XAxis type="number" />
        <YAxis dataKey={XDataKey} type="category" width={80} />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={data =>
            data.active &&
            data.payload && (
              <div className="recharts-custom-tooltip">
                <p className="font-light">{data.label}</p>
                <div className="active">
                  {data.payload.map(i => {
                    return (
                      <div className="flex items-center" key={i.dataKey}>
                        <Dot
                          dotClassName="!relative !size-[10px] !mb-0 pt-[2px] mr-[6px]"
                          color={i.payload.fill ?? i.color}
                        />
                        <span className="flex font-bold text-xl text-black">
                          {i.payload[i.dataKey ?? 0]}
                        </span>
                      </div>
                    );
                  })}
                </div>
                {handleLineClick && TooltipFooterInfoComponent && (
                  <TooltipFooterInfoComponent />
                )}
              </div>
            )
          }
        />
        <Bar
          type={areaProp.type}
          dataKey={YDataKey}
          stroke={areaProp.stroke}
          fillOpacity={areaProp.fillOpacity}
          fill={areaProp.fill}
          radius={[0, 3, 3, 0]}
          isAnimationActive={areaProp.isAnimationActive}
          className={classnames({
            'cursor-pointer bar-active': handleLineClick,
          })}
        />
      </DefaultBarChart>
    </ResponsiveContainer>
  );
};
