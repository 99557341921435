export function goToLegacy(
  route: string | null = null,
  isExternal: boolean = false,
) {
  const landingRoute = route ?? `index.html`;

  const target = isExternal ? '_blank' : '_self';

  window.open(`${process.env.NX_LEGACY_URL}/panel/${landingRoute}`, target);
}

/* istanbul ignore next */
export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('portal-token');
  window.location.href = `${process.env.NX_LEGACY_URL}/login.html`;
};
