import { FC } from 'react';
import styledComponents from 'styled-components';
import ReactSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import classnames from 'classnames';

import { Avatar } from '@ds-web/components/atoms/ui/avatar/Avatar';
import { IconHelper } from '@ds-web-iconhelper';

import { SizeWithXXL } from '@ds-web/tokens/enums';
import { getAvatarData, isNullOrEmptyString } from '@ds-web/utils/functions';

import '@ds-web/assets/scss/react/libs/react-select/_react-select.scss';

import { ISelectProps } from './types';

/**
 * A wrapper class for the `react-select: <ReactSelect />` component.
 * It hosts a variety of custom options, such as:
 * - `Icon` option - Option with a Feather/ShippyPro icon
 * - `Avatar` option - Option with an avatar component
 * - `Image` option - Option with an image
 * ...
 *
 * @interface IInputProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Select: FC<ISelectProps> = ({
  borderType,
  valid,
  invalid,
  multi,
  ...rest
}) => {
  let selectProps = {
    classNamePrefix: 'select',
    className: '',
    isMulti: multi,
    ...rest,
  };

  selectProps.className +=
    `${borderType ? ` ${borderType}` : ''}` +
    `${valid ? ` is-valid` : ''}` +
    `${invalid ? ` is-invalid` : ''}`;

  return rest.creatable ? (
    <StyledCreatableSelect {...selectProps} />
  ) : (
    <StyledReactSelect {...selectProps} />
  );
};

const { Option, Control, MultiValue } = components;
const IconOption = (props: any) => (
  <Option {...props} className={'option-with-icon'}>
    {isNullOrEmptyString(props.data.icon) ? (
      ''
    ) : (
      <IconHelper icon={props.data.icon} className="option-icon" />
    )}
    {props.data.label}
  </Option>
);

const SingleSelectedIconOption = ({ children, ...rest }: any) => {
  const selected = rest.getValue(),
    opt = selected.length
      ? rest.options.find((o: { value: any }) => o.value === selected[0].value)
      : {};

  return (
    <Control {...rest}>
      {opt && Object.keys(opt).length ? (
        <span className="icon-single-select-option">
          <IconHelper icon={opt['icon']} size={15} />
        </span>
      ) : null}{' '}
      {children}
    </Control>
  );
};

const AvatarOption = (props: any) => {
  const { color, content } = getAvatarData(props.data.label),
    data = !isNullOrEmptyString(props.data.img_url)
      ? {
          img: props.data.img_url,
        }
      : {
          color,
          content,
        };

  return (
    <Option {...props} className={'option-with-avatar'}>
      <Avatar size={SizeWithXXL.sm} {...data} />
      {props.data.label}
    </Option>
  );
};

const SingleSelectedAvatarOption = ({ children, ...rest }: any) => {
  const selected = rest.getValue(),
    opt = selected.length
      ? rest.options.find((o: { value: any }) => o.value === selected[0].value)
      : null,
    { color, content } = getAvatarData(opt ? opt['label'] : 'not found'),
    data = !opt
      ? {}
      : !isNullOrEmptyString(opt['img_url'])
        ? {
            img: opt['img_url'],
          }
        : {
            color,
            content,
          };

  return (
    <Control {...rest}>
      {opt && Object.keys(opt).length ? (
        <span className="avatar-single-select-option">
          <Avatar size={SizeWithXXL.sm} {...data} />
        </span>
      ) : null}{' '}
      {children}
    </Control>
  );
};

const ImageOption = (props: any) => {
  return (
    <Option {...props} className="!flex !items-center !gap-1">
      {props.data.img_url && (
        <img
          src={props.data.img_url}
          alt={props.data.label}
          className="max-h-[20px]"
          loading="lazy"
        />
      )}
      {props.data.label}
    </Option>
  );
};

const MultiSelectedImageOption = (props: any) => {
  return (
    <MultiValue {...props}>
      <div className="flex items-center gap-1">
        {props.data.img_url && (
          <img
            src={props.data.img_url}
            alt={props.data.label}
            className={classnames('max-h-[20px] bg-white rounded-[2px]', {
              'px-[2px]': props.data.padded ?? false,
            })}
            loading="lazy"
          />
        )}
        <span className="whitespace-nowrap overflow-hidden text-ellipsis">
          {props.data.label ?? props.data.value}
        </span>
      </div>
    </MultiValue>
  );
};

const SingleSelectedImageOption = ({ children, ...rest }: any) => {
  const selected = rest.getValue(),
    opt = selected.length
      ? rest.options.find((o: { value: any }) => o.value === selected[0].value)
      : {};
  return (
    <Control {...rest}>
      {opt && Object.keys(opt).length ? (
        <img
          src={opt['img_url']}
          alt={opt['label']}
          className={classnames('max-h-[20px] bg-white rounded-[2px]', {
            'pl-[8px]': opt['padded'] ?? false,
          })}
        />
      ) : null}{' '}
      {children}
    </Control>
  );
};

const style = `
  ${(props: { separator: boolean }) =>
    !props.separator && 'span{display: none;}'}
  .select__control {
    box-shadow: unset;
  }
  .select__input-container:before {
    display: none;
  }
`;

const StyledCreatableSelect = styledComponents(CreatableSelect)`
  ${style}
`;
const StyledReactSelect = styledComponents(ReactSelect)`
  ${style}
`;

export {
  AvatarOption,
  SingleSelectedAvatarOption,
  IconOption,
  SingleSelectedIconOption,
  ImageOption,
  MultiSelectedImageOption,
  SingleSelectedImageOption,
};
