// ########################## [IMPORTANT LIBRARIES]
import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import {
  PopoverBody,
  PopoverHeader,
  TooltipWrapperByREF,
  UncontrolledPopover,
} from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [TYPES]
import { Note } from '@web/types/order';

// ########################## [PAGE COMPONENTS]
import NotesList from '@web/components/common-features/order/notes/NotesList';

// ########################## [HOOKS]
import useParseOrderStatus from '@web/hooks/orders/useParseOrderStatus';
import { useSaveOrderNote } from '@web/features/order/hooks/api/useSaveOrderNote';
import { useGetDeviceSize } from '@shippypro/design-system-web/hooks';
import { useEditOrderNote } from '@web/features/order/hooks/useEditOrderNote';

interface INotesBadgeProps {
  orderId: number;
  orderStatus: string | null;
  notes: Note;
  showVisibleNote?: boolean;
}

/**
 * Displays a notes badge with a tooltip
 * Clicking the icon, a popover with a list of editable notes appear
 *
 * @interface INotesBadgeProps
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const NotesBadge: FC<INotesBadgeProps> = memo(
  ({ orderId, orderStatus, notes, showVisibleNote = false }) => {
    const { t } = useTranslation(),
      transTooltips = translations.ship.tooltips;

    const { isMobile, isTablet } = useGetDeviceSize();

    const category = useParseOrderStatus(orderStatus).category;
    const shippyProNote = notes?.shippypro;

    // Handle opening and closing the popover
    const [isOpen, setIsOpen] = useState(false);
    const togglePopover = () => !isEditingShippyProNote && setIsOpen(!isOpen);
    // Creating a div ref for popover
    const divRef = useRef<HTMLDivElement | null>(null);

    const notesCount = Object.values(notes).filter(Boolean).length;

    // Extract a note to show next to the badge with priority: store -> shippypro -> carrier
    const visibleNote = useMemo(
      () =>
        showVisibleNote
          ? notes.marketplace || notes.shippypro || notes.carrier || null
          : null,
      [notes.carrier, notes.marketplace, notes.shippypro, showVisibleNote],
    );

    // Notes logics: Shippy Pro note saving callback (and hook)
    const { saveOrderNote, isLoading: savingNotes } = useSaveOrderNote(
      true,
      'saveOrderNote',
    );
    const onSubmitShippyProNote = useCallback(
      async (newValue: string) => {
        if (orderId && category && newValue !== shippyProNote) {
          await saveOrderNote(orderId, category, newValue);
        }
      },
      [orderId, category, shippyProNote, saveOrderNote],
    );

    // Handle edit and update of a note.
    const {
      localShippyProNote,
      setLocalShippyProNote,
      isEditingShippyProNote,
      onSetShippyProNoteEditing,
    } = useEditOrderNote({
      notes,
      onSubmitShippyProNote,
      canEditShippyProNote: true,
    });

    return (
      <div className="notes-badge">
        <div ref={divRef}>
          <TooltipWrapperByREF
            content={`${t(transTooltips.notesCount, {
              count: notesCount,
            })}`}
          >
            <span
              className="cursor-pointer flex hover:text-primary"
              onClick={() => togglePopover}
            >
              <span
                className={classnames({
                  'p-[0.2rem] rounded-md flex items-center justify-start bg-[color:--shp-color-bg-highlight] text-truncate':
                    showVisibleNote,
                })}
              >
                <div>
                  <IconHelper icon="IconNote" size={18} />
                </div>
                {showVisibleNote && (
                  <span className="ml-1 text-sm font-medium truncate">
                    {visibleNote}
                  </span>
                )}
              </span>
              {notesCount > 1 ? (
                <span
                  className={classnames('mt-[3px] text-sm font-bold', {
                    'ml-2': showVisibleNote,
                  })}
                >
                  {showVisibleNote && '+'}
                  {showVisibleNote ? notesCount - 1 : notesCount}
                </span>
              ) : null}
            </span>
          </TooltipWrapperByREF>
        </div>
        <UncontrolledPopover
          target={divRef}
          placement={isMobile || isTablet ? 'top' : 'bottom'}
          isOpen={isOpen}
          toggle={togglePopover}
          trigger="legacy"
          popperClassName="!min-w-[300px] !max-w-[300px]"
        >
          <PopoverHeader>{t(translations.common.notes)}</PopoverHeader>
          <PopoverBody
            className="max-h-72 overflow-y-auto shadow-down"
            data-test="notes-list-wrapper"
          >
            {notes && (
              <NotesList
                notes={notes}
                editable
                isEditing={isEditingShippyProNote}
                setEditing={onSetShippyProNoteEditing}
                onChange={setLocalShippyProNote}
                onSubmit={onSubmitShippyProNote}
                disabled={savingNotes}
                localShippyProNote={localShippyProNote}
              />
            )}
          </PopoverBody>
        </UncontrolledPopover>
      </div>
    );
  },
);
