import { Except, Merge, SimplifyDeep } from 'type-fest';

export type Trigger = {
  id: number;
  type: string;
  code: string;
  name: string;
  description: string;
  object: string;
  parameters: Record<string, unknown>;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
};

export type Action = Merge<
  Except<Trigger, 'type' | 'object'>,
  {
    parameters: {
      definition?: {
        logic?: Record<string, unknown>;
      };
    };
    endpoint: string;
    method: string;
  }
>;

export type WorkflowAction = SimplifyDeep<{
  action: Action;
}>;

export type Condition = {
  json_logic: Record<string, unknown>;
  original_query: Record<string, unknown>;
};

export type Workflow = SimplifyDeep<{
  name: string;
  id: number;
  external_workflow_coordinator_id: string | null;
  created_by: number;
  status: boolean;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
  is_template: boolean;
  trigger_id: number | null;
  trigger: Trigger;
  workflow_actions: WorkflowAction[];
  conditions: Condition[];
}>;
