import { FetchArgs } from '@reduxjs/toolkit/query/react';

import { BASE_MICROSERVICE_ALLOWED_METHODS, getMicroserviceQuery } from '..';
import { getMicroserviceUrl } from '@api-client/utils';

import { MicroserviceNames } from '@api-client/types';
import { EntityType } from '@shippypro/foundation/settings/types';

type AUTHNZENGINE_MICROSERVICE_ALLOWED_METHODS =
  BASE_MICROSERVICE_ALLOWED_METHODS;

/**
 * This method scaffolds the query object for the "Auth NZ Engine" microservice API request.
 * It takes a url that goes AFTER the base one, an allowed REST method code and an optional body.
 * N.B. Said body is only used for non-GET requests.
 *
 * @param {string} url The url of the microservice endpoint.
 * @param {AUTHNZENGINE_MICROSERVICE_ALLOWED_METHODS} method The REST method code.
 * @param {ArgT} [args] The body of the request.
 * @returns {FetchArgs} The query object.
 */
export function getAuthNZEngineMicroserviceQuery<ArgT>(
  url: string,
  method: AUTHNZENGINE_MICROSERVICE_ALLOWED_METHODS,
  args?: ArgT,
): FetchArgs {
  return getMicroserviceQuery<ArgT>(
    `${getMicroserviceUrl(MicroserviceNames.AUTHNZ_ENGINE)}/${url}`,
    method,
    args,
  );
}

/**
 * This method scaffolds the query object for the "Auth NZ Engine" microservice API request.
 * It specifically targets the "security" endpoint part of the engine.
 * It takes the domain set of the user preferences object, an allowed REST method code and an optional body.
 * N.B. Said body is only used for non-GET requests.
 *
 * @param {string} url The url of the microservice endpoint.
 * @param {AUTHNZENGINE_MICROSERVICE_ALLOWED_METHODS} method The REST method code.
 * @param {EntityType} entity_type The entity type (user, wholesaler).
 * @param {number | string} entity_id The entity id.
 * @param {ArgT} [args] The body of the request.
 * @returns {FetchArgs} The query object.
 */
export function getAuthNZEngineSecurityMicroserviceQuery<
  ArgT extends {
    [key: string]: unknown | undefined;
  },
>(
  url: string,
  method: AUTHNZENGINE_MICROSERVICE_ALLOWED_METHODS,
  entity_id: number | string,
  entity_type: EntityType,
  args: ArgT,
  verification_token?: string,
): FetchArgs {
  const builtArgs = getMicroserviceQuery<ArgT>(
    `${getMicroserviceUrl(MicroserviceNames.AUTHNZ_ENGINE)}/${entity_type}/${entity_id}/security/${url}`,
    method,
    args,
  );
  if (verification_token)
    builtArgs.headers = {
      'X-ShippyPro-Verification-Token': verification_token,
    };
  return builtArgs;
}

/**
 * This method scaffolds the query object for the "Auth NZ Engine" microservice 'Validate MFA' API request.
 * It specifically targets the "verify mfa" endpoint part of the engine.
 * It takes the domain set of the user preferences object, an allowed REST method code and an optional body.
 * N.B. Said body is only used for non-GET requests.
 *
 * @param {number} mfa_id The url of the microservice endpoint.
 * @param {AUTHNZENGINE_MICROSERVICE_ALLOWED_METHODS} method The REST method code.
 * @param {EntityType} entity_type The entity type (user, wholesaler).
 * @param {string | number} entity_id The entity id.
 * @param {ArgT} [args] The body of the request.
 * @returns {FetchArgs} The query object.
 */
export function getAuthNZEngineMFAMicroserviceQuery<
  ArgT extends {
    [key: string]: unknown | undefined;
  },
>(
  mfa_id: number,
  method: 'POST' | 'DELETE',
  entity_id: string | number,
  entity_type: EntityType,
  args: ArgT,
  verification_token?: string,
): FetchArgs {
  const builtArgs = getMicroserviceQuery<ArgT>(
    `${getMicroserviceUrl(MicroserviceNames.AUTHNZ_ENGINE)}/${entity_type}/${entity_id}/security/mfa/${mfa_id}`,
    method,
    args,
  );
  if (verification_token)
    builtArgs.headers = {
      'X-ShippyPro-Verification-Token': verification_token,
    };
  return builtArgs;
}

/**
 * This method scaffolds the query object for the "Auth NZ Engine" microservice 'Session' API request.
 * It specifically targets the "session" endpoint part of the engine.
 * It takes the domain set of the user preferences object, an allowed REST method code and an optional body.
 * N.B. Said body is only used for non-GET requests.
 *
 * @param {string} slug The url of the microservice endpoint.
 * @param {AUTHNZENGINE_MICROSERVICE_ALLOWED_METHODS} method The REST method code.
 * @param {EntityType} entity_type The entity type (user, wholesaler).
 * @param {string | number | undefined} entity_id The entity id.
 * @param {ArgT} [args] The body of the request.
 * @returns {FetchArgs} The query object.
 */
export function getAuthNZEngineSessionMicroserviceQuery<
  ArgT extends {
    entity_id?: string | number;
    entity_type: EntityType;
    [key: string]: unknown | undefined;
  },
>(
  session_id: string,
  slug: string,
  method: 'POST',
  entity_type: EntityType,
  args: ArgT,
  entity_id?: string | number,
): FetchArgs {
  return getMicroserviceQuery<ArgT>(
    `${getMicroserviceUrl(MicroserviceNames.AUTHNZ_ENGINE)}/${entity_type}/${entity_id}/security/session/${session_id}/${slug}`,
    method,
    args,
  );
}
