import { components } from 'react-select';

import { IconHelper } from '@shippypro/design-system-web/iconhelper';

import { SelectOption } from '@web/types/common/selectOption';

import * as S from './ControlledDatePicker.styles';

type ControlledDatePickerDropdownSelectProps = {
  options: Array<string | number>;
  selected: string | number;
  onChange: (value: string) => void;
};

const ControlledDatePickerDropdownSelect = ({
  options: _options,
  selected,
  onChange,
}: ControlledDatePickerDropdownSelectProps) => {
  const options: SelectOption[] = _options.map(option => ({
    value: option.toString(),
    label: option.toString(),
  }));
  const value = options.find(o => o.value === selected.toString());

  return (
    <S.DropdownSelect
      isSearchable={false}
      classNamePrefix="react-select"
      value={value}
      onChange={option => onChange((option as SelectOption).value)}
      options={options}
      components={{
        IndicatorSeparator: () => null,
        Option: props => (
          <components.Option {...props}>
            <S.DropdownSelectOption>
              {props.label}
              {props.isSelected && (
                <IconHelper
                  icon="IconCheck"
                  size={16}
                  color="#616A75"
                  stroke={2}
                />
              )}
            </S.DropdownSelectOption>
          </components.Option>
        ),
      }}
    />
  );
};

export default ControlledDatePickerDropdownSelect;
