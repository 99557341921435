import { isNullOrEmptyString } from '../../strings';

/**
 * * Checks if the given value is null or empty (string, object or array)
 *
 * @param {Type} value The value to check
 *
 * @return {boolean} The result
 */
export function isNullOrEmpty<Type>(value: Type): boolean {
  return (
    value === null ||
    typeof value == 'undefined' ||
    (typeof value === 'string' && isNullOrEmptyString(value)) ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' &&
      Object.prototype.toString.call(value) !== '[object Date]' &&
      Object.keys(value as []).length === 0)
  );
}
