import { getMonth, getYear } from 'date-fns';

import { ReactDatePickerCustomHeaderProps } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

import DropdownSelect from './ControlledDatePicker.DropdownSelect';
import * as S from './ControlledDatePicker.styles';
import { years, months } from './ControlledDatePicker.utils';

const ControlledDatePickerHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <S.Header data-test="datepicker-header">
      <S.HeaderNavigation
        data-test="datepicker-nav-previous"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <IconHelper icon="IconChevronLeft" size={20} />
      </S.HeaderNavigation>
      <S.HeaderDropdownSelectors data-test="datepicker-header-selectors">
        <DropdownSelect
          options={months}
          selected={months[getMonth(date)]}
          onChange={(value: string) => changeMonth(months.indexOf(value))}
        />
        <DropdownSelect
          options={years}
          selected={getYear(date)}
          onChange={(value: string) => changeYear(Number(value))}
        />
      </S.HeaderDropdownSelectors>
      <S.HeaderNavigation
        data-test="datepicker-nav-next"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <IconHelper icon="IconChevronRight" size={20} />
      </S.HeaderNavigation>
    </S.Header>
  );
};

export default ControlledDatePickerHeader;
