// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';

// ########################## [TYPES]
import { ITopSideSectionProps } from './types';

/**
 * [SHIP - LAYOUT CARD top side] This component is used to render the top side of the entire SHIP page layout,
 * beside the title of the page.
 *
 * @interface ITopSideSectionProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const TopSideSection: FC<ITopSideSectionProps> = memo(
  ({ children, right }) => {
    return (
      <div className="flex flex-col w-full !pl-6 !gap-1 md:flex-row sm:justify-between sm:items-center sm:my-[6px] sm:!gap-0">
        {right}
        <div className="w-full flex flex-col items-end sm:flex-row sm:items-center gap-2 justify-end sm:h-[38px]">
          {children}
        </div>
      </div>
    );
  },
);

TopSideSection.displayName = 'TopSideSection_memoized';
