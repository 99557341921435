// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useMemo } from 'react';

// ########################## [TYPES]
import {
  EntityType,
  IProfileDetailsContext,
  ProfileDetails,
  ProfileDetailsOptionalProperties,
} from '@shippypro/foundation/settings/types';

// ########################## [HOOKS]
import { useGetUser } from '@web/features/_global/hooks/api/useGetUser';
import { useGetProfileDetails } from '@shippypro/foundation/settings/hooks/api/useGetProfileDetails';
import { useUpdateProfileDetails } from '@shippypro/foundation/settings/hooks/api/useUpdateProfileDetails';
import { useVerifyCurrentPassword } from '@shippypro/foundation/settings/hooks/api/useVerifyCurrentPassword';
import { useUpdatePassword } from '@shippypro/foundation/settings/hooks/api/useUpdatePassword';
import useLDFlag from '@web/hooks/useLDFlags';

/**
 * [HOOK] useInstanceProfileDetailsContext returns every data to be rendered about profile details,
 * together with the actions to update those details on the `Profile details` tab
 *
 * @return {IProfileDetailsContext} - The data and actions on the profile details tab
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const useInstanceProfileDetailsContext = (): IProfileDetailsContext => {
  const { user } = useGetUser();
  const { settingsOnUiApp } = useLDFlag();

  // ------ [ METHODS SETUP ] ------ //

  // BE call to get profile details
  const {
    profileDetails,
    isLoading: isGetProfileDetailsLoading,
    isFetching: isGetProfileDetailsFetching,
  } = useGetProfileDetails(user!.id!);

  const userDetails = useMemo(
    () =>
      profileDetails && 'phone_number' in profileDetails
        ? ({
            ...profileDetails,
            phone_number: profileDetails.phone_number
              ? profileDetails.phone_number?.replace('00', '+')
              : '',
          } as ProfileDetails)
        : null,
    [profileDetails],
  );

  // BE call to update profile details
  const { updateProfileDetails, isLoading: isUpdateProfileDetailsLoading } =
    useUpdateProfileDetails(user!.id!);

  const setProfileDetails = useCallback(
    async (
      updatedDetails: ProfileDetailsOptionalProperties,
      entityType?: EntityType,
      verificationToken?: string,
    ) => {
      if (settingsOnUiApp && user?.id) {
        const result = await updateProfileDetails(
          updatedDetails,
          entityType,
          verificationToken,
        );
        return result;
      } else return null;
    },
    [settingsOnUiApp, updateProfileDetails, user?.id],
  );

  // BE call to verify current password
  const { verifyCurrentPassword, isLoading: isVerifyCurrentPasswordLoading } =
    useVerifyCurrentPassword(user!.id!);

  const verifyCurrentPw = useCallback(
    async (currentPassword: string) => {
      if (settingsOnUiApp && user?.id) {
        const result = await verifyCurrentPassword(currentPassword, undefined);
        return result;
      } else return null;
    },
    [settingsOnUiApp, user?.id, verifyCurrentPassword],
  );

  // BE call to update password
  const { updatePassword, isLoading: isUpdatePasswordLoading } =
    useUpdatePassword(user!.id!);

  const updatePw = useCallback(
    async (
      newPassword: string,
      oldPassword: string,
      verificationToken?: string,
      entityType?: EntityType,
    ) => {
      if (settingsOnUiApp && user?.id) {
        const result = await updatePassword(
          newPassword,
          oldPassword,
          verificationToken,
          entityType,
        );
        return result;
      } else return null;
    },
    [settingsOnUiApp, updatePassword, user?.id],
  );

  return useMemo<IProfileDetailsContext>(
    () => ({
      profileDetails: userDetails,
      isProfileDetailsLoading:
        isGetProfileDetailsLoading ||
        isUpdateProfileDetailsLoading ||
        isGetProfileDetailsFetching,
      isGetProfileDetailsLoading,
      isUpdateProfileDetailsLoading,
      isVerifyCurrentPasswordLoading,
      isUpdatePasswordLoading,
      methods: {
        setProfileDetails,
        verifyCurrentPw,
        updatePw,
      },
    }),
    [
      userDetails,
      isGetProfileDetailsLoading,
      isUpdateProfileDetailsLoading,
      isGetProfileDetailsFetching,
      isVerifyCurrentPasswordLoading,
      isUpdatePasswordLoading,
      setProfileDetails,
      verifyCurrentPw,
      updatePw,
    ],
  );
};
