import { OrderCategory } from '@web/types/order/order';

import { useGetUserConfiguredTabs } from './api/tabs/useGetUserConfiguredTabs';

/**
 * [HOOK] Handy function that returns the current category of the order table
 * that the user is watching at the moment.
 *
 * @returns {OrderCategory} the category of the order table
 */
const useGetShipCategory = (): OrderCategory => {
  const location = window.location.hash;

  const { views_list: apiTabs } = useGetUserConfiguredTabs();

  switch (location) {
    case '#ready-to-ship':
      return OrderCategory.toShip;
    case '#shipped':
      return OrderCategory.shipped;
    case '#error':
      return OrderCategory.error;
    case '#archived':
      return OrderCategory.archived;
    case '#deleted':
      return OrderCategory.deleted;
    default:
      return (
        apiTabs.find(tab => `#${tab.code}` === location)?.starting_category ||
        OrderCategory.toShip
      );
  }
};

export default useGetShipCategory;
