// ########################## [IMPORTANT LIBRARIES]
import { FC, cloneElement, memo } from 'react';
import classnames from 'classnames';

// ########################## [TYPES]
import { TabConfiguration } from '@ds-web/components/templates/with-tabs/types';

interface IPreBodyContentProps {
  currentTab: TabConfiguration;
}

/**
 * [LAYOUT CARD WITH TOP TABS - PRE-BODY CONTENT] This component is used to render the content that goes before
 * the actual main body content of the layout card. It can be used to render custom filters, actions or other business-specific logics.
 *
 * @interface IPreBodyContentProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const PreBodyContent: FC<IPreBodyContentProps> = memo(
  ({
    currentTab: {
      mainContent: { hidden },
      secondaryContent: { preBody },
    },
  }) => (
    <div
      data-test="side-tabs-prebody"
      className={classnames('md:!mx-0 gap-[10px]', preBody?.wrapperClassName, {
        hidden,
      })}
    >
      {preBody?.render &&
        cloneElement(preBody.render, { disabled: preBody?.disabled })}
    </div>
  ),
);

export default PreBodyContent;
