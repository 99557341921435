import { FC, memo, useCallback, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Button, Dot } from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

import HelpSectionContext from '@web/features/help/contexts/HelpSectionContext';

import { HelpSubSection } from '@web/features/help/types';

import SystemStatus from '../system-status';

import useLDFlag from '@web/hooks/useLDFlags';

import { mixpanel } from '@web/utils/@user-tracking/mixpanel';

interface IContactUsProps {}

/**
 * This set of links of the Help section to aid the user in contacting our support team.
 * It displays two buttons that open the ticketing system and the live chat.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const ContactUs: FC<IContactUsProps> = memo(() => {
  const { t } = useTranslation(),
    trans = translations.help,
    transHome = trans.home,
    transCTAs = trans.ctas;

  const { supportSection, hubspotLiveChat } = useLDFlag();

  const { setSubSection } = useContext(HelpSectionContext)!;

  const onOpenTicketingSystem = useCallback(() => {
    setSubSection(HelpSubSection.ticketing);
    mixpanel.track('help-clicked', {
      destination: 'open-ticket',
      platform: 'Deliver',
    });
  }, [setSubSection]);

  const onOpenLiveChat = useCallback(() => {
    setSubSection(HelpSubSection.livechat);
    mixpanel.track('help-clicked', {
      destination: 'chat',
      platform: 'Deliver',
    });
  }, [setSubSection]);

  return (
    supportSection && (
      <section
        data-test="contactus-subsection"
        className="absolute !bottom-0 !pb-4 w-full flex flex-col bg-white"
      >
        <div className="flex justify-between items-center">
          <h3 className="!p-4 text-[color:--shp-color-text-cardtitle] font-bold uppercase">
            {t(transHome.support)}
          </h3>
          <a
            href="https://support.shippypro.com/tickets-view?offset=0"
            target="_blank"
            rel="noreferrer"
            className="mr-4 text-black font-bold"
            onClick={() =>
              mixpanel.track('help-clicked', {
                destination: 'tickets',
                platform: 'Deliver',
              })
            }
          >
            {t(trans.embedded.ticketing.yourTickets)}
          </a>
        </div>
        <span className="!mx-0 border-b-2 border-[color:--shp-color-bg-main]" />
        <div className="w-full !p-4 !pb-0 flex flex-col sm:flex-row justify-between items-center !gap-2">
          <Button
            data-test="open-ticketing-system-btn"
            color={Color.primary}
            icon={<IconHelper icon="IconMailPlus" size={18} />}
            className="w-full sm:w-1/2 flex justify-center items-center !gap-2"
            onClick={onOpenTicketingSystem}
          >
            {t(transCTAs.openTicket)}
          </Button>
          <div className="w-1/2 relative">
            <Button
              data-test="open-live-chat-btn"
              color={Color.primary}
              icon={
                <IconHelper icon="IconMessage" size={18} className="shrink-0" />
              }
              className="w-full sm:w-full flex justify-center items-center !gap-2"
              onClick={onOpenLiveChat}
              disabled={!hubspotLiveChat}
            >
              {hubspotLiveChat
                ? t(transCTAs.liveChat)
                : t(transCTAs.liveChatDisabled)}
            </Button>
            <Dot
              color={Color.accent}
              // Refer to the scathing comment in the `useManageLiveChat` hook to understand why this is here
              // and it's not managed with a reactive state
              isPulsing
              id="unreadchat-ContactUs"
              dotClassName="absolute !top-0 -right-1 hidden"
            />
          </div>
        </div>
        <SystemStatus />
      </section>
    )
  );
});

export default ContactUs;
