import { toLowerFirst } from '@shippypro/design-system-web/functions';

import {
  CreatePickingListMutationPayload,
  CreateDocumentArchivePayload,
  CreateDocumentArchiveResult,
  CreatePickingListMutationResult,
  GetPaymentMethodsQueryPayload,
  GetPaymentMethodsQueryResult,
  getQzTrayAuthDataMutationResult,
  GetZipCodesQueryPayload,
  GetZipCodesQueryResult,
  UserConfiguredTabsQueryPayload,
  UserConfiguredTabsResult,
  UserConfiguredTabsMutationPayload,
  GetLastOrdersQueryResult,
  GetLastOrdersQueryPayload,
  GetTagsQueryPayload,
  CheckAutoMergeCapabilityQueryPayload,
  CheckAutoMergeCapabilityQueryResult,
  OrderAutoMergeMutationPayload,
  OrderAutoMergeMutationResult,
  OrderSplitMutationResult,
  OrderSplitMutationPayload,
  EditImportExcelColumnsMutationResult,
  EditImportExcelColumnsMutationPayload,
  GetExportExcelColumnsTemplatesMutationResult,
  DeleteExportExcelColumnsTemplateMutationResult,
  DeleteExportExcelColumnsTemplateMutationPayload,
  SaveExportExcelColumnsTemplateMutationResult,
  SaveExportExcelColumnsTemplateMutationPayload,
  CarriersErrorsMessagesResult,
  CarriersErrorsMessagesPayload,
  CarriersErrorsTypesResult,
  CarriersErrorsTypesPayload,
  GetAutoRetryStatusResult,
  GetAutoRetryStatusPayload,
  SaveChangeAutoRetryStatusResult,
  SaveChangeAutoRetryStatusPayload,
  GetNoteOptionsQueryResult,
  GetNoteOptionsQueryPayload,
  GetErrorMessagesOptionsQueryResult,
  GetErrorMessagesOptionsQueryPayload,
} from './types';
import { rootApi, getGatewayQuery } from '@web/utils/@reduxjs/rtk-query';
import { getProfilesEnginePreferencesMicroserviceQuery } from '@web/utils/@reduxjs/microservices/profiles-engine';
import {
  getValidationEngineCarriersErrorsMessagesMicroserviceQuery,
  getValidationEngineCarriersErrorsTypesMicroserviceQuery,
} from '@web/utils/@reduxjs/microservices/validation-engine';

import {
  ImportOrdersFromFileMutationPayload,
  PrintOrdersMutationResult,
  PrintOrdersBaseMutationPayload,
  ShipAPIActions,
  CloseWorkDayMutationPayload,
  CloseWorkDayMutationResult,
  OrdersActionBaseMutationPayload,
  BaseFileExportResult,
  BaseFileAltExportResult,
  OrdersCreateInvoiceOrPackingSlipMutationPayload,
  PrintOrderListMutationPayload,
  OrdersTableDataQueryPayload,
  GetProductsQueryPayload,
  GetProductsQueryResult,
  getQzTrayAuthDataMutationPayload,
} from '@web/features/ship/types';

import { BaseAPIResult } from '@web/types/common/APIResults';
import { Order, OrderCategory } from '@web/types/order';
import { PaginatedData } from '@web/types/tables';
import { UserPreferencesDomain } from '@web/types/user/preferences';
import {
  getAutoRetryStatusQuery,
  getChangeAutoRetryStatusQuery,
} from '@web/utils/@reduxjs/microservices/workflow-engine';
import { PriceRange } from './types/priceRange';
import { OrderStatusFilterItem } from './types/orderStatusFilterItem';
import { getOrdersServiceQuery } from '@web/utils/@reduxjs/microservices/orders-service';
import { ShipMSAPIEndpoints } from '../ship/types/types';

/**
 * SHIP Feature APIs
 *
 * This object holds every API that is used in the /ship page.
 * N.B. Some APIs are shared with the /order/api object, the rule is:
 * if an API is also used in the Order Details page, it goes into that object
 * instead of this one.
 */
export const shipApi = rootApi.injectEndpoints({
  endpoints: build => ({
    /**
     * API: getUserConfiguredTabs
     *
     * @provides [{ type: 'UserTabs', id: 'The user id' }]
     */
    getUserConfiguredTabs: build.query<
      UserConfiguredTabsResult,
      UserConfiguredTabsQueryPayload
    >({
      query: args =>
        getProfilesEnginePreferencesMicroserviceQuery<UserConfiguredTabsQueryPayload>(
          UserPreferencesDomain.DeliverViews,
          'GET',
          args,
        ),
      providesTags: (_, __, { user_id: id }) => [{ type: 'UserTabs', id }],
    }),
    /**
     * API: updateUserConfiguredTabs
     *
     * @invalidate [{ type: 'UserTabs', id: 'The user id' }]
     */
    updateUserConfiguredTabs: build.mutation<
      UserConfiguredTabsResult,
      UserConfiguredTabsMutationPayload
    >({
      query: args =>
        getProfilesEnginePreferencesMicroserviceQuery<UserConfiguredTabsMutationPayload>(
          UserPreferencesDomain.DeliverViews,
          'PUT',
          args,
        ),
      invalidatesTags: (_, __, { user_id: id }) => [{ type: 'UserTabs', id }],
    }),
    /**
     * API: getTableData
     *
     * @provides [{ type: 'Ship', id: 'The passed Category' }]
     */
    getTableData: build.query<
      PaginatedData<Order>,
      OrdersTableDataQueryPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, OrdersTableDataQueryPayload>(
          ShipAPIActions.OrderGetList,
          args,
        ),
      providesTags: (_, __, { category, tabCode, filters }) => {
        let parsedCategory = toLowerFirst(category);
        if (filters.show_archived) parsedCategory = OrderCategory.archived;
        if (filters.show_hidden) parsedCategory = OrderCategory.deleted;
        if (filters.show_in_error) parsedCategory = OrderCategory.error;

        return [
          { type: 'Ship', id: parsedCategory },
          { type: 'Ship', id: `${parsedCategory}_${tabCode}` },
        ];
      },
    }),
    /**
     * API: getOrdersPrinceRange
     *
     * @provides [{ type: 'OrdersPriceRange' }]
     */
    getOrdersPrinceRange: build.query<PriceRange, {}>({
      query: args =>
        getGatewayQuery<ShipAPIActions, {}>(
          ShipAPIActions.GetOrderPriceRange,
          args,
        ),
      providesTags: ['OrdersPriceRange'],
    }),
    /**
     * API: getOrdersStatuses
     *
     * @provides [{ type: 'OrderStatuses' }]
     */
    getOrdersStatuses: build.query<OrderStatusFilterItem[], unknown>({
      query: () =>
        getOrdersServiceQuery(ShipMSAPIEndpoints.OrderGetStatuses, 'GET'),
      providesTags: ['OrdersStatuses'],
    }),
    /**
     * API: importOrdersFromFile
     *
     * @invalidates [{ type: 'Ship', id: 'toShip'}]
     */
    importOrdersFromFile: build.mutation<
      BaseAPIResult<boolean>,
      ImportOrdersFromFileMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, ImportOrdersFromFileMutationPayload>(
          ShipAPIActions.OrderImport,
          args,
        ),
      invalidatesTags: [
        { type: 'Ship', id: OrderCategory.toShip },
        { type: 'OrdersCount', id: OrderCategory.toShip },
        { type: 'Marketplaces' },
        { type: 'Onboarding' },
        { type: 'Setup' },
      ],
    }),
    /**
     * API: createPickingList
     *
     */
    createPickingList: build.mutation<
      CreatePickingListMutationResult,
      CreatePickingListMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, CreatePickingListMutationPayload>(
          ShipAPIActions.OrderCreatePickingList,
          args,
        ),
      invalidatesTags: [{ type: 'PickingLists' }],
    }),
    /**
     * API: createDocumentArchive
     */
    createDocumentArchive: build.mutation<
      CreateDocumentArchiveResult,
      CreateDocumentArchivePayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, CreateDocumentArchivePayload>(
          ShipAPIActions.OrderCreateDocumentArchive,
          args,
        ),
    }),
    /**
     * API: createOrderList
     *
     */
    createOrderList: build.mutation<
      BaseFileExportResult,
      PrintOrderListMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, PrintOrderListMutationPayload>(
          ShipAPIActions.OrderGetOrderList,
          args,
        ),
    }),
    /**
     * API: createInvoiceOrPackingSlip
     *
     * @invalidates ['CustomsDocuments']
     */
    createInvoiceOrPackingSlip: build.mutation<
      BaseFileAltExportResult,
      OrdersCreateInvoiceOrPackingSlipMutationPayload
    >({
      query: args =>
        getGatewayQuery<
          ShipAPIActions,
          OrdersCreateInvoiceOrPackingSlipMutationPayload
        >(ShipAPIActions.OrderCreateInvoice, args),
      invalidatesTags: ['CustomsDocuments'],
    }),
    /**
     * API: createA6PackingSlip
     *
     */
    createA6PackingSlip: build.mutation<
      BaseFileExportResult,
      OrdersActionBaseMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, OrdersActionBaseMutationPayload>(
          ShipAPIActions.OrderCreateA6PackingSlip,
          args,
        ),
    }),
    /**
     * API: createManifest
     *
     */
    createManifest: build.mutation<
      BaseFileExportResult,
      OrdersActionBaseMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, OrdersActionBaseMutationPayload>(
          ShipAPIActions.OrderCreateManifest,
          args,
        ),
      invalidatesTags: [{ type: 'Manifests' }],
    }),
    /**
     * API: closeWorkDay
     *
     */
    closeWorkDay: build.mutation<
      CloseWorkDayMutationResult,
      CloseWorkDayMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, CloseWorkDayMutationPayload>(
          ShipAPIActions.CarrierCloseShipments,
          args,
        ),
    }),
    /**
     * API: createCN22
     *
     */
    createCN22: build.mutation<
      BaseFileExportResult,
      OrdersActionBaseMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, OrdersActionBaseMutationPayload>(
          ShipAPIActions.OrderCreateCustomDocument,
          args,
        ),
    }),
    /**
     * API: printOrders
     *
     * @invalidates [{ type: 'Ship', id: 'shipped'}]
     */
    printOrders: build.mutation<
      PrintOrdersMutationResult,
      PrintOrdersBaseMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, PrintOrdersBaseMutationPayload>(
          ShipAPIActions.OrderPrintMultipleLabelsPDF,
          args,
        ),
      invalidatesTags: (_, __, args) => [
        ...args.ids.map(id => ({ type: 'Order', id })),
        { type: 'Ship', id: OrderCategory.shipped },
      ],
    }),
    /**
     * API: printOnZebra
     *
     * @invalidates [{ type: 'Ship', id: 'shipped'}]
     */
    printOnZebra: build.mutation<
      BaseAPIResult<boolean>,
      PrintOrdersBaseMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, PrintOrdersBaseMutationPayload>(
          ShipAPIActions.OrderPrintZPL,
          args,
        ),
      invalidatesTags: (_, __, args) => [
        ...args.ids.map(id => ({ type: 'Order', id })),
        { type: 'Ship', id: OrderCategory.shipped },
      ],
    }),
    /**
     * API: printOnPrintnode
     *
     * @invalidates [{ type: 'Ship', id: 'shipped'}]
     */
    printOnPrintnode: build.mutation<
      BaseAPIResult<boolean>,
      PrintOrdersBaseMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, PrintOrdersBaseMutationPayload>(
          ShipAPIActions.OrderPrintPrintNode,
          args,
        ),
      invalidatesTags: (_, __, args) => [
        ...args.ids.map(id => ({ type: 'Order', id })),
        { type: 'Ship', id: OrderCategory.shipped },
      ],
    }),
    /**
     * API: getProducts
     *
     */
    getProducts: build.query<
      Array<GetProductsQueryResult>,
      GetProductsQueryPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, GetProductsQueryPayload>(
          ShipAPIActions.GetProducts,
          { ...args },
        ),
    }),
    /**
     * API: getTags
     *
     */
    getTags: build.query<Array<string>, GetTagsQueryPayload>({
      query: args =>
        getGatewayQuery<ShipAPIActions, GetTagsQueryPayload>(
          ShipAPIActions.GetTags,
          { ...args },
        ),
    }),
    /**
     * API: getQzTrayAuthData
     *
     */
    getQzTrayAuthData: build.query<
      getQzTrayAuthDataMutationResult,
      getQzTrayAuthDataMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, any>(
          ShipAPIActions.ShipGetQzTrayAuthData,
          args,
        ),
    }),
    /**
     * API: getZipCodes
     *
     */
    getZipCodes: build.query<GetZipCodesQueryResult, GetZipCodesQueryPayload>({
      query: args =>
        getGatewayQuery<ShipAPIActions, GetZipCodesQueryPayload>(
          ShipAPIActions.PortalGetZipCodes,
          args,
        ),
    }),
    /**
     * API: getPaymentMethods
     *
     */
    getPaymentMethods: build.query<
      GetPaymentMethodsQueryResult,
      GetPaymentMethodsQueryPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, GetPaymentMethodsQueryPayload>(
          ShipAPIActions.OrderGetPaymentMethods,
          args,
        ),
    }),
    /**
     * API: printBrtLabels
     *
     */
    printBrtLabels: build.mutation<
      PrintOrdersMutationResult,
      OrdersActionBaseMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, OrdersActionBaseMutationPayload>(
          ShipAPIActions.OrderPrintBrtLabels,
          args,
        ),
    }),
    /**
     * API: getLastOrders
     *
     */
    getLastOrders: build.query<
      GetLastOrdersQueryResult,
      GetLastOrdersQueryPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, GetLastOrdersQueryPayload>(
          ShipAPIActions.OrderGetLastOrders,
          { ...args },
        ),
    }),
    /**
     * API: checkAutoMergeCapability
     *
     */
    checkAutoMergeCapability: build.query<
      CheckAutoMergeCapabilityQueryResult,
      CheckAutoMergeCapabilityQueryPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, CheckAutoMergeCapabilityQueryPayload>(
          ShipAPIActions.OrderCheckAutoMergeCapability,
          args,
        ),
      providesTags: (_, __, { order_ids }) => [
        {
          type: 'AutoMergeCapability',
          id: order_ids.join('_'),
        },
      ],
    }),
    /**
     * API: autoMergeOrders
     *
     */
    autoMergeOrders: build.mutation<
      OrderAutoMergeMutationResult,
      OrderAutoMergeMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, OrderAutoMergeMutationPayload>(
          ShipAPIActions.OrderAutoMergeOrders,
          args,
        ),
      invalidatesTags: (_, __, { ids }) => [
        {
          type: 'AutoMergeCapability',
          id: Object.entries(ids).reduce(
            (acc, [masterId, group]) => `${acc}_${masterId}_${group.join('_')}`,
            '',
          ),
        },
        { type: 'Ship', id: OrderCategory.toShip },
        { type: 'OrdersCount', id: OrderCategory.toShip },
      ],
    }),
    /**
     * API: splitOrder
     *
     */
    splitOrder: build.mutation<
      OrderSplitMutationResult,
      OrderSplitMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, OrderSplitMutationPayload>(
          ShipAPIActions.OrderSplitOrder,
          args,
        ),
      invalidatesTags: (_, __, { toShipOrderID }) => [
        { type: 'AutoMergeCapability' },
        { type: 'Ship', id: OrderCategory.toShip },
        { type: 'OrdersCount', id: OrderCategory.toShip },
        { type: 'Order', id: toShipOrderID },
      ],
    }),
    /**
     * API: getImportExcelColumns
     *
     */
    getImportExcelColumns: build.query<string[], void>({
      query: () =>
        getOrdersServiceQuery(
          ShipMSAPIEndpoints.OrderToShipGetImportExcelColumns,
          'GET',
        ),
      providesTags: ['GetExcelColumns'],
    }),
    /**
     * API: editImportExcelColumns
     *
     * @invalidates ['GetExcelColumns']
     */
    editImportExcelColumns: build.mutation<
      EditImportExcelColumnsMutationResult,
      EditImportExcelColumnsMutationPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, EditImportExcelColumnsMutationPayload>(
          ShipAPIActions.OrderEditImportExcelColumns,
          args,
        ),
      invalidatesTags: ['GetExcelColumns'],
    }),
    /**
     * API: getExportExcelColumnsTemplates
     *
     */
    getExportExcelColumnsTemplates: build.query<
      GetExportExcelColumnsTemplatesMutationResult,
      void
    >({
      query: args =>
        getOrdersServiceQuery(
          ShipMSAPIEndpoints.OrderGetExportExcelColumnsTemplates,
          'GET',
        ),
      providesTags: ['GetExcelColumnsTemplates'],
    }),
    /**
     * API: deleteExportExcelColumnsTemplate
     *
     */
    deleteExportExcelColumnsTemplate: build.mutation<
      DeleteExportExcelColumnsTemplateMutationResult,
      DeleteExportExcelColumnsTemplateMutationPayload
    >({
      query: args =>
        getGatewayQuery<
          ShipAPIActions,
          DeleteExportExcelColumnsTemplateMutationPayload
        >(ShipAPIActions.OrderDeleteExportExcelColumnsTemplate, args),
      invalidatesTags: ['GetExcelColumnsTemplates'],
    }),
    /**
     * API: saveExportExcelColumnsTemplate
     *
     */
    saveExportExcelColumnsTemplate: build.mutation<
      SaveExportExcelColumnsTemplateMutationResult,
      SaveExportExcelColumnsTemplateMutationPayload
    >({
      query: args =>
        getGatewayQuery<
          ShipAPIActions,
          SaveExportExcelColumnsTemplateMutationPayload
        >(ShipAPIActions.OrderSaveExportExcelColumnsTemplate, args),
      invalidatesTags: ['GetExcelColumnsTemplates'],
    }),
    /**
     * API: getValidationCarriersErrorsMessages
     */
    getValidationCarriersErrorsMessages: build.query<
      CarriersErrorsMessagesResult,
      CarriersErrorsMessagesPayload
    >({
      query: args =>
        getValidationEngineCarriersErrorsMessagesMicroserviceQuery<CarriersErrorsMessagesPayload>(
          'POST',
          args,
        ),
    }),
    /**
     * API: getValidationCarriersErrorsTypes
     */
    getValidationCarriersErrorsTypes: build.query<
      CarriersErrorsTypesResult,
      CarriersErrorsTypesPayload
    >({
      query: args =>
        getValidationEngineCarriersErrorsTypesMicroserviceQuery<CarriersErrorsTypesPayload>(
          'GET',
          args,
        ),
    }),
    /**
     * API: getAutoRetryStatus
     */
    getAutoRetryStatus: build.query<
      GetAutoRetryStatusResult,
      GetAutoRetryStatusPayload
    >({
      query: args => getAutoRetryStatusQuery('GET', args),
      providesTags: ['GetAutoRetryStatus'],
    }),
    /**
     * API: saveChangeAutoRetryStatus
     */
    saveChangeAutoRetryStatus: build.mutation<
      SaveChangeAutoRetryStatusResult,
      SaveChangeAutoRetryStatusPayload
    >({
      query: args =>
        getChangeAutoRetryStatusQuery<SaveChangeAutoRetryStatusPayload>(
          'POST',
          args,
        ),
    }),

    /* API: getNoteOptions
     *
     */
    getNoteOptions: build.query<
      GetNoteOptionsQueryResult,
      GetNoteOptionsQueryPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, GetNoteOptionsQueryPayload>(
          ShipAPIActions.OrderGetNoteOptions,
          args,
        ),
    }),
    /**
     * API: getErrorMessagesOptions
     */
    getErrorMessagesOptions: build.query<
      GetErrorMessagesOptionsQueryResult,
      GetErrorMessagesOptionsQueryPayload
    >({
      query: args =>
        getGatewayQuery<ShipAPIActions, GetErrorMessagesOptionsQueryPayload>(
          ShipAPIActions.OrderGetErrorMessagesOptions,
          args,
        ),
    }),
    // [APPEND NEW APIS ABOVE] < Needed for generating API Hooks seamlessly
  }),
});

export const {
  useGetUserConfiguredTabsQuery,
  useUpdateUserConfiguredTabsMutation,
  useGetTableDataQuery,
  useGetOrdersStatusesQuery,
  useGetOrdersPrinceRangeQuery,
  useImportOrdersFromFileMutation,
  useCreatePickingListMutation,
  useCreateDocumentArchiveMutation,
  useCreateOrderListMutation,
  useCreateInvoiceOrPackingSlipMutation,
  useCreateA6PackingSlipMutation,
  useCreateManifestMutation,
  useCloseWorkDayMutation,
  useCreateCN22Mutation,
  usePrintOrdersMutation,
  usePrintOnZebraMutation,
  usePrintOnPrintnodeMutation,
  useLazyGetProductsQuery,
  useLazyGetTagsQuery,
  useLazyGetQzTrayAuthDataQuery,
  useLazyGetZipCodesQuery,
  useLazyGetPaymentMethodsQuery,
  usePrintBrtLabelsMutation,
  useLazyGetLastOrdersQuery,
  useCheckAutoMergeCapabilityQuery,
  useAutoMergeOrdersMutation,
  useSplitOrderMutation,
  useGetImportExcelColumnsQuery,
  useEditImportExcelColumnsMutation,
  useGetExportExcelColumnsTemplatesQuery,
  useDeleteExportExcelColumnsTemplateMutation,
  useSaveExportExcelColumnsTemplateMutation,
  useGetValidationCarriersErrorsMessagesQuery,
  useGetValidationCarriersErrorsTypesQuery,
  useGetAutoRetryStatusQuery,
  useSaveChangeAutoRetryStatusMutation,
  useLazyGetNoteOptionsQuery,
  useLazyGetErrorMessagesOptionsQuery,
  // [EXPORT NEW APIS ABOVE] < Needed for generating API Hooks seamlessly
} = shipApi;
