/* istambul ignore file */

// ########################## [TRANSLATIONS]
import { translations } from '@shippypro/translations';
import { TFunction } from 'i18next';

// ########################## [DESIGN SYSTEM]
import { showErrorToastr } from '@shippypro/design-system-web/functions';

// ########################## [TYPES]
import { BaseAPIResult } from '../types';

export const manageResponse = <T>(result: any, t: TFunction): T | null => {
  const transCommon = translations.common;

  if ('data' in result) {
    const data = result.data;
    if (data && !data.success && data.message) {
      showErrorToastr(t(transCommon.errorFound), data.message);
    }
    return data ?? null;
  } else if ('error' in result) {
    if ('data' in result.error) {
      const data = result.error.data as BaseAPIResult;
      if (data && !data.success && data.message) {
        showErrorToastr(t(transCommon.errorFound), data.message);
      }
      return null;
    }
  }
  return null;
};
