import { SelectOption } from '../common/selectOption';

// A select input that holds the inclusion type (all, no, yes) we want for said filter
export type InclusionInput = SelectOption<FilterInclusion> | null;

export enum FilterInclusion {
  all = 'all',
  yes = 'yes',
  no = 'no',
}

export enum DateRange {
  Today = 'today',
  Yesterday = 'yesterday',
  LastWeek = 'last week',
  LastMonth = 'last month',
  None = '',
}

export const isDateRange = (dates: unknown): dates is DateRange => {
  return (
    typeof dates === 'string' &&
    Object.values(DateRange).includes(dates as DateRange)
  );
};

// A double input sporting a relation (greater than, lesser than...) and the actual numeric value to compare
export type RelationInput = {
  relation: FilterRelation;
  value: number | null;
};

export enum FilterRelation {
  greaterThan = 'greaterThan',
  greaterOrEqualTo = 'greaterOrEqualTo',
  lessThan = 'lessThan',
  lessOrEqualTo = 'lessOrEqualTo',
  equalTo = 'equalTo',
}

export type TableFiltersReturnType<T> = Partial<
  Record<
    keyof T,
    { name: string; value: string | Array<string> | true | undefined }
  >
>;
