import range from 'lodash/range';
import { format, getYear, setMonth } from 'date-fns';

import { getDatesFromRange } from '@web/features/shared/filters/utils/dateRange';
import { getCurrentLocaleObj } from '@web/utils/@date-fns/locale';
import { DateRange } from '@web/types/filters';

export const getDatePickerValues = (value?: DateRange | Date[]) => {
  let selected: Date | null = null;
  let startDate: Date | null = null;
  let endDate: Date | null = null;

  if (Array.isArray(value) && value.length > 0) {
    startDate = new Date(value[0]);
    selected = startDate;
    endDate = value[1] ? new Date(value[1]) : null;
  }

  if (typeof value === 'string') {
    [startDate, endDate] = getDatesFromRange(value);
    selected = startDate;
  }

  return {
    selected,
    startDate,
    endDate,
  };
};

export const years = range(2015, getYear(new Date()) + 1);
export const months = range(0, 12).map(i =>
  format(setMonth(new Date(), i), 'MMMM', { locale: getCurrentLocaleObj() }),
);
