// ########################## [IMPORTANT LIBRARIES]
import { memo, useState } from 'react';
import classnames from 'classnames';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { BaseButton } from './types';
import { PrintLabelMode } from '@web/features/ship/functions/tracking.types';

// ########################## [DESIGN SYSTEM]
import { DropdownItem, TooltipWrapperByID } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [PAGE COMPONENTS]
import BRTPopover from './blocks/BRTPopover';

// ########################## [HOOKS]
import { usePrintBrtLabels } from '@web/features/ship/hooks/api/printing/usePrintBRTLabels';
import { useTrackButtonClick } from '@web/features/ship/hooks/useTrackButtonClick';

export type IBtnPrintBRT = BaseButton & {
  show: boolean;
  ordersIds: number[];
};

export const BRT_NAME = 'BRT';

/**
 * This component renders the BRT print button conditionally on having at least one BRT connection
 * It opens a popover on hover
 */
export const BtnPrintBRT: React.FC<IBtnPrintBRT> = memo(
  ({ isDisabled, show, ordersIds, className }) => {
    const { t } = useTranslation(),
      transPrint = translations.ship.buttons.print,
      transBRT = transPrint.brt;

    const { printBrtLabels } = usePrintBrtLabels();
    const { trackPrintLabelClick } = useTrackButtonClick();

    const onClick = () => {
      trackPrintLabelClick(PrintLabelMode.BRT, ordersIds.length);
      setBrtPopoverOpen(false);
      printBrtLabels({ ids: ordersIds });
    };

    // Using a controlled state so that the target of the popover can be the bigger menu item
    // even if the trigger is ONLY the small info icon at the far-right.
    const [brtPopoverOpen, setBrtPopoverOpen] = useState<boolean>(false);

    return show ? (
      <>
        <TooltipWrapperByID
          target="print-btn-brt-wrapper"
          text={t(transBRT.onlyBRT)}
          active={isDisabled}
          tooltipProps={{
            className: 'print-btn-brt-disabled-tooltip',
          }}
        >
          {/*
            This lonely <div /> is needed as a wrapper of the menu item when it's disabled,
            since said element doesn't allow interactions when in this state.
            <TooltipWrapperByID />'s job is to clone the target as ID on the <div /> to show the popup.
          */}
          <div>
            <DropdownItem
              className={classnames('pr-0 justify-between w-auto', className)}
              id="print-btn-brt"
              tag="a"
              disabled={isDisabled}
              onClick={onClick}
            >
              <span className="text-truncate">{t(transBRT.dropdownTitle)}</span>
              <div
                id="print-brt-open-popover"
                className={classnames('ml-auto !mr-3', {
                  'text-dark cursor-pointer': !isDisabled,
                })}
                data-test="print-brt-open-popover"
                onMouseEnter={() => {
                  setBrtPopoverOpen(true);
                }}
                onMouseLeave={() => {
                  setBrtPopoverOpen(false);
                }}
                onClick={() => {
                  setBrtPopoverOpen(false);
                }}
              >
                <IconHelper icon="IconInfoCircle" size={18} />
              </div>
            </DropdownItem>
          </div>
        </TooltipWrapperByID>
        {/* BRT popover component */}
        {show && <BRTPopover isOpen={brtPopoverOpen} />}
      </>
    ) : null;
  },
);
