import styled from 'styled-components';

import { useGetHealthCheck } from '@web/features/_global/hooks/api/useGetHealthCheck';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Avatar,
  TooltipWrapperByID,
} from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { Status, StyleType } from '@shippypro/design-system-web/enums';
import {
  isNullOrEmptyString,
  getAvatarData,
} from '@shippypro/design-system-web/functions';

import { Col } from 'reactstrap';

export interface INavbarAvatarProps {
  name: string;
  email: string;
  img?: string;
}

const NavbarAvatar: React.FC<INavbarAvatarProps> = ({ name, img, email }) => {
  const { t } = useTranslation();
  const truncatedEmail =
    email.length > 20 ? `${email.substring(0, 17)}...` : '';

  const { content, color } = getAvatarData(name || 'Loading ...');

  const { isPortalHealthy } = useGetHealthCheck();

  return (
    <UncontrolledButtonDropdown tag="li" className="nav-item dropdown">
      <DropdownToggle
        tag="a"
        styleType={StyleType.filled}
        data-toggle="dropdown"
        className="nav-link dropdown-user-link cinitial"
        id="nav-profile"
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name text-bold-600 mb-0">{name}</span>
          <TooltipWrapperByID
            target="truncated-email"
            text={email}
            active={!!truncatedEmail}
          >
            <span className="text-bold-600 mb-0 max-width-full text-muted">
              {truncatedEmail || email}
            </span>
          </TooltipWrapperByID>
        </div>
        <TooltipWrapperByID
          tooltipProps={{
            className: 'user-status text-muted mt-0',
          }}
          target="toggle-status-tooltip"
          text={
            isPortalHealthy
              ? t(
                  isPortalHealthy
                    ? translations.nav.navbar.status
                    : translations.common.errors.connectionLost_lite,
                )
              : ''
          }
          active={!!truncatedEmail}
        >
          <div>
            <StyledAvatar
              content={content}
              color={color}
              status={
                isPortalHealthy === undefined
                  ? undefined
                  : isPortalHealthy
                    ? Status.online
                    : Status.busy
              }
              className={`${!img ? 'o5' : ''} cinitial`}
              img={isNullOrEmptyString(img) ? undefined : img}
            />
          </div>
        </TooltipWrapperByID>
      </DropdownToggle>
      <DropdownMenu end className="top-20">
        <DropdownItem
          tag="a"
          href={`${process.env.NX_LEGACY_URL}/panel/profile.html`}
          target="_blank"
        >
          <Col className="fonticon-container flex items-center">
            <div className="fonticon-wrap">
              <IconHelper icon="IconUser" size={13} />
            </div>
            <label className="fonticon-classname">
              {t(translations.nav.user.editProfile)}
            </label>
          </Col>
        </DropdownItem>
        <DropdownItem
          tag="a"
          href={`${process.env.NX_LEGACY_URL}/panel/my-addresses-parcels.html`}
          target="_blank"
        >
          <Col className="fonticon-container flex items-center">
            <div className="fonticon-wrap">
              <IconHelper icon="IconBook2" size={13} />
            </div>
            <label className="fonticon-classname">
              {t(translations.nav.user.addressAndParcels)}
            </label>
          </Col>
        </DropdownItem>
        <DropdownItem
          tag="a"
          href={`${process.env.NX_LEGACY_URL}/panel/new-plan.html`}
          target="_blank"
        >
          <Col className="fonticon-container flex items-center">
            <div className="fonticon-wrap">
              <IconHelper icon="IconShoppingCart" size={13} />
            </div>
            <label className="fonticon-classname">
              {t(translations.nav.user.planAndPayments)}
            </label>
          </Col>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag="a" href="/logout">
          <Col className="fonticon-container flex items-center">
            <div className="fonticon-wrap">
              <IconHelper icon="IconLogout" size={13} />
            </div>
            <label className="fonticon-classname">
              {t(translations.nav.user.logout)}
            </label>
          </Col>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

const StyledAvatar = styled(Avatar)`
  .avatar-content {
    width: 40px !important;
    height: 40px !important;
    font-size: 1rem !important;
  }

  img {
    width: 40px !important;
    height: 40px !important;
  }

  [class*='avatar-status-'] {
    width: 13px !important;
    height: 13px !important;
  }
`;

export default NavbarAvatar;
