import { createContext } from 'react';

import { IProfileDetailsContext } from '@shippypro/foundation/settings/types';

/**
 * This context will provide all the necessary data for the `Profile details` tabs
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const ProfileDetailsContext = createContext<IProfileDetailsContext | null>(
  null,
);

export default ProfileDetailsContext;
