// ########################## [IMPORTANT LIBRARIES]
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

// ########################## [TYPES]
import { Features, MenuLinks } from '@web/features/_global/types';
import {
  PaywallDesign,
  TwoRowsOneVideoLayoutPaywallInfo,
  TwoRowsOneImageLayoutPaywallInfo,
} from '@web/features/paywall/types';
import FeaturePaywallInfo from '@web/features/_global/models/FeaturePaywallInfoModel';

// ########################## [COMMON COMPONENTS]
import CommonBadgeLock from '@web/components/common-features/paywall/badges/lock';

// ########################## [UTILS]

// ########################## [CONTEXTS]
import PaywallContext from '@web/features/paywall/contexts/PaywallContext';

// ########################## [HOOKS]
import useLDFlags from '@web/hooks/useLDFlags';
import { useHasActivePlan } from './api/useHasActivePlan';
import { useCheckAvailableFeature } from './api/useCheckAvailableFeature';
import { useNavigate } from 'react-router-dom';

/**
 * Statically generated ShippyPro side menu links
 * In the future, this should be dinamically generated from BE, maybe a Deliver-dedicated table
 * In that case, pay attention to onClick props
 */
const useSideMenuLinks = (): MenuLinks => {
  const navigate = useNavigate();

  // Getting FF to use as value of the 'condition' properties
  // liveCheckout is the legacy name of Ship&Collect, the LD flag name was not updated
  const {
    liveCheckout: shipAndCollect,
    uiAppCarrierInvoiceAnalysis: carrierInvoiceAnalysis,
    pickAndPack,
    apiPageAccess,
    platformToggleSwitch,
    uiAppMyDocuments,
    // new analytics flags
    uiAppNewAnalytics: canAccessOptimizer,
    optimizerMenuItem: isOptimizerMenuItemVisible,
    // old analytics flags
    analysisShipmentPerformances,
    analysisRevenueAndShippingCosts,
    // automation flags - Will be used in the future
    automation,
  } = useLDFlags();

  /* If a user is enabled for at least one between 'revenue and cost' and
   * 'shipment performances' analysis he'll see the 'dashboard' and 'analysis'
   * links in the 'data' section
   */
  const isUserEnabledForOldAnalysis = useMemo(
    () =>
      !isOptimizerMenuItemVisible ||
      analysisShipmentPerformances ||
      analysisRevenueAndShippingCosts,
    [
      analysisRevenueAndShippingCosts,
      analysisShipmentPerformances,
      isOptimizerMenuItemVisible,
    ],
  );

  // Checking if the user has an active plan
  const {
    data: { hasActivePlan, redirectPath },
  } = useHasActivePlan();
  const accessNormalOrRedirectionLink = useCallback(
    (url: string) => ({
      link: hasActivePlan ? url : redirectPath?.split('/').at(-1) ?? null,
      isExternal: !hasActivePlan,
    }),
    [hasActivePlan, redirectPath],
  );

  // Easy Return
  // - setting a local state for features behind paywall
  const [openEasyReturn, setOpenEasyReturn] = useState(false);
  const [openApiMenu, setOpenApiMenu] = useState(false);
  const [openShipAndCollectMenu, setOpenShipAndCollectMenu] = useState(false);

  // - Bootstrapping the `Paywall` utility to block users who haven't paid for the specific `Easy Return` feature
  const { openPaywall } = useContext(PaywallContext)!;
  const onPaywalledFeatureClick = useCallback(
    (
      kpiPaywallInfo:
        | TwoRowsOneVideoLayoutPaywallInfo
        | TwoRowsOneImageLayoutPaywallInfo,
      isAvailable: boolean,
      openMenu: Dispatch<SetStateAction<boolean>>,
    ) => {
      return isAvailable
        ? openMenu(true)
        : openPaywall<
            PaywallDesign.TwoRowsSingleVideo | PaywallDesign.TwoRowsSingleImage
          >(
            kpiPaywallInfo['videoUrl']
              ? PaywallDesign.TwoRowsSingleVideo
              : PaywallDesign.TwoRowsSingleImage,
            kpiPaywallInfo,
          );
    },
    [openPaywall],
  );
  const onPaywalledPageClick = useCallback(
    (
      kpiPaywallInfo:
        | TwoRowsOneVideoLayoutPaywallInfo
        | TwoRowsOneImageLayoutPaywallInfo,
      isAvailable: boolean,
      url: string,
    ) => {
      return isAvailable
        ? navigate('/' + url)
        : openPaywall<
            PaywallDesign.TwoRowsSingleVideo | PaywallDesign.TwoRowsSingleImage
          >(
            kpiPaywallInfo['videoUrl']
              ? PaywallDesign.TwoRowsSingleVideo
              : PaywallDesign.TwoRowsSingleImage,
            kpiPaywallInfo,
          );
    },
    [navigate, openPaywall],
  );

  // - getting paywall settings
  const easyReturnPaywallConfig = FeaturePaywallInfo.RETURNMANAGEMENT;
  const isEasyReturnAvailable =
    useCheckAvailableFeature({
      feature: Features.EasyReturn,
    }).data?.is_feature_available ?? false;

  const apiPaywallConfig = FeaturePaywallInfo.APIMANAGEMENT;
  const isApiAvailable =
    (useCheckAvailableFeature({
      feature: Features.Api,
    }).data?.is_feature_available ??
      false) ||
    apiPageAccess;

  const shipAndCollectPaywallConfig = FeaturePaywallInfo.LIVECHECKOUT;
  const isShipAndCollectAvailable =
    (useCheckAvailableFeature({
      feature: Features.ShipAndCollect,
    }).data?.is_feature_available ??
      false) ||
    shipAndCollect;

  const optimizerPaywallConfig = FeaturePaywallInfo.OPTIMIZER;

  const menuLinks = useMemo(() => {
    return [
      {
        data: [
          {
            label: 'home',
            icon: 'IconHome',
            link: 'home',
            isExternal: false,
            isVisible: true,
          },
        ],
      },
      {
        category: 'solutions',
        data: [
          {
            label: 'labelCreator',
            icon: 'IconSend',
            link: null,
            isExternal: false,
            isVisible: true,
            subMenu: [
              {
                label: 'myOrders',
                link: 'ship',
                isExternal: false,
                isCustom: false,
                isVisible: true,
              },
              ...(platformToggleSwitch || uiAppMyDocuments
                ? [
                    {
                      label: 'myDocuments',
                      link: 'my-documents',
                      isExternal: false,
                      isCustom: false,
                      isVisible: true,
                    },
                  ]
                : [
                    {
                      label: 'myManifests',
                      link: 'my-manifests.html',
                      isExternal: true,
                      isCustom: false,
                      isVisible: true,
                    },
                    {
                      label: 'pickingLists',
                      link: 'my-pickinglists.html',
                      isExternal: true,
                      isCustom: false,
                      isVisible: true,
                    },
                  ]),
              {
                label: 'cashOnDelivery',
                link: 'cashondeliverymanager.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
              ...(platformToggleSwitch || uiAppMyDocuments
                ? []
                : [
                    {
                      label: 'invoiceManager',
                      link: 'invoicemanager.html',
                      isExternal: true,
                      isCustom: false,
                      isVisible: true,
                    },
                  ]),
              {
                label: 'pickupManager',
                link: 'pickupmanager.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
              {
                label: 'shippingRules',
                link: 'shipping-rules.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
            ],
          },
          {
            label: 'trackTrace',
            icon: 'IconMapPin',
            link: null,
            isExternal: false,
            isVisible: true,
            subMenu: [
              {
                label: 'overview',
                link: 'trackandtrace-overview.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
              {
                label: 'emailCondition',
                link: 'trackandtrace-emailtriggers.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
              {
                label: 'emailTemplate',
                link: 'trackandtrace-emailtemplates.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
              {
                label: 'mobileNotification',
                link: 'trackandtrace-mobilenotification.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
              {
                label: 'trackingPage',
                link: 'customize-tracking-page.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
              {
                label: 'myFeedback',
                link: 'feedbackmanager.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
              {
                label: 'customerCare',
                link: 'customercare.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
            ],
          },
          {
            // Easy Return will trigger an action on click
            label: 'easyReturn',
            icon: 'IconCornerDownLeft',
            link: null,
            isExternal: false,
            isVisible: true,
            isCustom: true,
            lockComponent: isEasyReturnAvailable ? undefined : (
              <CommonBadgeLock />
            ),
            onClick: () =>
              onPaywalledFeatureClick(
                easyReturnPaywallConfig,
                isEasyReturnAvailable,
                setOpenEasyReturn,
              ),
            subMenu: [
              {
                label: 'analytics',
                link: 'return-analytics.html',
                isExternal: true,
                isCustom: false,
                isVisible: openEasyReturn,
              },
              {
                label: 'returnForm',
                link: 'return-form.html',
                isExternal: true,
                isCustom: false,
                isVisible: openEasyReturn,
              },
              {
                label: 'returnRules',
                link: 'return-rules.html',
                isExternal: true,
                isCustom: false,
                isVisible: openEasyReturn,
              },
              {
                label: 'returnShipment',
                link: 'return-trackandtrace-overview.html',
                isExternal: true,
                isCustom: false,
                isVisible: openEasyReturn,
              },
              {
                label: 'emailTemplates',
                link: 'return-emailtemplates.html',
                isExternal: true,
                isCustom: false,
                isVisible: openEasyReturn,
              },
            ],
          },
          {
            label: 'shipAndCollect',
            icon: 'IconDroplet',
            link: null,
            isExternal: false,
            isVisible: true,
            lockComponent: isShipAndCollectAvailable ? undefined : (
              <CommonBadgeLock />
            ),
            onClick: () =>
              onPaywalledFeatureClick(
                shipAndCollectPaywallConfig,
                isShipAndCollectAvailable,
                setOpenShipAndCollectMenu,
              ),
            subMenu: [
              {
                label: 'mapsSetup',
                link: 'drop-off-points-map.html',
                isExternal: true,
                isCustom: false,
                isVisible: openShipAndCollectMenu,
              },
              {
                label: 'advanceSetup',
                link: 'drop-off-points-map-code.html',
                isExternal: true,
                isCustom: false,
                isVisible: openShipAndCollectMenu,
              },
              {
                label: 'pricingUsage',
                link: 'drop-off-points-pricing.html',
                isExternal: true,
                isCustom: false,
                isVisible: openShipAndCollectMenu,
              },
            ],
          },
          {
            label: 'pickAndPack',
            icon: 'IconRadar2',
            link: null,
            isExternal: false,
            isVisible: pickAndPack,
            subMenu: [
              {
                label: 'list',
                link: 'pickandpack.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
              {
                label: 'productsLocation',
                link: 'pickandpack-products.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
            ],
          },
          {
            label: 'analytics',
            icon: 'IconChartBar',
            link: null,
            isExternal: false,
            isVisible: true,
            subMenu: [
              {
                label: 'newAnalytics',
                isVisible: isOptimizerMenuItemVisible,
                isCustom: true,
                lockComponent: canAccessOptimizer ? undefined : (
                  <CommonBadgeLock />
                ),
                onClick: () =>
                  onPaywalledPageClick(
                    optimizerPaywallConfig,
                    canAccessOptimizer,
                    'data/optimizer',
                  ),
                ...accessNormalOrRedirectionLink('data/optimizer'),
              },
              {
                label: 'dashboards',
                isCustom: false,
                isVisible: isUserEnabledForOldAnalysis,
                ...accessNormalOrRedirectionLink('data/dashboards'),
              },
              {
                label: 'analysis',
                isCustom: false,
                isVisible: isUserEnabledForOldAnalysis,
                ...accessNormalOrRedirectionLink('data/analysis'),
              },
              {
                label: 'invoiceAnalysis',
                isCustom: false,
                isVisible: carrierInvoiceAnalysis,
                ...accessNormalOrRedirectionLink('data/carrierinvoiceanalysis'),
              },
              {
                label: 'invoiceAnalysis',
                link: 'carrierinvoiceanalysis.html',
                isExternal: true,
                isCustom: false,
                isVisible: !carrierInvoiceAnalysis,
              },
            ],
          },
          {
            label: 'automation',
            icon: 'IconSettingsAutomation',
            link: 'automation',
            isExternal: false,
            isVisible: automation,
          },
        ],
      },
      {
        category: 'integrations',
        data: [
          {
            label: 'carriers',
            icon: 'IconTruck',
            link: 'my-couriers.html',
            isExternal: true,
            hasDot: false,
            dotProps: {
              isPulsing: true,
            },
            isVisible: true,
          },
          {
            label: 'stores',
            icon: 'IconBuildingStore',
            link: 'marketplace.html',
            isExternal: true,
            isVisible: true,
          },
        ],
      },
      {
        category: 'more',
        data: [
          {
            label: 'utilities',
            icon: 'IconLayoutGrid',
            link: null,
            isExternal: false,
            isVisible: true,
            subMenu: [
              {
                label: 'taskScheduler',
                link: 'cronjobs.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
              {
                label: 'eBayImporter',
                link: 'admin-ebayimporter.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
              {
                label: 'plugins',
                link: 'integrations.html',
                isExternal: true,
                isCustom: false,
                isVisible: true,
              },
            ],
          },
          {
            label: 'api',
            icon: 'IconCode',
            link: null,
            isExternal: false,
            isVisible: true,
            lockComponent: isApiAvailable ? undefined : <CommonBadgeLock />,
            onClick: () =>
              onPaywalledFeatureClick(
                apiPaywallConfig,
                isApiAvailable,
                setOpenApiMenu,
              ),
            subMenu: [
              {
                label: 'api',
                link: 'apikeys.html',
                isExternal: true,
                isCustom: false,
                isVisible: openApiMenu,
              },
              {
                label: 'apiConsole',
                link: 'apiconsole.html',
                isExternal: true,
                isCustom: false,
                isVisible: openApiMenu,
              },
            ],
          },
        ],
      },
    ];
  }, [
    platformToggleSwitch,
    uiAppMyDocuments,
    isEasyReturnAvailable,
    openEasyReturn,
    isShipAndCollectAvailable,
    openShipAndCollectMenu,
    pickAndPack,
    isOptimizerMenuItemVisible,
    canAccessOptimizer,
    accessNormalOrRedirectionLink,
    isUserEnabledForOldAnalysis,
    carrierInvoiceAnalysis,
    isApiAvailable,
    openApiMenu,
    onPaywalledFeatureClick,
    easyReturnPaywallConfig,
    shipAndCollectPaywallConfig,
    onPaywalledPageClick,
    optimizerPaywallConfig,
    apiPaywallConfig,
    automation,
  ]);

  return menuLinks;
};

export default useSideMenuLinks;
