import {
  endOfDay,
  getUnixTime,
  parse,
  parseISO,
  startOfDay,
  getYear,
} from 'date-fns';

import { TFunction } from 'i18next';
import { translations } from '@shippypro/translations';

import { DateRange } from '@web/types/filters';
import { formatDate } from '@web/utils/@date-fns/dates';

/**
 * Utils to get a date from and a date to from a string interval
 *
 * @param interval
 * @param customStartDate date from which start calculate a range (es. yesterday and not today)
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
const getDatesFromRange = (interval: DateRange, customStartDate?: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const startDate = customStartDate ?? today;

  const endOfCurrentDay = new Date().setHours(23, 59, 59, 999);
  const endOfCurrentDayDate = new Date(endOfCurrentDay);

  const yesterday = new Date(today);
  yesterday.setHours(0, 0, 0, 0);
  yesterday.setDate(today.getDate() - 1);

  const endOfYesterday = new Date(yesterday).setHours(23, 59, 59, 999);
  const endOfYesterdayDate = new Date(endOfYesterday);

  switch (interval) {
    case DateRange.Today:
      return [today, endOfCurrentDayDate];
    case DateRange.Yesterday:
      return [yesterday, endOfYesterdayDate];
    case DateRange.LastWeek:
      const lastWeek = new Date(startDate).setDate(startDate.getDate() - 6);
      const lastWeekDate = new Date(lastWeek);
      return [lastWeekDate, startDate];
    case DateRange.LastMonth:
      const lastMonth = new Date(startDate).setDate(startDate.getDate() - 29);
      const lastMonthDate = new Date(lastMonth);
      return [lastMonthDate, startDate];
    default:
      return [startDate, endOfCurrentDayDate];
  }
};

const getRangeLabels = (t: TFunction) => {
  const transFilters = translations.ship.table.filters;
  return {
    [DateRange.Today]: t(transFilters.today),
    [DateRange.Yesterday]: t(transFilters.yesterday),
    [DateRange.LastWeek]: t(transFilters.lastWeek),
    [DateRange.LastMonth]: t(transFilters.lastMonth),
  };
};

const getLabelsFromRange = (label: DateRange, t: TFunction): string => {
  const labels = getRangeLabels(t);
  return labels[label];
};

const getDateRangeValue = (t: TFunction, dateRange?: Date[] | DateRange) => {
  if (!dateRange || dateRange.length === 0) {
    return undefined;
  }

  if (typeof dateRange === 'string') {
    return getLabelsFromRange(dateRange, t);
  }

  // Hide year in applied filter label if the date range is in the same year
  let isSameYearDate = false;
  try {
    isSameYearDate =
      Array.isArray(dateRange) && dateRange.length > 1
        ? getYear(parseISO(dateRange[0].toString())) ===
          getYear(parseISO(dateRange[1].toString()))
        : false;
  } catch (e) {
    // Fail silently since we don't want to break the UI just for a date parsing error
  }

  return dateRange.length === 1 ||
    formatDate(dateRange[0].toString(), 'dd/MM/yyy', true) ===
      formatDate(dateRange[1].toString(), 'dd/MM/yyy', true)
    ? `${formatDate(dateRange[0].toString(), 'dd/MM/yyy', true)}`
    : `${formatDate(dateRange[0].toString(), isSameYearDate ? 'dd/MM' : 'dd/MM/yyy', true)} - ${formatDate(
        dateRange[1].toString(),
        'dd/MM/yyy',
        true,
      )}`;
};

export const getDatesFromAndToFromRangeBase = (
  dateRange: Date[] | (Date | undefined)[] | DateRange | undefined,
  customStartDate?: Date,
  format: string = "yyyy-MM-dd'T'HH:mm:ss.SSSX",
) => {
  let dateFrom: Date | undefined = undefined;
  let dateTo: Date | undefined = undefined;
  if (typeof dateRange === 'string') {
    const dateRangeValues = getDatesFromRange(dateRange, customStartDate);
    if (dateRangeValues?.length) {
      dateFrom = dateRangeValues[0];
      dateTo = dateRangeValues[1];
    }
  } else if (dateRange && dateRange.length === 1 && dateRange[0]) {
    dateFrom = dateRange[0];
  } else if (
    dateRange &&
    dateRange.length > 1 &&
    dateRange[0] &&
    dateRange[1]
  ) {
    // FIXME: Standardise date formatting throughout the filters to avoid multiple-formattings
    const formattedFrom =
      typeof dateRange[0] === 'string'
        ? parse(dateRange[0], format, new Date())
        : dateRange[0];
    const formattedTo =
      typeof dateRange[1] === 'string'
        ? parse(dateRange[1], format, new Date())
        : dateRange[1];

    dateFrom = formattedFrom;
    dateTo = formattedTo;
  }

  return {
    dateFrom,
    dateTo,
  };
};

export const getDatesFromAndToFromRange = (
  dateRange: Date[] | (Date | undefined)[] | DateRange | undefined,
  customStartDate?: Date,
  format: string = "yyyy-MM-dd'T'HH:mm:ss.SSSX",
) => {
  const { dateFrom, dateTo } = getDatesFromAndToFromRangeBase(
    dateRange,
    customStartDate,
    format,
  );

  return {
    dateFrom: dateFrom ? getUnixTime(startOfDay(dateFrom)) : undefined,
    dateTo: dateTo ? getUnixTime(endOfDay(dateTo)) : undefined,
  };
};

export const getDatesFromAndToFromRangeISO = (
  dateRange: Date[] | (Date | undefined)[] | DateRange | undefined,
  customStartDate?: Date,
  format: string = "yyyy-MM-dd'T'HH:mm:ss.SSSX",
) => {
  const { dateFrom, dateTo } = getDatesFromAndToFromRangeBase(
    dateRange,
    customStartDate,
    format,
  );

  return {
    dateFrom: dateFrom ? startOfDay(dateFrom).toISOString() : undefined,
    dateTo: dateTo ? endOfDay(dateTo).toISOString() : undefined,
  };
};

export {
  getDatesFromRange,
  getRangeLabels,
  getLabelsFromRange,
  getDateRangeValue,
};
