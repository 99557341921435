import { FC, memo, useCallback, useContext } from 'react';
import classnames from 'classnames';
import { Spinner } from 'reactstrap';

import HubspotForm from 'react-hubspot-form';

import { Card, CardBody } from '@shippypro/design-system-web';

import HelpSectionContext from '@web/features/help/contexts/HelpSectionContext';

import { HelpSubSection } from '@web/features/help/types';

interface ITicketingProps {}

/**
 * This component is the container of the Ticketing System iFrame that is embedded via a custom library integration.
 * The whole component is only hidden in CSS so that the user can navigate elsewhere without losing any previously-typed data.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const Ticketing: FC<ITicketingProps> = memo(() => {
  const { subSection } = useContext(HelpSectionContext)!;

  // Redirecting to our external ticketing system
  const onOpenTicketingSystem = useCallback(
    /* istanbul ignore next */
    () => {
      window.open(
        'https://support.shippypro.com/tickets-view?offset=0',
        '_blank',
        'noopener noreferrer',
      );
    },
    [],
  );

  return (
    <section
      data-test="embedded-ticketing-section"
      className={classnames('w-full flex flex-col !gap-4 !px-3', {
        hidden: subSection !== HelpSubSection.ticketing,
      })}
    >
      <Card className="mb-0">
        <CardBody className="!py-4" onClick={onOpenTicketingSystem}>
          <HubspotForm
            portalId="25285487"
            formId="807757d4-9b4b-449e-bb13-485bd4998af5"
            region="eu1"
            loading={<Spinner className="flex m-auto" />}
          />
        </CardBody>
      </Card>
    </section>
  );
});

export default Ticketing;
