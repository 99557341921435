import { showErrorToastr } from '@shippypro/design-system-web/functions';
import { translations } from '@shippypro/translations';

/**
 * * Converts a file to the Base64 standardized format
 *
 * @param {Blob} file The file to convert
 *
 * @return {Promise<unknown>} The Base64 promise handler
 */
export function fileToBase64(file: Blob): Promise<unknown> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

/**
 * Open a document browser window to preview or download a pdf/zip file
 * @param {string} data The document string in base64
 * @param {string}  type The mime type (default 'application/pdf')
 */
export function downloadBase64File(data, type = 'application/pdf') {
  let blob;

  // Parsing the data into a BLOB structure
  blob = b64toBlob(data, type);
  const blobURL = URL.createObjectURL(blob);

  // Creating a virtual window out of the BLOB
  const theWindow = window.open(blobURL);
  if (!theWindow) return;
  const theDoc = theWindow!.document;

  // Opening the Blob as a new browser tab
  const theScript = document.createElement('script');
  function injectThis() {
    window.print();
  }
  theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
  theDoc.body.appendChild(theScript);
}

function b64toBlob(content, contentType) {
  contentType = contentType || '';
  const sliceSize = 512;
  // method which converts base64 to binary
  const byteCharacters = window.atob(content);

  const byteArrays = [] as any;
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {
    type: contentType,
  }); // statement which creates the blob
  return blob;
}

/**
 * Converts a base64 string to a .xls file and triggers the download
 *
 * @param {string} base64String The base64 string
 */
export function base64ToXls(
  base64String: string,
  fileName: string = 'tab',
): void {
  const bindata = window.atob(base64String);

  var bytes = new Array(bindata.length);
  for (var i = 0; i < bindata.length; i++) {
    bytes[i] = bindata.charCodeAt(i);
  }

  const unit8Array = new Uint8Array(bytes);

  var blob = new Blob([unit8Array], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  let objectURL = window.URL.createObjectURL(blob);
  let anchor = document.createElement('a');

  anchor.href = objectURL;
  anchor.download = `${fileName}.xls`;
  anchor.click();

  URL.revokeObjectURL(objectURL);
}

/**
 * Function to convert base64 string to zip file and open it in new tab
 * for preview or download
 *
 * @param base64String base64 string
 * @param fileName file name
 */
export function base64ToZip(base64String: string, fileName: string) {
  const type = 'application/zip';
  const blobBase64 = b64toBlob(base64String, type);
  const blob = new Blob([blobBase64], { type: type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}

/**
 * This function downloads a file from a given url.
 *
 * @param url {string} The url to download
 * @returns {bolean} True if the download was successful, false otherwise
 */
/* istanbul ignore next */
export async function downloadFile(url: string): Promise<boolean> {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = url.substring(url.lastIndexOf('/') + 1);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  } catch (e) {
    throw new Error();
  }
}

/**
 * Downloads a CSV file with the given content and file name.
 *
 * @param content - The content of the CSV file.
 * @param fileName - The name of the CSV file.
 */
export function downloadCsv(content: string, fileName: string) {
  const csvContent = 'data:text/csv;charset=utf-8,' + content;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  if (!fileName.endsWith('.csv')) {
    fileName += '.csv';
  }
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Manages file processing, validation and upload
 *
 * @param file - The file to check and upload
 * @param allowedExtensions - The list of allowed extensions
 * @param onUploadComplete - The action to execute on upload
 * @param t - The translation function
 * @param unsupportedFileErrorKey - The key for the unsupported file error message
 * @param cannotReadFileErrorKey - The key for the cannot read file error message
 * @returns {Promise<T | undefined>} The result of the upload
 */

interface handleFileUploadProps<T> {
  file: File | null;
  allowedExtensions: string[];
  onUploadComplete: (fileData: {
    name: string;
    extension: string;
    base64File: string;
  }) => Promise<T | undefined>;
  t: (key: string) => string;
  unsupportedFileErrorKey: string;
  cannotReadFileErrorKey: string;
}

export const handleFileUpload = async <T>({
  file,
  allowedExtensions,
  onUploadComplete,
  t,
  unsupportedFileErrorKey,
  cannotReadFileErrorKey,
}: handleFileUploadProps<T>): Promise<T | undefined> => {
  if (!file) return;

  let base64File = (await fileToBase64(file)) as string;

  const b64Substr = ';base64,';
  if (base64File.includes(b64Substr)) {
    base64File = base64File.split(b64Substr)[1];
  }

  const extension = file.name.split('.').pop() || '';

  if (!allowedExtensions.find(v => v.toString() === extension)) {
    showErrorToastr(
      t(translations.common.errors.error),
      t(unsupportedFileErrorKey),
    );
    return;
  } else if (!base64File) {
    showErrorToastr(
      t(translations.common.errors.error),
      t(cannotReadFileErrorKey),
    );
    return;
  }

  return await onUploadComplete({
    name: file.name,
    extension,
    base64File,
  });
};
