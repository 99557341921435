import { Sorting } from './sorting';

export type PaginatedData<T> = {
  data: T[];
  pagination: Pagination | null;
};

export type Pagination = {
  page_number: number;
  total_pages: number;
  rows: number;
  total_rows: number;
};

export type PaginatedRequest<T> = T & {
  pagination: {
    page_number: number;
    rows: number;
  };
};

export type RemoteTableData<DataType, RequestFiltersType> = {
  sorting: Sorting | null;
  filters: RequestFiltersType;
  pagination: Pagination | null;
  data: DataType[];
};
