import { useMemo } from 'react';
import { User } from '@web/types/global';
import { GetUserId } from '@shippypro/api-client/utils';
import { useGetUserQuery } from '@web/features/_global/api';
import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

/**
 * Returns RTK Query hook with renamed data field.
 * Also sets the user's id into the user data returned by the query.
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetUser = (options?: CustomRTKQueryOptions) => {
  const id = GetUserId();
  const { data: user, error, ...rest } = useGetUserQuery(undefined, options);

  const errorReason = useMemo(() => {
    if (error === undefined) return undefined;
    const e = error as {
      status: number;
      data: any;
      error?: string;
    };
    return e.status.toString();
  }, [error]);

  const userWithId = useMemo<User | undefined>(() => {
    if (!user) return undefined;
    return {
      ...user,
      id,
    };
  }, [user, id]);

  return useMemo(
    () => ({
      user: userWithId,
      error,
      errorReason,
      ...rest,
    }),
    [userWithId, error, errorReason, rest],
  );
};
