import styled from 'styled-components';
import { Row } from 'reactstrap';

const StyledContainer = styled(Row)<{
  $fullHeight: boolean;
  $unpadCards: boolean;
}>`
  height: 100%;
  width: 100%;

  padding: 0 !important;
  margin: 0 !important;

  // ---------------------[ TITLE CUSTOMISATION ]
  .card-with-top-tabs-title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.5rem;
    color: var(--shp-color-genericui-primary);
  }

  // ---------------------[ NAVS CUSTOMISATION ]

  .nav-tabs .nav-link.active:after {
    transform: translate3d(10px, 10px, 10px);
  }
`;

export default StyledContainer;
