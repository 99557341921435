import axios from 'axios';
import * as jwt from 'jsonwebtoken';

import { requiredScopes, CacheItem } from '@shippypro/api-client/utils';
import { isTestingEnv } from '@shippypro/utils';
import { goToLegacy } from '@shippypro/utils';

// used a dedicated axios client to send the refresh token calls
// just to be sure it doesn't mess with the main client (i.e. 401 interceptor loop)
const refreshTokenClient = axios.create();

/* istanbul ignore next */
async function refreshAccessTokenLogic() {
  const refreshTokenUrl = [
    process.env.NX_LEGACY_URL!,
    process.env.NX_NEW_TOKEN_PAGE!,
  ].join('/');

  const refreshToken = localStorage.getItem('refreshToken');

  try {
    if (!refreshToken) {
      throw new Error('missing refreshToken');
    }

    const requestData = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: localStorage.getItem('clientId'),
      client_secret: localStorage.getItem('clientSecret'),
      scope: requiredScopes,
    };

    const { data } = await refreshTokenClient.post(
      refreshTokenUrl,
      requestData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    if (data.hasOwnProperty('error')) {
      throw Error(`${data.error_description} | ${data.hint}`);
    }
    if (data.token_type !== 'Bearer') {
      throw new Error(`Unhandled token type ${data.token_type}`);
    }
    if (!data.access_token) {
      throw new Error('Missing token in refreshToken response');
    }
    localStorage.setItem('accessToken', data.access_token);

    // Cache the JWT-encoded Access Token
    CacheItem('portal-token', 'jwt-token', data.access_token);
    localStorage.setItem('refreshToken', data.refresh_token);

    const jwtData = jwt.decode(data.access_token);
    // Set the decoded JWT data in the app cache
    CacheItem('portal-token', 'jwt', jwtData);

    // notifies App that the token has been refreshed
    // (since it's read from localStorage, it's not "reactive")
    document.dispatchEvent(new CustomEvent('refreshed-token'));

    return Promise.resolve(data.access_token);
  } catch (e) {
    console.error(e);
    const isNetworkError = /Network Error/.test((e as Error).toString());
    if (!isNetworkError && !isTestingEnv()) {
      goToLegacy();
    }
  }
}

let refreshTokenPromise: null | Promise<string>;
export function getOrCreateRefreshTokenPromise() {
  if (!refreshTokenPromise) {
    refreshTokenPromise = refreshAccessTokenLogic().then(
      /* istanbul ignore next */
      newToken => {
        refreshTokenPromise = null;
        return newToken;
      },
    );
  }
  return refreshTokenPromise;
}
