import { memo, MemoExoticComponent, useMemo } from 'react';
import { Spinner } from 'reactstrap';

import { lazyLoad, SuspenseOpts } from '@web/utils/@react-suspense/loadable';

/**
 * [HOOK] useGetLazyLoad takes the folder name in the 'features' folder and returns a Lazy-Loaded index for that Page.
 *
 * @param {string} path The name of your folder
 *
 * @returns {(props: any) => JSX.Element} the lazy-loaded JSX element
 *
 * @author [Emanuele Moricci](https://github.com/emanuele-moricci-shippypro)
 */
const useGetLazyLoad = (
  path: string,
  cmpName?: string | null,
  opts?: SuspenseOpts,
): React.FC<any> => {
  return useMemo(
    () =>
      lazyLoad(
        () => import(`features/${path}/index`),
        module =>
          module[cmpName ?? path.charAt(0).toUpperCase() + path.slice(1)],
        opts,
      ),
    [cmpName, opts, path],
  );
};

const BasicFullPageLazyLoader = memo(() => (
  <div className="w-auto h-32 flex">
    <Spinner size="lg" className="m-auto" />
  </div>
));

export const BasicSmallLazyLoader = memo(() => (
  <div className="w-auto flex">
    <Spinner className="m-auto w-6 h-6" />
  </div>
));

/**
 * [HOOK] get the basic spinner option for when a component is lazy-loading, and optionally
 * pass a custom fallback component.
 *
 * @param {MemoExoticComponent<() => JSX.Element>} [FallbackElement=BasicFullPageLazyLoader] the custom fallback component
 *
 * @returns {SuspenseOpts} the options for the lazy-loading
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const useGetBasicLazyOpts = (
  FallbackElement: MemoExoticComponent<
    () => JSX.Element
  > = BasicFullPageLazyLoader,
  avoidReload: boolean = false,
): SuspenseOpts =>
  (useMemo ?? (() => {}))(
    () => ({
      fallback: <FallbackElement />,
      avoidReload,
    }),
    [FallbackElement],
  );

export const useGetBasicLazyComponentOpts = (
  FallbackElement: MemoExoticComponent<
    () => JSX.Element
  > = BasicFullPageLazyLoader,
): SuspenseOpts => useGetBasicLazyOpts(FallbackElement, true);

export default useGetLazyLoad;
