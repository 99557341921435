import styled, { css } from 'styled-components';

import { PopoverWithClickoutside } from '@ds-web/components';
import { QuickFilterTagProps } from './QuickFilter.props';

export const QuickFilterContainer = styled.div<{ active: boolean }>`
  height: 28px;
  padding: 0 7px;
  background: var(--shp-color-bg);
  border: 1px solid var(--shp-color-genericborder-input);
  border-radius: 2.5px;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  gap: 3px;
  cursor: pointer;

  &:hover {
    border-color: var(--shp-color-bg-dark-highlight);
  }
`;

const dot = css`
  &:after {
    content: '';
    margin-left: 3px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--shp-color-genericui-accent);
  }
`;

export const QuickFilterLabel = styled.span<{ withDot?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  color: var(--shp-color-text-tab-title);
  display: flex;
  align-items: center;

  ${({ withDot }) => withDot && dot}
`;

export const QuickFilterPopover = styled(PopoverWithClickoutside)`
  & .popover {
    box-shadow: 3px 7px 20px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #ebedee;
    border-radius: 10px;
  }

  .popover-body {
    border: none !important;
    padding: 0;
  }

  &&& .popover-arrow {
    display: none; !important
  }
  
  #countries-tooltip-target,
  #countries-tooltip-target div {
    max-height: inherit;

    .form-select-element {
      max-height: inherit;

      .select__control {
        max-height: inherit;

        .select__value-container {
          max-height: inherit;
          overflow-y: auto;
        }
      }
    }
  }
`;

export const QuickFilterTagContainer = styled.div<
  Pick<QuickFilterTagProps, 'standalone' | 'value'>
>`
  flex-shrink: 0;
  height: 20px;
  padding-left: 7px;
  background-color: #f2effc;
  border: 0.7px solid #cabbf1;
  border-radius: 3px;
  display: inline-flex;
  align-items: stretch;
  cursor: pointer;
  overflow: hidden;

  ${({ standalone }) =>
    standalone &&
    `
      height: 28px;

      & > ${QuickFilterTag} {
        font-size: 14px;
      }
    `};

  ${({ standalone, value }) =>
    standalone &&
    !value &&
    `
      border: 1px solid var(--shp-color-genericborder-input);
      background-color: var(--shp-color-bg);

      & > ${QuickFilterTag} {
        font-weight: 400;
        color: var(--shp-color-text-tab-title);
      }
    `};
`;

export const QuickFilterTag = styled.span<{ withDot?: boolean }>`
  font-size: 13px;
  font-weight: 500;
  color: var(--shp-color-genericui-accent);
  padding-right: 7px;
  align-self: center;
  display: flex;
  align-items: center;

  ${({ withDot }) => withDot && dot}
`;

export const QuickFilterTagAction = styled.div`
  border-left: 0.7px solid #cabbf1;
  padding: 0 5px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: var(--shp-color-bg-accent);

    svg {
      stroke: white;
    }
  }
`;
