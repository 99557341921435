/**
 * Returns the object without the highlighted props.
 *
 * @param {T} object - The object.
 * @param {string[]} props - The props to be removed from the object.
 * @returns {Omit<T, keyof typeof props>} - The object without the highlighted props.
 */
export function removePropsFromObject<T extends Record<string, any>>(
  object: T,
  props: string[],
): Omit<T, keyof typeof props> {
  const payloadCopy = { ...object };
  props.forEach(prop => delete payloadCopy[prop]);
  return payloadCopy;
}
