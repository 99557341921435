// ########################## [WHY DID YOU RENDER]
/// <reference types="@welldone-software/why-did-you-render" />
import '@web/utils/@wdyr/wdyr';

// ########################## [POLYFILLS]
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// ########################## [IMPORTANT LIBRARIES]
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// ########################## [IMPORTED STYLES]
import 'sanitize.css/sanitize.css';
import 'react-intl-tel-input/dist/main.css';
import '@shippypro/design-system-web/styles';
import 'assets/styles/tailwind/index.scss';
import 'assets/styles/custom/index.scss';

// ########################## [INITIALISE LANGUAGES]
import '@shippypro/translations/init';

// ########################## [INITIALISERS]
import { initSentry } from './utils/@sentry';
import { store } from '@web/utils/@reduxjs/configureStore';
import '@web/utils/@user-tracking/init';

// ########################## [FALLBACKS]
import FatalErrorPage from './features/500';

// ########################## [APP PROPER]
import { App } from '@web/features';
import { isLocalEnv, isProductionEnv } from '@shippypro/utils';

initSentry();

const root = createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

(async () => {
  require('dotenv').config();

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.NX_LAUNCHDARKLY_CLIENTID || 'CLIENTID_NOT_FOUND',
    context: {
      kind: 'user',
      key: '0',
      name: 'Anonymous User',
      email: 'anonymous@shippypro.com',
    },
    options: { disableSyncEventPost: true },
  });

  root.render(
    <LDProvider>
      <Provider store={store}>
        <HelmetProvider>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <ErrorBoundary FallbackComponent={FatalErrorPage}>
                <App />
                {/* Commented to avoid error on CY tests de-comment to use it in local env isLocalEnv() && <ReactQueryDevtools initialIsOpen={false} /> */}
              </ErrorBoundary>
            </QueryClientProvider>
          </BrowserRouter>
        </HelmetProvider>
      </Provider>
    </LDProvider>,
  );
})();

// Hot reloadable translation json files
if (module.hot) {
  /* istanbul ignore next */
  module.hot.accept(['@shippypro/translations/init'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// FIXME: TEMPORARY, we need to purge logs from being overrun by the old libraries
if (!isProductionEnv()) {
  // eslint-disable-next-line no-console
  const originalWarn = console.error;
  // eslint-disable-next-line no-console
  console.error = (...args) => {
    if (
      args[0] &&
      typeof args[0] === 'string' &&
      (args[0].includes(
        'Support for defaultProps will be removed from function components in a future major release.',
      ) ||
        args[0].includes(
          'findDOMNode is deprecated and will be removed in the next major release.',
        ))
    ) {
      return;
    }
    originalWarn(...args);
  };
}
