import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

type TagType = string | { type: string; id?: string | number };

/**
 * [HOOK] This hook creates a callback that is able to manually
 * invalidate already loaded queries (by their RTK Query tags)
 * and force them to refetch their data again.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {(tags: TagType[]) => void} A function that triggers the manual invalidation when fired.
 */
const useManualQueryInvalidation = () => {
  const dispatch = useDispatch();

  return useCallback(
    (tags: TagType[]) =>
      dispatch({
        type: `api/invalidateTags`,
        payload: tags,
      }),
    [dispatch],
  );
};

export default useManualQueryInvalidation;
