import styled from 'styled-components';

export const Content = styled.div<{ $justify?: string }>`
  width: 100%;
  display: flex;
  justify-content: ${({ $justify }) => $justify || 'flex-start'};
  align-items: center;

  &:not(:empty) {
    padding: 0.5rem 0;
  }
`;
