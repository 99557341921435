import { FC } from 'react';
import classnames from 'classnames';

import { IDetailRowProps } from './types';

/**
 * A component that displays a simple row with title, content and a custom element on the right.
 *
 * @interface IDetailRowProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const DetailRow: FC<IDetailRowProps> = props => {
  const {
    code,
    title,
    content,
    rightElement,
    titleClassName = '',
    contentClassName = '',
    rightElementClassName = '',
  } = props;

  return (
    <div
      data-test={code + '-detail-row'}
      className="w-full flex justify-between items-center border-b-2"
    >
      <div className="flex flex-col p-2">
        <div
          data-test={code + '-title-detail-row'}
          className={classnames(titleClassName)}
        >
          {title}
        </div>
        <div
          data-test={code + '-content-detail-row'}
          className={classnames(contentClassName)}
        >
          {content}
        </div>
      </div>
      {rightElement && (
        <div
          data-test={code + '-right-element-detail-row'}
          className={classnames('flex pr-[1.5rem]', rightElementClassName)}
        >
          {rightElement}
        </div>
      )}
    </div>
  );
};
