// ########################## [IMPORTANT LIB]
import { memo, useCallback, useEffect, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import classnames from 'classnames';

// ########################## [DESIGN SYSTEM]
import { Toastr } from '@shippypro/design-system-web';
import { useGetDeviceSize } from '@shippypro/design-system-web/hooks';

// ########################## [PAGE COMPONENTS]
import Navbar from '@web/features/_global/layout/Navigation/Header/Header';
import { Changelog } from '@web/features/changelog-modal';
import { Paywall } from '@web/features/paywall';
import { HelpSection } from '@web/features/help';
import { ManageOrderModal } from '@web/features/manage-order-modal';
import { TrackingDialog } from '@web/features/tracking-dialog';
import { TopProgressBar } from '@web/features/top-progress-bar';
import { AppRoutes } from '@web/features/routes';
import SideMenu from './layout/SideMenu/SideMenu';
import ScrollToTop from './layout/Floaters/ScrollToTop';
import { SetupBanner } from './layout/Banners/Setup';
import StatusPageAlertsContainer from '@web/features/shared/alerts/status-page';
import { PageHeaderContextProvider, PageHeader } from '../page-header';

// ########################## [UTILS]
import * as Sentry from '@sentry/browser';
import { toLDUser } from '@web/utils/@launchdarkly';
import { toSentryUser } from '@web/utils/@sentry';
import { logout } from '@shippypro/utils';

// ########################## [CONTEXTS]
import PaywallContext from '@web/features/paywall/contexts/PaywallContext';
import HelpSectionContext from '@web/features/help/contexts/HelpSectionContext';
import ManageOrderModalExtContext from '@web/features/manage-order-modal/contexts/ManageOrderModalExtContext';
import TrackingDialogContext from '@web/features/tracking-dialog/contexts/TrackingDialogContext';
import TopProgressBarContext from '@web/features/top-progress-bar/contexts/TopProgressBarContext';
import QzTrayManagerContext from '@web/features/qz-tray/contexts/QzTrayManagerContext';
import ProfileDetailsContext from '@shippypro/foundation/settings/context/profile-details';
import { TranslationsProvider } from '@web/contexts/translations-provider';
import MFAContext from '@shippypro/foundation/settings/context/mfa';

// ########################## [HOOKS]
import { useGetUser } from './hooks/api/useGetUser';
import { useGetFeatureFlaggingCustomParameters } from './hooks/api/useGetFeatureFlaggingCustomParameters';
import { useGetRefinerFields } from './hooks/api/useGetRefinerFields';
import useShowParamsErrors from './hooks/useShowParamsMessages';
import useNavigationData from './hooks/useNavigationData';
import useIdentifyUserForTrackings from './hooks/useIdentifyUserForTrackings';
import useInstanceTopProgressBar from '@web/features/top-progress-bar/hooks/useInstanceTopProgressBar';
import useInstancePaywall from '@web/features/paywall/hooks/useInstancePaywall';
import useInstanceHelpSection from '@web/features/help/hooks/useInstanceHelpSection';
import useInstanceManageOrderModalExtState from '@web/features/manage-order-modal/hooks/useInstanceManageOrderModalExtState';
import useInstanceTrackingDialog from '@web/features/tracking-dialog/hooks/useInstanceTrackingDialog';
import useInstanceQzTrayManager from '@web/features/qz-tray/hooks/useInstanceQzTrayManager';
import { useInstanceProfileDetailsContext } from '@shippypro/foundation/settings/hooks/profile-details/useInstanceProfileDetailsContext';
import { useSideMenuStore } from './layout/SideMenu/store/side-menu-store';
import { useInstanceMFAContext } from '@shippypro/foundation/settings/hooks/security/mfa/useInstanceMFAContext';

const Authenticated: React.FC = memo(() => {
  const { user } = useGetUser();

  const { data: customParams } = useGetFeatureFlaggingCustomParameters();
  const { fields } = useGetRefinerFields();

  // Fetching the LaunchDarkly Client
  const client = useLDClient();

  const [isWaitingForLD, setWaitingForLD] = useState(true);

  useIdentifyUserForTrackings();

  useEffect(() => {
    // calling waitForInitialization should avoid LaunchDarklyFlagFetchError caused
    // by calling identify when the client is not ready yet
    if (isWaitingForLD)
      client?.waitForInitialization().then(() => {
        // re-initialize LaunchDarkly if the user wasn't present on load
        // or user id wasn't defined
        if (user && !!user.id) {
          client?.identify(toLDUser(user, customParams)).finally(() => {
            setWaitingForLD(false);
          });
          // Avoiding broadcast calls from re-freshing every connected user session throughout the app
          client.setStreaming(false);
        }

        if (user) {
          // Initialising the Sentry user to log them in case of an error
          Sentry.setUser(toSentryUser(user));
        }
      });
  }, [client, isWaitingForLD, setWaitingForLD, user, customParams, fields]);

  // Initialize the various global properties (theme, language, etc.)
  const { isMobile } = useGetDeviceSize();

  // Checking if the current route doesn't have the normal "sidemenu + navbar" layout
  const { pathname } = useLocation();
  const plainLayoutRoute = [/^\/$/, /\/redirect-check/, /\/onboarding/].some(
    rx => rx.test(pathname),
  );
  const noScrollTopRoute = [/\/onboarding/].some(rx => rx.test(pathname));
  const noSetupBanner = [/\/onboarding/, /\/home/].some(rx =>
    rx.test(pathname),
  );
  const noPageHeaderRoute = [/\/automation\/workflows\/.+/].some(rx =>
    rx.test(pathname),
  );

  // Create the Navigation Routes behaviors with the layout components
  const { isNavbarHidden } = useNavigationData();

  // Fetches any error from the url GET parameters (mainly used for the OAuth Callbacks)
  useShowParamsErrors();

  // Manage the visibility of the side menu
  const mobileSideMenuOpen = useSideMenuStore(state => state.isOpen);
  const isSideMenuPinned = useSideMenuStore(state => state.isPinned);
  const isSideMenuHidden = useSideMenuStore(state => state.isHidden);
  const {
    toggleOpen: setMobileSideMenuOpen,
    togglePin: setIsSideMenuPinned,
    toggleIsHidden,
  } = useSideMenuStore();
  const toggleMobileSideMenuOpen = useCallback(() => {
    setMobileSideMenuOpen();
  }, [setMobileSideMenuOpen]);

  useEffect(() => {
    toggleIsHidden((isMobile && !mobileSideMenuOpen) || plainLayoutRoute);
  }, [isMobile, mobileSideMenuOpen, plainLayoutRoute, toggleIsHidden]);

  // Instancing the context to allow any component in the project to summon the Top Progress Bar
  const topProgressBarProps = useInstanceTopProgressBar();

  // Instancing the context to allow any component in the project to open the Paywall modal
  const paywallProps = useInstancePaywall();

  // Instancing the context to allow any component in the project to open the Help section
  const helpSectionProps = useInstanceHelpSection();

  // Instancing the context to allow any component in the project to open the Manage Order modal
  const manageOrderModalProps = useInstanceManageOrderModalExtState();

  // Instancing the context to allow any component in the project to open the Tracking modal
  const trackingDialogProps = useInstanceTrackingDialog();

  // Instancing the context to allow any component in the project to access QzTray status
  const qzTrayManagerProps = useInstanceQzTrayManager();

  // Instancing the context to allow any component in the project to access profile details
  const profileDetails = useInstanceProfileDetailsContext();

  // Instancing the context to allow any component in the project to access mfa authentication and validation details
  const MFA = useInstanceMFAContext();

  if (isWaitingForLD) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spinner color="grey" size="xl" />
      </div>
    );
  }

  // Render the authenticated layout
  return (
    <PageHeaderContextProvider>
      <ProfileDetailsContext.Provider value={profileDetails}>
        <MFAContext.Provider value={MFA}>
          <TranslationsProvider>
            <TopProgressBarContext.Provider value={topProgressBarProps}>
              <PaywallContext.Provider value={paywallProps}>
                <HelpSectionContext.Provider value={helpSectionProps}>
                  <QzTrayManagerContext.Provider value={qzTrayManagerProps}>
                    <ManageOrderModalExtContext.Provider
                      value={manageOrderModalProps}
                    >
                      <TrackingDialogContext.Provider
                        value={trackingDialogProps}
                      >
                        <div className="wrapper vertical-layout theme-primary">
                          <div
                            id="drawer-root"
                            className="relative flex max-w-screen max-h-screen h-full overflow-hidden"
                          >
                            {!isSideMenuHidden && (
                              <SideMenu
                                isMobile={isMobile}
                                onClick={toggleMobileSideMenuOpen}
                                isSideMenuPinned={isSideMenuPinned}
                                setIsSideMenuPinned={setIsSideMenuPinned}
                              />
                            )}
                            <div
                              id="main-container"
                              className={classnames(
                                'bg-[color:--shp-color-bg-main] flex-1 overflow-y-auto overflow-x-hidden ml-0',
                                {
                                  'md:ml-[60px]': !plainLayoutRoute,
                                  'md:!ml-[80px]':
                                    !plainLayoutRoute && isSideMenuPinned,
                                },
                              )}
                            >
                              {/* PROD-2252 An anchor for the "Scroll To Top" logics since the body element doesn't really scroll */}
                              <div className="site-anchor" />
                              {isMobile ? (
                                <>
                                  <div
                                    className={classnames(
                                      'header-navbar-shadow',
                                      {
                                        hidden:
                                          isNavbarHidden || plainLayoutRoute,
                                      },
                                    )}
                                  />
                                  <Navbar
                                    name={user ? `${user.full_name}` : ''}
                                    img={user?.imageData}
                                    email={user ? `${user.email}` : ''}
                                    hide={isNavbarHidden || plainLayoutRoute}
                                    onClick={toggleMobileSideMenuOpen}
                                  />
                                </>
                              ) : (
                                // The banner wrapper must have a z index less than 1050 to not appear over the modals
                                <div
                                  data-test="banner-wrapper"
                                  className="sticky top-0 z-[1000]"
                                >
                                  {!noSetupBanner && <SetupBanner />}
                                </div>
                              )}
                              {/* The component that hosts a new header with the Reactive Search Bar™️ that will swap with the old one in the future */}
                              {!isMobile &&
                                !isNavbarHidden &&
                                !plainLayoutRoute &&
                                !noPageHeaderRoute && <PageHeader />}

                              <TopProgressBar />
                              <Changelog />
                              <Paywall />
                              <HelpSection />
                              <ManageOrderModal />
                              <TrackingDialog />
                              <AppRoutes />
                            </div>
                          </div>

                          {!noScrollTopRoute && <ScrollToTop />}
                          <Toastr.ToastContainer />
                        </div>
                      </TrackingDialogContext.Provider>
                    </ManageOrderModalExtContext.Provider>
                  </QzTrayManagerContext.Provider>
                </HelpSectionContext.Provider>
              </PaywallContext.Provider>
              {/* STATUSPAGE ALERTS CONTAINER */}
              <StatusPageAlertsContainer />
            </TopProgressBarContext.Provider>
          </TranslationsProvider>
        </MFAContext.Provider>
      </ProfileDetailsContext.Provider>
    </PageHeaderContextProvider>
  );
});

export const Logout = (): JSX.Element => {
  logout();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

Authenticated.displayName = 'Authenticated_memoized';

export default Authenticated;
