// ########################## [TRANSLATIONS]
import type { TFunction } from 'i18next';

// ########################## [TYPES]
import type { CarrierErrorMessageType } from '@web/features/ship/types';
import { useCallback } from 'react';
import { translations } from '@shippypro/translations';

export const useGetErrorMessageTypeNameAndDescription = () => {
  const getErrorMessageTypeNameAndDescription = useCallback(
    (
      t: TFunction<'translation', undefined>,
      messageTypeCode: string,
    ): CarrierErrorMessageType | null => {
      const transName =
        translations.ship.carrierErrorSuggestions.types[messageTypeCode]?.name;
      const transDescription =
        translations.ship.carrierErrorSuggestions.types[messageTypeCode]
          ?.description;

      return transName
        ? {
            parent: null,
            id: 0,
            code: messageTypeCode,
            name: transName ? t(transName) : '',
            description: transDescription ? t(transDescription) : '',
          }
        : null;
    },
    [],
  );

  return getErrorMessageTypeNameAndDescription;
};
