// ########################## [IMPORTANT LIBRARIES]
import { FC, memo, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

// ########################## [TYPES]
import { IPageWithTabs } from '@ds-web/components/templates/with-tabs/types';

// ########################## [PAGE COMPONENTS]
import SideTabsListing from './tabs-navigation/SideTabsListing';
import BodyContent from '@ds-web/components/templates/with-tabs/components/body/BodyContent';
import PreBodyContent from '@ds-web/components/templates/with-tabs/components/body/PreBodyContent';

// ########################## [HOOKS]
import { useGetDeviceSize } from '@ds-web/utils/hooks';

interface ITabsNavigatorProps extends Omit<IPageWithTabs, 'headingTitle'> {
  tabContentRef?: React.RefObject<HTMLElement>;
}

/**
 * [LAYOUT CARD WITH SIDE TABS - CARD] This component is used to render the tabs navigator of the layout card.
 * It holds the actual tabs listings and their respective content.
 * N.B. The content shown is based on the currently active tab and NO OTHER page is pre-rendered and hidden in the parsed HTML code.
 *
 * @interface ITabsNavigatorProps
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const SideTabsNavigator: FC<ITabsNavigatorProps> = memo(
  ({ tabs, activeTabIndex, toggle, loadInvisibleTabs, tabContentRef }) => {
    const { isTablet, isMobile } = useGetDeviceSize();

    const activeTabRef = useRef<HTMLDivElement>(null);

    // Selecting the currently displayed tab based on the active "Tab Index"
    const activeTab = tabs[activeTabIndex] ?? tabs[0];

    // Calculating dynamically the body height
    const [dynamicHeight, setDynamicHeight] = useState('100%');
    const headerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      const updateHeight = () => {
        if (headerRef.current) {
          const headerHeight = headerRef.current.offsetHeight;
          setDynamicHeight(`calc(100% - ${headerHeight}px)`);
        }
      };
      updateHeight();
      window.addEventListener('resize', updateHeight);
      return () => {
        window.removeEventListener('resize', updateHeight);
      };
    }, []);

    return (
      <div
        className={classnames('flex flex-nowrap !w-full h-full p-0', {
          'container-override': isTablet || isMobile,
        })}
      >
        <section className="flex flex-wrap flex-col w-[20%] bg-[#F5F6F6] border-r-2 border-[#D6D6D6] p-2">
          <SideTabsListing
            activeTabRef={activeTabRef}
            tabs={tabs}
            activeTabIndex={activeTabIndex}
            toggle={toggle}
          />
        </section>
        <section
          data-test="side-tabs-content"
          className="w-[80%] h-full shrink-0 bg-[#F5F6F6] shadow-sm"
          ref={tabContentRef}
        >
          <div ref={headerRef} className="py-2 px-4">
            <span
              data-test="side-tabs-title"
              className="text-[18px] font-bold text-[#364959]"
            >
              {activeTab.title}
            </span>
            <PreBodyContent currentTab={activeTab} />
          </div>
          <BodyContent
            tabs={tabs}
            activeTabIndex={activeTabIndex}
            loadInvisibleTabs={loadInvisibleTabs}
            sectionStyle={{ height: dynamicHeight }}
          />
        </section>
      </div>
    );
  },
);

SideTabsNavigator.displayName = 'SideTabsNavigator_memoized';
