import { useRef } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import omit from 'lodash/omit';
import styled from 'styled-components';
import classnames from 'classnames';

import { FormGroupProps } from 'reactstrap';

import { DatetimePicker } from '@shippypro/design-system-web';
import { IInputProps } from '@shippypro/design-system-web/types';

import { DateTimePickerProps } from 'react-flatpickr';
import { getDatesFromRange } from '@web/features/shared/filters/utils/dateRange';

export interface ControlledInlineDatetimeProps<FormType extends FieldValues>
  extends Pick<
    FormGroupProps,
    | 'row'
    | 'check'
    | 'inline'
    | 'floating'
    | 'disabled'
    | 'tag'
    | 'cssModule'
    | 'className'
  > {
  name: Path<FormType>;
  type?: IInputProps['type'];
  control: Control<FormType>;
  placeholder?: string;
  inputProps?: Omit<DateTimePickerProps, 'value' | 'onChange'>;
  disabled?: boolean;
  required?: boolean;
  dataTest?: string;
  wrapperClassName?: string;
  onChangeCustomAction?: (val?: Date[] | undefined) => void;
  customMaxDateForRange?: Date;
}

export function ControlledInlineDatetime<FormType extends FieldValues>({
  name,
  type,
  placeholder,
  control,
  inputProps,
  // here we can support one or more ways to show errors: FormFeedback, tooltip...
  className,
  wrapperClassName,
  disabled,
  required,
  dataTest,
  onChangeCustomAction,
  customMaxDateForRange,
}: ControlledInlineDatetimeProps<FormType>) {
  const isRequired = required ?? inputProps?.required;
  const inputType = type ?? inputProps?.type;
  const tooltipTargetRef = useRef<HTMLParagraphElement | null>(null);

  return (
    <div ref={tooltipTargetRef} className={wrapperClassName}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <StyledDateTimePicker
            className={classnames('!py-2 !px-4', className)}
            {...omit(inputProps)}
            options={{
              ...inputProps?.options,
              inline: true,
            }}
            onBlur={onBlur}
            onChange={val => {
              onChange(val);
              onChangeCustomAction && onChangeCustomAction(val);
            }}
            onWheel={
              /* istanbul ignore next */
              e => {
                if (e.target) {
                  try {
                    (e.target as HTMLElement).blur();
                  } catch (e) {
                    //
                  }
                }
              }
            }
            placeholder={placeholder ?? inputProps?.placeholder}
            value={
              typeof value === 'string'
                ? getDatesFromRange(value, customMaxDateForRange)
                : value ?? ''
            }
            disabled={disabled ?? inputProps?.disabled}
            type={inputType}
            required={isRequired}
            data-test={dataTest ?? name}
          />
        )}
        name={name}
      />
    </div>
  );
}

const StyledDateTimePicker = styled(DatetimePicker)`
  display: none;

  + .flatpickr-calendar {
    left: 0;
    margin: auto;
    box-shadow: none;

    .flatpickr-innerContainer {
      border: none;

      .flatpickr-days {
        border: none;
      }
    }
  }
`;
