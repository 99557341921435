// ########################## [IMPORTANT LIBRARIES]
import { memo, useContext } from 'react';
import classnames from 'classnames';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { BaseButton } from './types';
import { PrintLabelMode } from '@web/features/ship/functions/tracking.types';

// ########################## [DESIGN SYSTEM]
import { DropdownItem, TooltipWrapperByID } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [PAGE COMPONENTS]
import {
  GLSFormatCode,
  GLSItalyPrintFormatPickerContext,
  useGetCurrentGLSItalyConfig,
  useGetGLSItalyFormatValueFromCode,
} from '@web/features/ship/components/dialogs/glsitaly-printformat-picker';

// ########################## [HOOKS]
import { usePrintOrders } from '@web/features/ship/hooks/api/printing/usePrintOrder';
import { useTrackButtonClick } from '@web/features/ship/hooks/useTrackButtonClick';

export type IBtnPrintGLS = BaseButton & {
  ordersIds: number[];
  show?: boolean;
};

export const GLSITALY_NAME = 'GLSItaly';

/**
 * This component renders the GLS print button conditionally on having at least one GLS connection
 * It manages a opening dialog on click on the setting icon to get/set the print format
 * from/to the local storage
 */
export const BtnPrintGLS: React.FC<IBtnPrintGLS> = memo(
  ({ isDisabled, ordersIds, show, className }) => {
    const { t } = useTranslation(),
      transPrint = translations.ship.buttons.print,
      transGLSItaly = transPrint.glsItaly;

    const { printOrders } = usePrintOrders();
    const { trackPrintLabelClick } = useTrackButtonClick();

    const onClick = () => {
      trackPrintLabelClick(PrintLabelMode.GLS_ITALY, ordersIds.length);
      defaultFormat
        ? printOrders({
            ids: ordersIds,
            glsformat: defaultFormatValue,
          })
        : openDialog({ ids: ordersIds, glsformat: -1 });
    };

    const currentFormatSettings = useGetCurrentGLSItalyConfig(ordersIds.length);
    const defaultFormat = localStorage.getItem(currentFormatSettings) as
      | keyof typeof GLSFormatCode
      | null;
    const defaultFormatValue = useGetGLSItalyFormatValueFromCode(defaultFormat);

    const { openDialog } = useContext(GLSItalyPrintFormatPickerContext)!;

    return show ? (
      <TooltipWrapperByID
        target="print-btn-glsitaly-wrapper"
        text={
          isDisabled
            ? t(transGLSItaly.onlyGLSItaly)
            : defaultFormat
              ? `${t(transGLSItaly.modal.formats[defaultFormat]?.title)} - ${t(
                  transGLSItaly.modal.formats[defaultFormat]?.subtitle,
                )}`
              : ''
        }
        active={isDisabled || !!defaultFormat}
        tooltipProps={{
          className: 'print-btn-glsitaly-disabled-tooltip',
        }}
      >
        <div>
          <DropdownItem
            className={classnames('pr-0 justify-between w-auto', className)}
            id="print-btn-glsitaly"
            tag="a"
            disabled={isDisabled}
            onClick={onClick}
          >
            <span className="text-truncate">
              {t(transGLSItaly.dropdownTitle)}
            </span>
            <div
              className={classnames('ml-auto !mr-3', {
                'text-dark cursor-pointer': !isDisabled,
              })}
              onClick={e => {
                e.stopPropagation();
                openDialog({
                  ids: ordersIds,
                  glsformat: defaultFormatValue,
                });
              }}
              data-test="print-gls-reopen-dialog"
            >
              <IconHelper icon="IconSettings" size={18} />
            </div>
          </DropdownItem>
        </div>
      </TooltipWrapperByID>
    ) : null;
  },
);
