import { FC, memo, useMemo } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import {
  QuickFilter,
  QuickFilterProps,
  TooltipWrapperByID,
} from '@ds-web/components';

import { IFiltersSummaryBtnNewProps } from './types';

import { useGetDeviceSize } from '@ds-web/utils/hooks';

/**
 * The New button shown in the top-right corner of the page layout, below the tabs.
 * Normally it opens the FiltersForm drawer when clicked.
 * This component is TEMPORARY and will be removed once the filters drawer will be removed.
 * Right now, this is required since previous component is still used in other feature that does not require this new implementation.
 *
 * @interface IFiltersSummaryBtnNewProps
 *
 * @author Marcello Aguzzi <marcello.aguzzi@shippypro.com>
 */
export const FiltersSummaryBtnNew: FC<IFiltersSummaryBtnNewProps> = memo(
  ({ disabled, toggleDrawer }) => {
    const { t } = useTranslation(),
      trans = translations.common;

    // Changing the amount and size of the pills if the display is smaller than 768px
    const { isTabletHoriz } = useGetDeviceSize();

    const filtersSummaryBtnQuickFilterProps: QuickFilterProps = useMemo(() => {
      return {
        filterKey: 'filters-summary-button',
        icon: 'IconFilter',
        name: isTabletHoriz ? '' : t(trans.allFilters),
      };
    }, [isTabletHoriz, t, trans.allFilters]);

    return (
      <div
        onClick={() => !disabled && toggleDrawer?.()}
        className={classnames({
          'text-muted': disabled,
          '!cursor-default': toggleDrawer === undefined,
        })}
      >
        <QuickFilter {...filtersSummaryBtnQuickFilterProps} />
      </div>
    );
  },
);

FiltersSummaryBtnNew.displayName = 'FiltersSummaryBtnNew_memoized';
