import { useCallback } from 'react';

import { Address } from '@web/types/common/address';

import useLDFlag from '../useLDFlags';
import { useGetCountries } from '@web/features/_global/hooks/api/useGetCountries';

const CHECKED_COUNTRY = 'GB';
const CHECKED_ZIP = 'BT';

export type WindsorFrameworkShipmentDutiableCallback = (
  addressTo?: Address,
  addressFrom?: Address,
) => boolean;

/**
 * Custom hook to determine if a shipment is dutiable under the Windsor Framework.
 *
 * @returns {WindsorFrameworkShipmentDutiableCallback} A callback function that checks if a shipment is dutiable.
 *
 * The callback function takes two parameters:
 * - `addressTo`: The destination address object.
 * - `addressFrom`: The origin address object.
 *
 * The callback function returns a boolean indicating whether the shipment is dutiable.
 *
 * The function performs the following checks:
 * - Ensures the Windsor Framework feature flag is enabled.
 * - Validates the presence of both destination and origin addresses.
 * - Extracts and validates the alpha-2 country codes from the addresses.
 * - Checks if the shipment meets specific criteria based on country codes and postal codes.
 *
 * @author Marcello Aguzzi <marcello.aguzzi@shippypro.com>
 *
 */
export function useIsWindsorFrameworkDutiable(): WindsorFrameworkShipmentDutiableCallback {
  const { windsorFramework } = useLDFlag();
  const { countries } = useGetCountries();

  return useCallback(
    (addressTo, addressFrom) => {
      if (!windsorFramework || !addressTo || !addressFrom) {
        return false;
      }

      let countryFromAlpha2Code: string | undefined = undefined;
      let countryToAlpha2Code: string | undefined = undefined;
      const countryFrom = addressFrom?.country;
      const countryTo = addressTo?.country;
      const zipTo = addressTo!.zip;
      const zipFrom = addressFrom!.zip;

      if (countryFrom && typeof countryFrom === 'string') {
        countryFromAlpha2Code = (countries || []).find(
          co => co.alpha_2_code === countryFrom,
        )?.alpha_2_code;
      } else if (countryFrom && typeof countryFrom === 'object') {
        countryFromAlpha2Code = countryFrom?.alpha_2_code;
      }

      if (countryTo && typeof countryTo === 'string') {
        countryToAlpha2Code = (countries || []).find(
          co => co.alpha_2_code === countryTo,
        )?.alpha_2_code;
      } else if (countryTo && typeof countryTo === 'object') {
        countryToAlpha2Code = countryTo.alpha_2_code;
      }

      if (!countryFromAlpha2Code || !countryToAlpha2Code) {
        return false;
      }

      if (
        // sender country === GB and sender zip doesn't start with BT
        CHECKED_COUNTRY === countryFromAlpha2Code! &&
        !zipFrom.toUpperCase().startsWith(CHECKED_ZIP) &&
        // recipient country === GB and recipient zip starts with BT
        CHECKED_COUNTRY === countryToAlpha2Code! &&
        zipTo.toUpperCase().startsWith(CHECKED_ZIP)
      ) {
        return true;
      }

      return false;
    },
    [windsorFramework, countries],
  );
}
