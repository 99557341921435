// ########################## [IMPORTANT LIBRARIES]
import styled from 'styled-components';
import classnames from 'classnames';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { Modal, ModalHeader, ModalBody } from '@shippypro/design-system-web';
import { useGetDeviceSize } from '@shippypro/design-system-web/hooks';

// ########################## [TYPES]
import { Order } from '@web/types/order';

// ########################## [PAGE COMPONENTS]
import DetailsCard from '../components/DetailsCard';
import TrackingCard from '../components/TrackingCard';

// ########################## [HOOKS]
import { useIsShipmentDutiable } from '@web/hooks/orders/useIsShipmentDutiable';

export type IOrderTrackingDialogProps = {
  show: boolean;
  order: Order;
  onCancel: () => void;
};

/**
 * This modal receives the order, show directive and on cancel action from outside
 * and renders receipient, items and tracking details.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface ITrackingCodeBadge
 * @returns {JSX.Element}
 */
const OrderTrackingDialog: React.FC<IOrderTrackingDialogProps> = ({
  show,
  order,
  onCancel,
}) => {
  const { t } = useTranslation(),
    transDialog = translations.order.info.trackingDialog;

  const { isMobile } = useGetDeviceSize();

  const isDutiableCallback = useIsShipmentDutiable();

  return (
    <Modal
      toggle={onCancel}
      isOpen={show}
      className="modal-dialog-centered modal-wrapper modal-xl"
      data-test="order-tracking-dialog"
    >
      <ModalHeader className="bg-white" toggle={onCancel}>
        <span className="text-xl font-bold">
          {t(transDialog.title, {
            externalId: order.external_id,
          })}
        </span>
      </ModalHeader>
      <StyledModalBody
        className={classnames(
          'sm:mx-1 overflow-y-scroll mb-1 lg:overflow-y-hidden lg:mb-0',
          {
            'max-h-[70vh]': !isMobile,
          },
        )}
      >
        <div className="flex flex-col items-stretch lg:gap-2 lg:flex-row max-h-[inherit]">
          <TrackingCard order={order} className="lg:order-1 !lg:w-1/2" />
          <DetailsCard
            order={order}
            isDutiable={isDutiableCallback(order.address)}
            className="lg:order-0 !lg:w-1/2"
          />
        </div>
      </StyledModalBody>
    </Modal>
  );
};

const StyledModalBody = styled(ModalBody)`
  #wrapper {
    align-items: stretch;
    height: auto;
    .card {
      height: -webkit-fill-available;
    }
  }
`;

export default OrderTrackingDialog;
