import { FC, memo } from 'react';

interface IAlertCloseProps {
  dismissAlert: () => void;
}

/**
 * **[COMPONENT]** The alert-close component.
 * The Closing  Icon of the StatusPage Alert.
 *
 * @interface IAlertCloseProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const AlertClose: FC<IAlertCloseProps> = memo(({ dismissAlert }) => {
  return (
    <div className="status-alert-close">
      <button className="status-alert-svg-button" onClick={dismissAlert}>
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 13 13"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id="status-embed-svg-close-icon"
        >
          <defs>
            <path
              d="M10,8.8214887 L14.4107443,4.41074435 C14.7361813,4.08530744 15.2638187,4.08530744 15.5892557,4.41074435 C15.9146926,4.73618126 15.9146926,5.26381874 15.5892557,5.58925565 L11.1785113,10 L15.5892557,14.4107443 C15.9146926,14.7361813 15.9146926,15.2638187 15.5892557,15.5892557 C15.2638187,15.9146926 14.7361813,15.9146926 14.4107443,15.5892557 L10,11.1785113 L5.58925565,15.5892557 C5.26381874,15.9146926 4.73618126,15.9146926 4.41074435,15.5892557 C4.08530744,15.2638187 4.08530744,14.7361813 4.41074435,14.4107443 L8.8214887,10 L4.41074435,5.58925565 C4.08530744,5.26381874 4.08530744,4.73618126 4.41074435,4.41074435 C4.73618126,4.08530744 5.26381874,4.08530744 5.58925565,4.41074435 L10,8.8214887 Z"
              id="path-x"
            ></path>
          </defs>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-1027.000000, -941.000000)">
              <g
                id="global/cross"
                transform="translate(1023.500000, 937.500000)"
              >
                <mask id="mask-x" fill="white">
                  <use xlinkHref="#path-x"></use>
                </mask>
                <use
                  id="Combined-Shape"
                  fill="#42526E"
                  fillRule="nonzero"
                  xlinkHref="#path-x"
                ></use>
                <g
                  id="Dark-neutral-/-DN070"
                  mask="url(#mask-x)"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                >
                  <polygon id="DN70" points="0 20 20 20 20 0 0 0"></polygon>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
  );
});

export default AlertClose;
