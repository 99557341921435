/* istanbul ignore file */

import { Color } from '@shippypro/design-system-web/enums';
import { OrderCategory } from '@web/types/order';

type OrderStatus = {
  code: string;
  color: Color;
  shippyCode: string;
  shippyColor: Color;

  category: string;
};

/**
 * [HOOK] function that gets an "Order Status" code (-1, 0, 1, 2, 3...) and returns
 * an "Order Status" object with its code and associated color.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @param orderStatus {string} To Status code gotten from the API
 *
 * @returns {OrderStatus} The parsed "Order Status" object
 */
const useParseOrderStatus = (orderStatus?: string | null): OrderStatus => {
  return parseOrderStatus(orderStatus);
};

export const parseOrderStatus = (orderStatus?: string | null): OrderStatus => {
  switch (orderStatus) {
    case '-1':
      return {
        code: 'unshipped',
        color: Color.danger,
        shippyCode: 'toShip',
        shippyColor: Color.warning,
        category: OrderCategory.toShip,
      };
    case '0':
    case '98': // api
    case '99': // rabbitMQ
      return {
        code: 'pending',
        color: Color.warning,
        shippyCode: 'shipped',
        shippyColor: Color.success,
        category: OrderCategory.shipped,
      };
    case '1':
      return {
        code: 'completed',
        color: Color.success,
        shippyCode: 'shipped',
        shippyColor: Color.success,
        category: OrderCategory.shipped,
      };
    case '2':
      return {
        code: 'error',
        color: Color.danger,
        shippyCode: 'error',
        shippyColor: Color.danger,
        category: OrderCategory.error,
      };
    case '3':
      return {
        code: 'toPick',
        color: Color.warning,
        shippyCode: 'toPick',
        shippyColor: Color.warning,
        category: 'topick',
      };
    case '4':
      return {
        code: 'onHold',
        color: Color.warning,
        shippyCode: 'onHold',
        shippyColor: Color.warning,
        category: 'onhold',
      };
    default:
      return {
        code: 'unknown',
        color: Color.secondary,
        shippyCode: 'unknown',
        shippyColor: Color.secondary,
        category: 'unknown',
      };
  }
};

export default useParseOrderStatus;
