import { FetchArgs } from '@reduxjs/toolkit/query/react';

import { BASE_MICROSERVICE_ALLOWED_METHODS, getMicroserviceQuery } from '..';
import { getMicroserviceUrl } from '@shippypro/api-client/utils';

import { MicroserviceNames } from '@shippypro/api-client/types';
import { CarriersErrorsMessagesPayload } from '@web/features/ship/types';

type VALIDATIONENGINE_ALLOWED_METHODS = Exclude<
  BASE_MICROSERVICE_ALLOWED_METHODS,
  'PATCH' | 'DELETE' | 'PUT'
>;

/**
 * This method scaffolds the query object for the "Validation Engine" microservice API request.
 * It takes a url that goes AFTER the base one, an allowed REST method code and an optional body.
 * N.B. Said body is only used for non-GET requests.
 *
 * @param {string} url The url of the microservice endpoint.
 * @param {VALIDATIONENGINE_ALLOWED_METHODS} method The REST method code.
 * @returns {FetchArgs} The query object.
 */
function getValidationEngineMicroserviceQuery<ArgT>(
  url: string,
  method: VALIDATIONENGINE_ALLOWED_METHODS,
  args?: ArgT,
): FetchArgs {
  return getMicroserviceQuery<ArgT>(
    `${getMicroserviceUrl(MicroserviceNames.VALIDATION_ENGINE)}/${url}`,
    method,
    args,
  );
}

/**
 * This method scaffolds the query object for the "Validation Engine" microservice API request.
 * It specifically targets the "rules" endpoint part of the engine.
 *
 * @param {VALIDATIONENGINE_ALLOWED_METHODS} method The REST method code.
 * @returns {FetchArgs} The query object.
 */
export function getValidationEngineRulesMicroserviceQuery<
  ArgT extends { carrier: string; version: string },
>(method: VALIDATIONENGINE_ALLOWED_METHODS, args: ArgT): FetchArgs {
  return getValidationEngineMicroserviceQuery<ArgT>(
    `rules/${args.carrier}/${args.version}`,
    method,
    args,
  );
}

/**
 * This method scaffolds the query object for the "Validation Engine" microservice API request.
 * It specifically targets the "carriers" endpoint part of the engine.
 * It gets the error messages of that carrier's list in the provided locale language
 *
 * @param {VALIDATIONENGINE_ALLOWED_METHODS} method The REST method code.
 * @returns {FetchArgs} The query object.
 */
export function getValidationEngineCarriersErrorsMessagesMicroserviceQuery<
  ArgT extends CarriersErrorsMessagesPayload,
>(method: VALIDATIONENGINE_ALLOWED_METHODS, args: ArgT): FetchArgs {
  return getValidationEngineMicroserviceQuery<ArgT>(
    'carriers/errorMessages',
    method,
    args,
  );
}

/**
 * This method scaffolds the query object for the "Validation Engine" microservice API request.
 * It specifically targets the "carriers" endpoint part of the engine.
 * It gets the error types of every error the carrier could return.
 *
 * @param {VALIDATIONENGINE_ALLOWED_METHODS} method The REST method code.
 * @returns {FetchArgs} The query object.
 */
export function getValidationEngineCarriersErrorsTypesMicroserviceQuery<ArgT>(
  method: VALIDATIONENGINE_ALLOWED_METHODS,
  args: ArgT,
): FetchArgs {
  return getValidationEngineMicroserviceQuery<ArgT>(
    'carriers/errorMessages/types',
    method,
    args,
  );
}
