// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';
import { NavLink, Spinner } from 'reactstrap';
import classnames from 'classnames';

// ########################## [TYPES]
import { ITabsListingProps } from '@ds-web/components/types';

// ########################## [COMPONENTS]
import { IconHelper } from '@ds-web-iconhelper';

interface ISectionWithTopTabItemProps {
  tab: ITabsListingProps['tabs'][number];
  activeTabIndex: number;
  activeTabRef: React.RefObject<HTMLDivElement> | null;
  toggle: (index: number) => void;
  pending: boolean;
  nextTab: number;
  trueIndex: number;
}

/**
 * [LAYOUT SECTION WITH TOP TABS - TABS ITEM] This component holds the actual tab item, decoupled from his content.
 * Every tab button shows a simple button.
 *
 * @interface ITabsListingProps
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const SideTabsItem: FC<ISectionWithTopTabItemProps> = memo(
  ({
    tab,
    activeTabIndex,
    activeTabRef,
    toggle,
    pending,
    nextTab,
    trueIndex,
  }) => {
    const { url, icon, title, disabled, buttonOverride } = tab;

    const isTabActive = activeTabIndex === trueIndex;

    const tabId = `tab-${url}`;

    return (
      <div
        key={trueIndex}
        data-test={tabId}
        id={tabId}
        className="nav-item flex"
        ref={isTabActive ? activeTabRef : null}
        onClick={() => {
          if (disabled) return;
          if (buttonOverride) return buttonOverride();

          toggle(trueIndex);
        }}
      >
        <NavLink
          active={isTabActive}
          className={classnames('gap-[10px]', {
            '!cursor-not-allowed': disabled,
          })}
        >
          {trueIndex === nextTab && pending ? (
            <Spinner size="sm" className="!mr-3" />
          ) : (
            icon && <IconHelper icon={icon} stroke={isTabActive ? 2 : 1.5} />
          )}
          {title}
        </NavLink>
      </div>
    );
  },
);

SideTabsItem.displayName = 'SideTabsItem';

export default SideTabsItem;
