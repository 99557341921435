export type SideMenuState = {
  isOpen: boolean;
  isPinned: boolean;
  isHidden: boolean;
};

export type SideMenuActions = {
  toggleOpen: () => void;
  togglePin: () => void;
  toggleIsHidden: (hide: boolean) => void;
};

export type SideMenuStore = SideMenuState & SideMenuActions;
