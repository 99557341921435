import React, { FC } from 'react';
import classnames from 'classnames';

import {
  Area,
  AreaChart as DefaultAreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { IMultiAreaChartDataProps } from './types';

import DefaultTooltip from '@ds-web/components/molecules/ui/charts/multi-area-chart-tooltip/MultiAreaChartTooltip';
import { toLocalizedDecimal } from '@ds-web/utils/functions';

import { useGetMultiAreaClickedRow } from '@ds-web/components/molecules/ui/charts/hooks/useGetMultiAreaClickedRow';

// Defaults
const gradientDefaults = [
  {
    stopColor: '#7349DD',
    id: 'colorShp',
  },
];
const YAxisPropsDefaults = {
  width: 35,
  chartPadding: { top: 25 },
  allowDecimals: false,
};
const areasDefaults = [
  {
    YAxisProps: ['yValue'],
    type: 'linear',
    stroke: '#7349DD',
    fillOpacity: 1,
    fill: 'url(#colorShp)',
  },
];

/**
 * A component that render and multi area chart.
 *
 * @interface IMultiAreaChartDataProps
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const MultiAreaChart: FC<IMultiAreaChartDataProps<string>> = ({
  data,
  tickCount = 2,
  gradients = gradientDefaults,
  XDataKey = 'xName',
  YAxisProps = YAxisPropsDefaults,
  areas = areasDefaults,
  CustomTooltip,
  handleLineClick,
}) => {
  const { setSinglePointClicked, setGraphDataClicked } =
    useGetMultiAreaClickedRow(handleLineClick);
  return (
    <ResponsiveContainer width="98%" height="99%">
      <DefaultAreaChart
        data={data}
        margin={{ right: 0, left: 0 }}
        onClick={e => setGraphDataClicked(e)}
      >
        <defs>
          {gradients.map(gradient => (
            <linearGradient id={gradient.id} x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={gradient.stopColor}
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor={gradient.stopColor}
                stopOpacity={0}
              />
            </linearGradient>
          ))}
        </defs>

        <XAxis dataKey={XDataKey} tickCount={tickCount} />

        <YAxis
          width={YAxisProps.width}
          tickFormatter={value => toLocalizedDecimal(value, 0, 0)}
          padding={YAxisProps.chartPadding}
          allowDecimals={YAxisProps.allowDecimals}
        />

        <CartesianGrid />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={data =>
            data.active &&
            data.payload &&
            (CustomTooltip ? (
              <CustomTooltip data={data} isAreaClickable={!!handleLineClick} />
            ) : (
              <DefaultTooltip data={data} isAreaClickable={!!handleLineClick} />
            ))
          }
        />
        {areas.map((areaProp, index) => {
          return (
            <Area
              type={areaProp.type}
              dataKey={areaProp.dataKey}
              stroke={areaProp.stroke}
              fillOpacity={areaProp.fillOpacity}
              fill={areaProp.fill}
              activeDot={{
                r: 5,
                className: classnames({
                  'cursor-pointer area-active-dot': handleLineClick,
                }),
                onClick: (e, payload) => {
                  setSinglePointClicked({
                    dataKey: payload['dataKey'],
                    index: index,
                  });
                },
              }}
            />
          );
        })}
      </DefaultAreaChart>
    </ResponsiveContainer>
  );
};
