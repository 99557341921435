import { MouseEventHandler } from 'react';

import { IconHelper } from '@ds-web-iconhelper';
import { UncontrolledTooltip as Tooltip } from '@ds-web/components';

import * as S from './QuickFilter.styles';
import { formatValue } from './QuickFilter.utils';
import { QuickFilterTagProps } from './QuickFilter.props';

const QuickFilterTag = ({
  filterKey,
  name,
  value,
  setValue,
  onClear,
  onSubmit,
  highlighted,
  tooltipText,
  standalone = false,
}: QuickFilterTagProps) => {
  const tagId = `quick-filter-tag-${filterKey}`;
  const standaloneTagId = `${tagId}--standalone`;
  const hasValue = Array.isArray(value) ? value.length > 0 : !!value;
  const { formattedValue, isValueTruncated } = standalone
    ? { formattedValue: name, isValueTruncated: false }
    : formatValue(value || name, filterKey);

  const onStandaloneTagClick: MouseEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    setValue?.(filterKey, !value);
    onSubmit?.();
  };

  const onTagActionClick: MouseEventHandler<HTMLDivElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    onClear?.(filterKey);
  };

  return (
    <S.QuickFilterTagContainer
      standalone={standalone}
      value={value}
      onClick={standalone && !hasValue ? onStandaloneTagClick : undefined}
      data-test={standaloneTagId}
      data-testid={standaloneTagId}
      id={standaloneTagId}
    >
      <S.QuickFilterTag
        id={tagId}
        data-test={tagId}
        data-testid={tagId}
        withDot={highlighted && !hasValue}
      >
        {formattedValue}
      </S.QuickFilterTag>
      {!!onClear && hasValue && (
        <S.QuickFilterTagAction
          onClick={onTagActionClick}
          id={`${tagId}--remove-filter`}
          data-test={`${tagId}--remove-filter`}
          data-testid={`${tagId}--remove-filter`}
        >
          <IconHelper
            id={`${tagId}--remove-filter-icon`}
            icon="IconX"
            size={standalone ? 20 : 16}
            stroke={2}
            color="#7349dd"
          />
        </S.QuickFilterTagAction>
      )}
      {!!tooltipText && !hasValue && (
        <Tooltip
          placement="top"
          target={standaloneTagId}
          data-testid={`${standaloneTagId}--tooltip`}
        >
          {tooltipText}
        </Tooltip>
      )}
      {hasValue && isValueTruncated && (
        <Tooltip
          placement="top"
          target={tagId}
          data-testid={`${tagId}--tooltip`}
        >
          {Array.isArray(value) ? value.join(', ') : String(value)}
        </Tooltip>
      )}
    </S.QuickFilterTagContainer>
  );
};

export default QuickFilterTag;
