import { refiner } from './refiner';
import { mixpanel } from './mixpanel';
import { userflow } from './userflow';

import { isProductionEnv, isTestingEnv } from '@shippypro/utils';

refiner('setProject', process.env.NX_REFINER_PROJECT_KEY);

mixpanel.init(process.env.NX_MIXPANEL_PROJECT_KEY || '', {
  debug: !isProductionEnv(),
  track_pageview: true,
  persistence: 'localStorage',
});

/* istanbul ignore next */
if (!isTestingEnv()) userflow.init(process.env.NX_USERFLOW_PROJECT_KEY || '');
