// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { AuthenticationResponseJSON } from '@simplewebauthn/browser';
import type {
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
} from '@simplewebauthn/browser';

// ########################## [TYPES]
import {
  EntityType,
  IMFAContext,
  MFAData,
  TotpMfaData,
  VerifyRequestScope,
  VerifyRequestType,
} from '@shippypro/foundation/settings/types';

// ########################## [HOOKS]
import { useGetUser } from '@web/features/_global/hooks/api/useGetUser';
import { useGetMFAMethod } from '@shippypro/foundation/settings/hooks/api/useGetMFAMethod';
import { useUpdateMFAMethod } from '@shippypro/foundation/settings/hooks/api/useUpdateMFAMethod';
import { useValidateMFA } from '@shippypro/foundation/settings/hooks/api/useValidateMFA';
import { useDeleteMFAMethod } from '@shippypro/foundation/settings/hooks/api/useDeleteMFAMethod';
import useManualQueryInvalidation from '@web/hooks/useManualQueryInvalidation';

/**
 * [HOOK] useInstanceMFAContext returns every data to be rendered about MFA settings,
 * together with the actions to update those MFA settings on the `Security` tab
 *
 * @return {IMFAContext} - The data and actions on the Security tab about MFA
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const useInstanceMFAContext = (): IMFAContext => {
  // Prepares the "manual query invalidation" callback
  const invalidate = useManualQueryInvalidation();

  const { user } = useGetUser();

  const { MFAMethods, isLoading: areMFAMethodsLoading } = useGetMFAMethod(
    user?.id,
  );

  const MFAMethod = useMemo(
    () => MFAMethods?.filter(method => method.verified)[0],
    [MFAMethods],
  );

  // ------ [ METHODS SETUP ] ------ //

  // BE calls to update profile details
  const {
    updateMFAMethod,
    data: updateMFAMethodData,
    isLoading: isMFADataLoading,
  } = useUpdateMFAMethod(user?.id!);

  const setMFAMethod = useCallback(
    async (mfaData: MFAData, deviceData?: string, entityType?: EntityType) => {
      const result = await updateMFAMethod(mfaData, deviceData, entityType);
      return result;
    },
    [updateMFAMethod],
  );

  const {
    validateMFA,
    data: verifyMFAMethodData,
    isLoading: isVerifyMFALoading,
  } = useValidateMFA(user?.id!);

  const verificationToken = useMemo(() => {
    return (
      verifyMFAMethodData?.token_id ??
      updateMFAMethodData?.token_id ??
      undefined
    );
  }, [updateMFAMethodData, verifyMFAMethodData]);

  const verifyMFA = useCallback(
    async (
      mfaId: number,
      mfaData: string | AuthenticationResponseJSON | MFAData,
      scope: VerifyRequestScope,
      entityType?: EntityType,
      requestType?: VerifyRequestType,
      invalidateMethods = false,
    ) => {
      const result = await validateMFA(
        mfaId,
        scope,
        mfaData,
        entityType,
        requestType,
      );
      if (invalidateMethods && result && result.success)
        invalidate(['MFAMethods']);

      return result;
    },
    [invalidate, validateMFA],
  );

  const resendMFAEmail = useCallback(
    async (mfaId: number, entityType?: EntityType) => {
      const result = await validateMFA(
        mfaId,
        VerifyRequestScope.EmailEdit,
        undefined,
        entityType,
        VerifyRequestType.RequestChallenge,
      );
      return result;
    },
    [validateMFA],
  );

  const { deleteMFAMethod } = useDeleteMFAMethod(user?.id!);

  const deleteMFAMethodCallback = useCallback(
    async (mfaId: number, entityType?: EntityType) => {
      const result = await deleteMFAMethod(
        mfaId,
        verificationToken!,
        entityType,
      );
      return result;
    },
    [deleteMFAMethod, verificationToken],
  );

  const [mfaData, setMfaData] = useState<
    | PublicKeyCredentialCreationOptionsJSON
    | TotpMfaData
    | PublicKeyCredentialRequestOptionsJSON
    | undefined
  >(undefined);
  useEffect(() => {
    if (updateMFAMethodData && updateMFAMethodData.mfa_data)
      setMfaData(updateMFAMethodData.mfa_data);
  }, [updateMFAMethodData]);
  useEffect(() => {
    if (verifyMFAMethodData && verifyMFAMethodData.mfa_data)
      setMfaData(verifyMFAMethodData.mfa_data);
  }, [verifyMFAMethodData]);

  const mfaId = useMemo(
    () => MFAMethod?.id ?? updateMFAMethodData?.id,
    [MFAMethod, updateMFAMethodData],
  );

  const mfaType = useMemo(() => MFAMethod?.mfa_type, [MFAMethod]);

  const mfaRecoveryCodes = useMemo(
    () => updateMFAMethodData?.recovery_codes,
    [updateMFAMethodData],
  );

  const hasSetMFAMethod = useMemo(() => MFAMethod !== undefined, [MFAMethod]);

  return useMemo<IMFAContext>(
    () => ({
      MFAMethod,
      MFAMethods,
      mfaId, // Of the first method - the only one we are considering atm
      mfaType, // Of the first method - the only one we are considering atm
      mfaRecoveryCodes,
      MFAData: mfaData,
      areMFAMethodsLoading,
      isMFADataLoading,
      isVerifyMFALoading,
      verificationToken,
      hasSetMFAMethod,
      methods: {
        setMFAMethod,
        verifyMFA,
        resendMFAEmail,
        deleteMFAMethodCallback,
      },
    }),
    [
      MFAMethod,
      MFAMethods,
      mfaId,
      mfaType,
      mfaRecoveryCodes,
      mfaData,
      areMFAMethodsLoading,
      isMFADataLoading,
      isVerifyMFALoading,
      verificationToken,
      hasSetMFAMethod,
      setMFAMethod,
      verifyMFA,
      resendMFAEmail,
      deleteMFAMethodCallback,
    ],
  );
};
