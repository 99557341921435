import { useCallback } from 'react';

import {
  CreateInvoiceOrderStatus,
  CreateInvoiceVariant,
  PrintLabelMode,
  PrintLabelTrigger,
} from '../functions/tracking.types';

import {
  trackCreateInvoice,
  trackCreateManifest,
  trackOrderSplit,
  trackPrintLabel,
} from '../functions/tracking';

export const useTrackButtonClick = () => {
  const trackPrintLabelClick = useCallback(
    async (
      mode: PrintLabelMode,
      ordersSelected: number,
      trigger: PrintLabelTrigger = PrintLabelTrigger.DROPDOWN,
    ) => {
      trackPrintLabel({ mode, ordersSelected, trigger });
    },
    [],
  );

  const trackCreateInvoiceClick = useCallback(
    async (
      ordersSelected: number,
      ordersStatus: CreateInvoiceOrderStatus,
      variant: CreateInvoiceVariant = 'standard',
    ) => {
      trackCreateInvoice({ ordersSelected, ordersStatus, variant });
    },
    [],
  );

  const trackCreateManifestClick = useCallback(
    async (ordersSelected: number) => {
      trackCreateManifest({ ordersSelected });
    },
    [],
  );

  const trackOrderSplitClick = useCallback(async () => {
    trackOrderSplit();
  }, []);

  return {
    trackPrintLabelClick,
    trackCreateInvoiceClick,
    trackCreateManifestClick,
    trackOrderSplitClick,
  };
};
