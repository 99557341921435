import axios from 'axios';

import { GetJWTToken, getOrCreateRefreshTokenPromise } from './token';

export const execQueryWithReauth = async <ReturnT>(
  baseQuery: ReturnType<typeof axios.get<ReturnT>>,
) => {
  let result = await baseQuery;

  if (result.status === 401) {
    await getOrCreateRefreshTokenPromise();

    result = await baseQuery;
  } else if (result.status === 403) {
    //Delete the old tokens
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('portal-token');
    //Redirect to the PKCE entry-point
    window.location.reload();

    return result;
  }

  return result;
};

export const getHeaders = () => {
  const token = GetJWTToken();

  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    authorization: `Bearer ${token}`,
  };
};
