import { useMemo } from 'react';
import { parseAPIError } from '@web/utils/@reduxjs/rtk-query';

import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

import { FetchedOnboarding, FetchedSetup } from '@web/features/_global/types';

import {
  useGetOnboardingProgressQuery,
  useGetSetupProgressQuery,
} from '@web/features/_global/api';

/**
 * Returns RTK Query hook with renamed data field.
 * This function calls the API Gateway to fetch the help articles
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetSetupData = (options?: CustomRTKQueryOptions) => {
  const {
    data: onboarding,
    isLoading: onboardingLoading,
    isFetching: onboardingFetching,
    refetch: onboardingRefetch,
    error: onboardingError,
  } = useGetOnboardingProgressQuery(undefined, options);
  const {
    data: setup,
    isLoading: setupLoading,
    isFetching: setupFetching,
    refetch: setupRefetch,
    error: setupError,
  } = useGetSetupProgressQuery(undefined, options);

  return useMemo(
    () => ({
      data: [onboarding, setup] as [FetchedOnboarding, FetchedSetup],
      hideOnboarding: !onboarding,
      isLoading: onboardingLoading || setupLoading,
      isFetching: onboardingFetching || setupFetching,
      refetch: () => {
        onboardingRefetch();
        setupRefetch();
      },
      error: onboardingError
        ? parseAPIError(onboardingError)
        : setupError
          ? parseAPIError(setupError)
          : '',
    }),
    [
      onboarding,
      onboardingError,
      onboardingLoading,
      onboardingFetching,
      onboardingRefetch,
      setup,
      setupError,
      setupLoading,
      setupFetching,
      setupRefetch,
    ],
  );
};
