// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { RowDensity } from '@web/features/ship/types';

// ########################## [PAGE COMPONENTS]
import { LinkWithBadge } from '@web/components/common-ui/tables/columns';
import { MarketplaceBadge } from '@web/components/common-ui/tables/columns/badges/MarketplaceBadge';
import { IsReturnBadge } from '@web/components/common-ui/tables/columns/badges/IsReturnBadge';
import { NotesBadge } from '@web/components/common-ui/tables/columns/badges/NotesBadge';
import { DocumentsBadge } from '@web/components/common-ui/tables/columns/badges/DocumentsBadge';
import OrderTypeBadge from '@web/components/common-features/order/OrderTypeBadge';
import OrderMergedBadge from '@web/components/common-features/order/OrderMergedBadge';
import OrderSplitBadge from '@web/components/common-features/order/OrderSplitBadge';
import { ItemsCountBadge } from '@web/components/common-ui/tables/columns/badges/ItemsCountBadge';

// ########################## [UTILS]
import { getOrderOrderType } from '@web/hooks/orders/useGetOrderOrderType';
import { getCompositeKeyFromOrder } from '@web/utils/functions/orders';

// ########################## [HOOKS]
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const ToShipExternalIdColumnCode = 'order';

/**
 * Column holding info about the external id (aka transaction id) of a ready to ship order
 *
 * @returns react-table column configuration for the external id column
 */
export const useGetToShipExternalIdColumn = (
  rowDensity: RowDensity | null,
): ColumnDef<Order, string> => {
  const { t } = useTranslation(),
    transCommon = translations.common;

  const size = useGetTabColumnSize(ToShipExternalIdColumnCode, 248);

  return useMemo(
    () =>
      columnHelper.accessor('external_id', {
        header: t(transCommon.order),
        minSize: 150,
        size,
        maxSize: 450,
        id: 'external_id',
        enableSorting: true,
        cell: ({ row: { original, getIsExpanded } }) =>
          toShipExternalIdColumnRendererFn(
            t,
            getIsExpanded() ? RowDensity.S : rowDensity,
            original,
          ),
      }),
    [t, transCommon, rowDensity, size],
  );
};

const toShipExternalIdColumnRendererFn = (
  t: TFunction<'translation', undefined>,
  rowDensity: RowDensity | null,
  order: Order,
) => {
  const {
    id,
    external_id,
    items_count,
    marketplace_platform,
    marketplace_id,
    documentation,
    notes,
    order_status,
  } = order;

  const transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns,
    transPopovers = transToShip.popovers;
  // TODO: this is gonna be useful when two tooltips will be used, for every id not truncated
  // transTooltips = translations.order.tooltips;

  const text = external_id || id.toString();
  // TODO: this is gonna be useful when two tooltips will be used, for every id not truncated
  // const textTooltip = t(transTooltips.openOrderPage);

  const shouldShowDocumentIcon = documentation && documentation.length > 0;
  const shouldShowReturnIcon =
    marketplace_platform === 'Returns' || order.is_normal_return;
  const shouldShowNotesIcon = !!notes;

  const orderType = getOrderOrderType(order);

  return (
    <div
      className="w-full flex flex-col space-y-1"
      onClick={e => e.stopPropagation()}
    >
      <div className="flex items-center justify-start max-w-[80%]">
        <MarketplaceBadge
          mrkId={marketplace_id}
          mrkPlatform={marketplace_platform}
        />
        <LinkWithBadge
          url={`/orders/toship${getCompositeKeyFromOrder(order)}`}
          className="break-all !text-[1rem]"
          text={text}
          textTooltip={text}
          textTarget={`toship-order-items-${id}`}
          invertTextColor={!external_id}
          allowEvents
        />
        {(shouldShowReturnIcon ||
          shouldShowNotesIcon ||
          shouldShowDocumentIcon ||
          orderType) && (
          <span className="flex space-x-3">
            {rowDensity === RowDensity.S && orderType && (
              <OrderTypeBadge orderType={orderType} compact={true} />
            )}
            {rowDensity === RowDensity.S && order.merged && (
              <OrderMergedBadge compact={true} />
            )}
            {rowDensity === RowDensity.S && order.splitted && (
              <OrderSplitBadge compact={true} />
            )}
            {rowDensity === RowDensity.S && shouldShowReturnIcon && (
              <IsReturnBadge compact={true} />
            )}
          </span>
        )}
      </div>
      {rowDensity !== RowDensity.S && (
        <div className="flex items-center justify-start !gap-2">
          {items_count && items_count > 1 && (
            <ItemsCountBadge
              count={items_count}
              tooltipText={t(transPopovers.orderItemsExp, {
                num: items_count,
              })}
              target={`toship-order-items-badge-${id}`}
            />
          )}
          {orderType && <OrderTypeBadge orderType={orderType} />}
          {order.merged && <OrderMergedBadge />}
          {order.splitted && <OrderSplitBadge />}
          {shouldShowReturnIcon && <IsReturnBadge />}
          {shouldShowNotesIcon && (
            <NotesBadge orderId={id} orderStatus={order_status} notes={notes} />
          )}
          {shouldShowDocumentIcon ? (
            <DocumentsBadge documentation={documentation} orderId={id} />
          ) : null}
        </div>
      )}
    </div>
  );
};
