import { compareAsc, subMinutes } from 'date-fns';

import { CacheItem } from '@shippypro/api-client/utils';

import { useGetChangelog } from '@web/features/_global/hooks/api/useGetChangelog';

/**
 * [HOOK] This hook pings Changelogfy and checks if there are
 * any new news articles that the user has yet to see.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {boolean} If there are news the user has yet to see
 */
export const useCheckForChangelogUpdates = (): boolean => {
  // Get the current changelog news
  const { changelog, publishedPosts } = useGetChangelog();

  // Preventing time values from exceeding today's date (yes it actually happens)
  const today = subMinutes(new Date(), 30).getTime();

  // Fetching the last time the user closed the changelog
  const userLastUpdate = Math.min(
    today,
    parseInt(CacheItem('lastChangelog') ?? '-3600'),
  );

  // Checking the BE changelog data's last publishing date
  const changelogLastUpdate = Math.min(
    today,
    parseInt(changelog?.lastPublishDate ?? '0') * 1000 ?? -3600,
  );

  // Opening the modal ONLY IF:
  // * The BE's last changelog post is newer than the user's last interaction with the modal;
  // * There actually are posts to show the user;
  return (
    compareAsc(userLastUpdate, changelogLastUpdate) === -1 &&
    publishedPosts.length > 0
  );
};
