import truncate from 'lodash/truncate';

export const formatValue = (
  value: string | Array<string> | boolean,
  filterKey: string,
) => {
  const truncateLength = filterKey === 'dateRange' ? Infinity : 15;

  let formattedValue = truncate(String(value), { length: truncateLength });

  if (Array.isArray(value)) {
    const firstValue = value[0];
    const remainingItems = value.length - 1;

    formattedValue = `${truncate(firstValue, { length: truncateLength })} ${remainingItems > 0 ? `+${remainingItems}` : ''}`;
  }

  return {
    formattedValue,
    isValueTruncated:
      formattedValue.includes('...') || formattedValue.includes('+'),
  };
};
