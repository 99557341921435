import { DefaultValues } from 'react-hook-form';

import { BaseAPIResult } from '@web/types/common/APIResults';
import type {
  RemoteTableData,
  Sorting,
  PaginatedData,
  Pagination,
} from '@web/types/tables';
import { GLSFormatValue } from '../components/dialogs/glsitaly-printformat-picker';
import { Filtering } from '@web/features/ship/types/filtering';

import type {
  AutoMergeAnalysisResult,
  Item,
  ItemToSelect,
  Note,
  Order,
  OrderCategory,
  OrderGroupNotMerged,
  OrderGroupsToMerge,
  Parcel,
} from '@web/types/order';
import { Address } from '@web/types/common/address';
import { DeliverView } from '@web/types/user/preferences/deliver-views';
import { UserPreferencesDomain } from '@web/types/user/preferences';
import { IOrderFilterForm } from '../components/forms/FiltersForm/types';
import { ExcelColumnsTemplate } from '../components/drawers/order-summary-drawer/types';

/** Picking List drawer types */
export type PickingListItem = Omit<Item, 'id' | 'attributes' | 'price'> & {
  id: number | null; // handling optional ids for newly created items
} & {
  price: number;
};

export interface IPickedOrder {
  shippyProNote: string;
  items: PickingListItem[];
}

/* --- STATE --- */
export interface ShipState {
  actions: {
    exportPerformingAction: boolean;
    printing: boolean;
    newDocPerformingAction: boolean;
  };
  tablesSettings: ShipTableSettingsState;
  infoDrawer: {
    open: boolean;
    order: Order | null;
  };
  editViewsDrawer: {
    open: boolean;
    tabs: DeliverView[];
  };
  editOrderNotesDialog: null | {
    orders: Order[];
    category: OrderCategory;
  };
  pickingListDrawer: {
    category: OrderCategory | null;
    currentlyOpenDrawerType: PickingListType | null;
    pickedOrders: Record<number, IPickedOrder>;
  };
  invoicePackingSlipDrawer: {
    open: boolean;
    type: InvoicePackingSlipType;
  };
  saveCustomViewDialog: null | {
    submittedFilters: DefaultValues<IOrderFilterForm>;
    startingCategory: OrderCategory;
  };
  orderSummaryDrawer: {
    open: boolean;
    orders: Order[];
  };
}

// The common state of every Orders Table in the `Ship` page
export type ShipTableState = RemoteTableData<Order, Filtering> & {
  filtered: boolean;
  selected: number[];
};

export enum RowDensity {
  S = 'S',
  M = 'M',
  L = 'L',
}

export type ShipTableSettingsState = {
  drawerOpen: boolean;
  showLargeRowDensityOption: boolean;
  toShip: {
    actionsShown: boolean;
    rowDensity: RowDensity | null;
  };
  shipped: {
    actionsShown: boolean;
    rowDensity: RowDensity | null;
  };
  error: {
    actionsShown: boolean;
    rowDensity: RowDensity | null;
  };
  archived: {
    actionsShown: boolean;
    rowDensity: RowDensity | null;
  };
  deleted: {
    actionsShown: boolean;
    rowDensity: RowDensity | null;
  };
};

export interface IOrdersTableProps {
  orders: PaginatedData<Order>;
  isFetching: boolean;
  handleClick: (row: Order, e: React.MouseEvent, scroll?: string) => void;
  handleDocs?: (order: Order) => void;
}

// The types of the payload passed and received when the paginated tables are changing
export type OrderTableReloading = {
  category: OrderCategory;
  page?: number;
  sorting?: Sorting;
  filtering?: Filtering;
  filtered?: boolean;
};

export enum InvoicePackingSlipType {
  Invoice = 'invoice',
  PackingSlip = 'packingSlip',
}

export enum OrderImportFileExtension {
  // It's not possible to import txt in v0 because we don't support custom columns yet.
  // uncomment next line when ready
  // 'txt' = 'txt',
  'csv' = 'csv',
  'xls' = 'xls',
  'xlsx' = 'xlsx',
}

// The gateway API calls related to the `Ship` feature
export enum ShipAPIActions {
  OrderGetList = 'OrderGetList',
  OrderPrintMultipleLabelsPDF = 'OrderPrintMultipleLabelsPDF',
  OrderGetOrderList = 'OrderGetOrderList',
  OrderCreateInvoice = 'OrderCreateInvoice',
  OrderCreateManifest = 'OrderCreateManifest',
  OrderCreateCustomDocument = 'OrderCreateCustomDocument',
  CarrierCloseShipments = 'CarrierCloseShipments',
  OrderPrintPrintNode = 'OrderPrintPrintNode',
  OrderPrintZPL = 'OrderPrintZPL',
  OrderImport = 'OrderImport',
  OrderCreateA6PackingSlip = 'OrderCreateA6OrderSummary',
  OrderCreatePickingList = 'OrderCreatePickingList',
  OrderCreateDocumentArchive = 'OrderCreateDocumentArchive',
  CustomerCreateAddress = 'CustomerCreateAddress',
  CustomerEditAddress = 'CustomerEditAddress',
  GetOrderPriceRange = 'OrderPriceRange',
  GetProducts = 'GetProducts',
  GetTags = 'TagGetTags',
  OrderGetStatuses = 'OrderGetStatuses',
  ShipGetQzTrayAuthData = 'PortalGetQZTrayAuthData',
  PortalGetZipCodes = 'PortalGetZipCodes',
  OrderGetPaymentMethods = 'OrderGetPaymentMethods',
  OrderPrintBrtLabels = 'OrderPrintBRTLabels',
  OrderGetLastOrders = 'OrderGetLastOrders',
  OrderCheckAutoMergeCapability = 'OrderCheckAutoMergeCapability',
  OrderAutoMergeOrders = 'OrderAutoMergeOrders',
  OrderSplitOrder = 'OrderSplitOrder',
  OrderEditImportExcelColumns = 'OrderEditImportExcelColumns',
  OrderDeleteExportExcelColumnsTemplate = 'OrderDeleteExportExcelColumnsTemplate',
  OrderSaveExportExcelColumnsTemplate = 'OrderSaveExportExcelColumnsTemplate',
  // [EXPORT NEW ACTIONS ABOVE] < Needed for generating API Hooks seamlessly
}

export type FeatureState = ShipState;

/* --- QUERY TYPES --- */

export type UserConfiguredTabsQueryPayload = {
  user_id: number;
};

export type UserConfiguredTabsResult = {
  preferences: {
    user_id: string;
    domain: UserPreferencesDomain.DeliverViews;
    views_list: DeliverView[];
  };
};

export type UserConfiguredTabsMutationPayload = {
  user_id: number;
  views_list: DeliverView[];
};

export type UserConfiguredTabsUpdateData = {
  name: string;
  startingCategory: OrderCategory;
  configuredFilters: Record<string, unknown>;
  rowDensity?: RowDensity;
};

export type OrdersTableDataQueryPayload = {
  tabCode: string;
  category: string;
  filters: any;
  pagination: Pagination;
  sort: Sorting | null;
};

export type OrdersActionBaseMutationPayload = {
  ids: number[];
  category?: string;
  columns?: string;
};

export type ImportOrdersFromFileMutationPayload = {
  name: string;
  base64File: string;
  extension: string;
};

export type PrintOrdersBaseMutationPayload = OrdersActionBaseMutationPayload & {
  glsformat?: GLSFormatValue;
};

export type PrintOrderListMutationPayload = OrdersActionBaseMutationPayload & {
  format: string;
};

export type PrintOrdersMutationResult = { base64File: string };

export type CloseWorkDayMutationPayload = {
  carriers: {
    carrier_name: string;
    ids: number[];
  }[];
};

export type CloseWorkDayMutationResult = {
  carrier?: string;
  error: string;
  manifest_number: string;
  manifest_urls: string[];
  additional_docs: { extension: string; base64: string }[];
};

export type PickingListItemInput = {
  id: number | null;
  weight: number | null;
  img: string | null;
  qty: number;
  title: string;
  price: number;
  currency: string | null;
  location: string | null;
  sku: string;
  asin: string;
  ean: string;
  note: string;
};

export type PickingListOrderWithItems = {
  id: number;
  items: PickingListItemInput[];
  notes: Note;
};

export enum PickingListType {
  ByOrder = 'ByOrder',
  ByItem = 'ByItem',
}

export type PreferencesPayload = {
  product_code: boolean;
  location: boolean;
  barcode: boolean;
  customer_info: boolean;
  product_image: boolean;
  note: boolean;
};

export type CreatePickingListMutationPayload = {
  type: PickingListType;
  category: 'ToShip' | 'Shipped';
  orders: PickingListOrderWithItems[];
  preference: PreferencesPayload;
};

export type CreateDocumentArchivePayload = {
  category: OrderCategory;
  id: number;
};

export type CreateDocumentArchiveResult = {
  base64file: string;
  filename: string;
};

export type CreatePickingListMutationResult = {
  file: string;
  pageFormat: string;
};

export type BaseFileExportResult = { base64File: string; error: string };
export type BaseFileAltExportResult = { base64: string; error: string };

export type getQzTrayAuthDataMutationPayload = {
  to_sign?: string;
  type: 'signature' | 'certificate';
};

export type getQzTrayAuthDataMutationResult = {
  signature?: string;
  certificate?: string;
  message: string | null;
};

type NullableEmail<T> = {
  [K in keyof T]: K extends 'email' ? string | null : T[K];
};

export type OrdersCreateInvoiceOrPackingSlipMutationPayload = {
  document_type: string;
  documents: {
    additional_decription: string | null;
    additional_tax_id: string | null;
    address: NullableEmail<Address>;
    currency: string | null;
    date: string | null;
    external_id: string | null;
    footer_description: string | null;
    items: ItemProps[];
    marketplace_id: number | null;
    marketplace_platform: string | null;
    payment_method: string | null;
    shipment_cost: number | null;
    shippypro_id: number | null;
    vat_number: string;
  }[];
  include_shipping_cost: boolean;
  prefix: string;
  progressive_number: number;
  show_logo: boolean;
  vat: number;
};

export type ItemProps = {
  id: number | null;
  description: string;
  quantity?: number;
  price?: number | string | null;
};

export type GetProductsQueryPayload = {
  name: string;
  category: string;
};

export type GetProductsQueryResult = ItemToSelect;

export type GetTagsQueryPayload = {
  search: string;
};

export type GetZipCodesQueryPayload = {
  iso_code: string;
  search_term: string;
};

export type ZipCode = {
  value: string;
  city: string;
  label: string;
};

export type GetZipCodesQueryResult = ZipCode[];

export type GetPaymentMethodsQueryPayload = {
  category: OrderCategory;
  search_term: string;
  date_from: string;
  date_to: string;
};

export type PaymentMethod = { value: string; label: string };

export type GetPaymentMethodsQueryResult = PaymentMethod[];

export enum MenuId {
  archived = 'archived-context-menu',
  deleted = 'deleted-context-menu',
  error = 'error-context-menu',
  shipped = 'shipped-context-menu',
  toShip = 'toShip-context-menu',
}

export type CarrierByTable = {
  carrierId: string;
  carrierName: string;
  displayName: string;
  carrierLogo: string;
};

export type CarriersByTableDataQueryResult = {
  carriers: CarrierByTable[];
};

// --------- [ GET LAST ORDERS ]

export type LastOrder = {
  ordine_id: number;
  transaction_id: string;
  carrier_name: string;
  carrier_id: string;
  created_at: number;
  sender: Omit<Address, 'id'>;
  recipient: Omit<Address, 'id'>;
  parcels: Parcel[];
};

export type GetLastOrdersQueryPayload = {
  search_term: string;
};

export type GetLastOrdersQueryResult = LastOrder[];

// --------- [ CHECK AUTO-MERGE CAPABILITY ]

export type CheckAutoMergeCapabilityQueryPayload = {
  order_ids: number[];
};

export type CheckAutoMergeCapabilityQueryResult = AutoMergeAnalysisResult;

// --------- [ AUTO-MERGE EXECUTION ]

export type OrderAutoMergeMutationPayload = {
  ids: OrderGroupsToMerge;
};

export type OrderAutoMergeMutationResult = {
  result: boolean;
  errors: OrderGroupNotMerged[];
};

// --------- [ SPLIT ORDER ]

export type OrderSplitMutationPayload = {
  toShipOrderID: number;
  items: number[];
};

export type OrderSplitMutationResult = BaseAPIResult<boolean>;

// --------- [ EDIT IMPORT EXCEL COLUMNS ]
export type EditImportExcelColumnsMutationPayload = {
  columns: string[];
};

export type EditImportExcelColumnsMutationResult = {
  result: boolean;
};

// --------- [ GET EXPORT EXCEL COLUMNS TEMPLATES ]
export type GetExportExcelColumnsTemplatesMutationResult =
  ExcelColumnsTemplate[];

// --------- [ DELETE EXPORT EXCEL COLUMNS TEMPLATE ]
export type DeleteExportExcelColumnsTemplateMutationPayload = {
  id: string;
};

export type DeleteExportExcelColumnsTemplateMutationResult = {
  result: boolean;
};

// --------- [ SAVE EXPORT EXCEL COLUMNS TEMPLATE ]
// id could be null for new templates during save operations
export type SaveExportExcelColumnsTemplateMutationPayload = Omit<
  ExcelColumnsTemplate,
  'id'
> & { id: string | null };

export type SaveExportExcelColumnsTemplateMutationResult = {
  result: boolean;
};

// --------- [ ORDER SERVICE MICROSERVICE API ENDPOINTS ]
export enum ShipMSAPIEndpoints {
  OrderGetStatuses = 'orders/statuses',
  OrderGetExportExcelColumnsTemplates = 'orders/exporter/spreadsheet-columns-templates',
  OrderToShipGetImportExcelColumns = 'orders-to-ship/importer/spreadsheet-columns',
}
