import { useMemo } from 'react';
import { useIdle } from 'react-use';

import { isTestingEnv } from '@shippypro/utils';

type ReturnType = {
  pollingInterval: number;
  skip: boolean;
};

/**
 * **[HOOK]** Returns a polling object for RTK Query with an idleness check
 * to avoid polling when the user is idle/elsewhere.
 *
 * @param {number} [pollingInterval=120000] - The polling interval in milliseconds
 * @returns {ReturnType} The polling object
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const useGetPollingTimeWithIdlenessCheck = (
  pollingInterval: number = 120000,
): ReturnType => {
  // Check if the user is in an idle state (no mouse or keyboard activity OR no tab focus for 5 seconds)
  // The Cypress API doesn't have a way to move the mouse to stop the idleness, so we'll just disable this when in testing mode
  const idleResult = useIdle(5000);
  const isIdle = isTestingEnv() ? false : idleResult;

  return useMemo(
    () => ({
      pollingInterval, // Polling every N minutes to see if other alerts have been added
      skip: isIdle,
    }),
    [isIdle, pollingInterval],
  );
};

export default useGetPollingTimeWithIdlenessCheck;
