import { memo, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { useSideMenuStore } from '../_global/layout/SideMenu/store/side-menu-store';
import { usePageHeaderContext } from './index.context';
import * as S from './index.styles';

/**
 * **[COMPONENT]** The page top header component.
 * The top header of the site
 * displaying different elements based on the current page and configuration in the store.
 * Not all the pages have a top-bar.
 *
 * @author Marcello Aguzzi <marcello.aguzzi@shippypro.com>
 * @author Orkhan Rustamli <orkhan.rustamli@shippypro.com>
 */
export const PageHeader = memo(() => {
  const { leftContentRef, centerContentRef, rightContentRef } =
    usePageHeaderContext();

  const isSideMenuOpen = useSideMenuStore(state => state.isOpen);
  const isSideMenuHidden = useSideMenuStore(state => state.isHidden);
  const isSideMenuPinned = useSideMenuStore(state => state.isPinned);

  return (
    <header
      id="page-header"
      data-test="page-header"
      className={classnames(
        'sticky top-0 right-0 z-10',
        'bg-white shadow-sm',
        'grid grid-cols-12 gap-2',
        { '!left-0': isSideMenuHidden },
        { '!left-14': !isSideMenuOpen && !isSideMenuPinned },
        {
          '!left-64': isSideMenuPinned || (!isSideMenuPinned && isSideMenuOpen),
        },
      )}
    >
      <S.Content
        ref={leftContentRef}
        data-test="page-header-left-content"
        className={classnames(
          'flex items-center justify-start flex-wrap',
          'col-span-4',
          {
            '!ml-4': !isSideMenuOpen && !isSideMenuPinned,
            '!ml-8': isSideMenuPinned || (!isSideMenuPinned && isSideMenuOpen),
          },
        )}
      />
      <S.Content
        $justify="center"
        data-test="page-header-center-content"
        ref={centerContentRef}
        className="col-span-4"
      />
      <S.Content
        $justify="flex-end"
        data-test="page-header-right-content"
        ref={rightContentRef}
        className="col-span-4"
      />
    </header>
  );
});

PageHeader.displayName = 'PageHeader_memoized';

export const PageHeaderLeftContent = memo(({ children }: PropsWithChildren) => {
  const { setContent, leftContentRef } = usePageHeaderContext();
  return setContent(leftContentRef, children);
});

export const PageHeaderCenterContent = memo(
  ({ children }: PropsWithChildren) => {
    const { setContent, centerContentRef } = usePageHeaderContext();
    return setContent(centerContentRef, children);
  },
);

export const PageHeaderRightContent = memo(
  ({ children }: PropsWithChildren) => {
    const { setContent, rightContentRef } = usePageHeaderContext();
    return setContent(rightContentRef, children);
  },
);

export * from './components/PageTitle';
export * from './index.context';
