// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

// ########################## [STATE MANAGEMENT]
import {
  CustomRTKQueryOptions,
  parseAPIError,
} from '@web/utils/@reduxjs/rtk-query';

// ########################## [TYPES]
import { EntityType } from '@shippypro/foundation/settings/types';

// ########################## [HOOKS]
import { useGetMFAMethodQuery } from '@shippypro/foundation/settings/api';
import useLDFlag from '@web/hooks/useLDFlags';

/**
 * Returns RTK Query hook with renamed data field.
 * Returns the MFA Method based on entity type and entity id.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetMFAMethod = (
  entityId?: number,
  entityType?: EntityType,
  options?: CustomRTKQueryOptions,
) => {
  const { settingsOnUiApp } = useLDFlag();

  const apiPayload = useMemo(
    () => ({
      entity_type: entityType ?? EntityType.User,
      entity_id: entityId,
    }),
    [entityId, entityType],
  );

  const { data, error, isFetching, ...rest } = useGetMFAMethodQuery(
    apiPayload,
    { ...options, skip: !(settingsOnUiApp && entityId !== undefined) },
  );

  return useMemo(
    () => ({
      MFAMethods: data ?? null,
      unparsedError: error,
      error: parseAPIError(error),
      isFetching,
      ...rest,
    }),
    [data, error, isFetching, rest],
  );
};
