import { mixpanel } from '@web/utils/@user-tracking/mixpanel';
import {
  PickingListCategory,
  PickingListType,
} from '@web/features/ship/types/index';
import { OrderDifferences } from '@web/features/manage-order-modal/functions/diff-order';
import { mapObjectKeysToSnakeCase } from '@shippypro/api-client/utils';
import {
  CreateInvoiceOrderStatus,
  CreateInvoiceVariant,
  PrintLabelMode,
  PrintLabelTrigger,
  ShipWithModalSelectionTrigger,
} from './tracking.types';

const PLATFORM = 'Deliver';

export const trackShipBtnClicked = (ordersSelected = 1) =>
  mixpanel.track('ship_button_clicked', {
    platform: PLATFORM,
    orders_selected: ordersSelected,
  });

export const trackPickingListGenerated = (payload: {
  ordersSelected: number;
  orderStatus: PickingListCategory;
  variant: PickingListType;
}) =>
  mixpanel.track('picking_list_generated', {
    patform: PLATFORM,
    ...mapObjectKeysToSnakeCase(payload),
  });

export const trackShipWithModalSelection = (payload: {
  selectedCarriersCount: number;
  trigger: ShipWithModalSelectionTrigger;
}) => {
  mixpanel.track('ship_with_premodal', {
    platform: PLATFORM,
    carriers_selected: payload.selectedCarriersCount,
    trigger: payload.trigger,
  });
};

export const trackOrderEdited = (payload: {
  editedFields: OrderDifferences;
  orderId: number;
  isReturn: boolean;
  modalType: 'edit' | 'ship';
}) => {
  mixpanel.track('order_edited', {
    platform: PLATFORM,
    ...(payload.editedFields.recipient.length && {
      recipient_edited_fields: payload.editedFields.recipient.join(','),
    }),
    ...(payload.editedFields.sender.length && {
      sender_edited_fields: payload.editedFields.sender.join(','),
    }),
    ...(payload.editedFields.details.length && {
      details_edited_fields: payload.editedFields.details.join(','),
    }),
    ...(payload.editedFields.parcels.length && {
      parcels_edited_fields: payload.editedFields.parcels.join(','),
    }),
    order_id: payload.orderId,
    is_return: payload.isReturn,
    modal: payload.modalType,
  });
};

export const trackPrintLabel = (payload: {
  ordersSelected: number;
  mode: PrintLabelMode;
  trigger: PrintLabelTrigger;
}) => {
  mixpanel.track('print_label_click', {
    platform: PLATFORM,
    orders_selected: payload.ordersSelected,
    print_mode: payload.mode,
    trigger: payload.trigger,
  });
};

export const trackCreateInvoice = (payload: {
  ordersSelected: number;
  ordersStatus: CreateInvoiceOrderStatus;
  variant: CreateInvoiceVariant;
}) => {
  mixpanel.track('invoice_generated', {
    platform: PLATFORM,
    orders_selected: payload.ordersSelected,
    variant: payload.variant,
    orders_status: payload.ordersStatus,
  });
};

export const trackCreateManifest = (payload: { ordersSelected: number }) => {
  mixpanel.track('manifest_generated', {
    platform: PLATFORM,
    orders_selected: payload.ordersSelected,
  });
};

export const trackOrderSplit = () => {
  mixpanel.track('order_split', { platform: PLATFORM });
};
