import qz from 'qz-tray';
import { PrinterListType } from './types';
import { isTestingEnv } from '@shippypro/utils';

let qzioConfig = qz.configs.create(null);

export function qzAuthenticate(fetchFn: Function): void {
  // Setting the ShippyPro Certificate
  qz.security.setCertificatePromise(function (resolve, reject) {
    fetchFn({ type: 'certificate' }).then(({ data }) => {
      if (!data.certificate) {
        reject(data.message);
      }

      resolve(data.certificate);
    });
  });

  // Setting the signature algorithm
  qz.security.setSignatureAlgorithm('SHA512');

  // Signing the certificate with the paid ShippyPro key
  qz.security.setSignaturePromise(function (toSign) {
    return function (resolve, reject) {
      fetchFn({ to_sign: toSign, type: 'signature' })
        .then(({ data }) => {
          if (!data.signature) {
            reject(data.message);
          }

          resolve(data.signature);
        })
        .catch(e => {
          throw e;
        });
    };
  });
}

// Return the qz connection status
export function isQzConnected(): boolean {
  return qz.websocket.isActive();
}

// Update qz config
export function getQZioUpdatedConfig() {
  if (qzioConfig === null) {
    qzioConfig = qz.configs.create(null);
  }
  return qzioConfig;
}

// Return a promise for the connect method
export function startQZioConnection(retries = 2) {
  return qz.websocket.connect({ retries: retries });
}

// Load printer list
export function loadQZIoPrinterList(
  force: boolean,
  printerList: PrinterListType,
  setList: (printersList: PrinterListType) => void,
) {
  if (isTestingEnv()) {
    const printerList = generatePrintersTestData();
    setList(printerList);
    return;
  }
  const cf = getQZioUpdatedConfig();

  qz.printers
    .getDefault()
    .then(function (dataDefault) {
      let defaultPrinter = dataDefault;
      let savedPrinter = false;

      if (printerList.some(printer => printer.isDefault === true)) {
        savedPrinter = true;
        defaultPrinter = printerList.find(
          printer => printer.isDefault === true,
        );
      }

      qz.printers.find().then(function (data) {
        const favouritePrinter = localStorage.getItem('favouritePrinter');
        // Set shippypro printer as default
        let printerList = data.map(printer => {
          if (
            printer.trim().indexOf('ShippyPro') >= 0 &&
            !force &&
            !savedPrinter
          ) {
            defaultPrinter = printer;
            cf.setPrinter(printer);
          }

          const isDefault = defaultPrinter === printer;
          const isFavourite = favouritePrinter === printer;

          return {
            name: printer,
            isDefault: isFavourite || isDefault,
            isFavourite,
          };
        });

        // Avoid multiple default printers
        const isFavouriteSet = printerList.some(p => p.isFavourite);
        if (isFavouriteSet) {
          printerList = printerList.map(p => {
            if (p.isFavourite) {
              return p;
            }
            return { ...p, isDefault: false };
          });
        }

        // Set printer on qz config
        const printerToSet = printerList.find(p => p.isDefault)?.name;
        printerToSet && cf.setPrinter(printerToSet);

        // Favourite printer always on top
        printerList.sort((p1, p2) => p2.isFavourite - p1.isFavourite);
        setList(printerList);
      });
    })
    .catch(e => {
      console.error('error', e);
    });
}

// Printing function
export function printZebraQZio(data) {
  const config = getQZioUpdatedConfig();

  let printJobs = [] as any;

  if (data.zpl.length === 0 && data.epl.length === 0) {
    printJobs.push(Promise.reject('cannotPrint'));
  } else {
    if (data.zpl.length !== 0) {
      const printData = [
        {
          type: 'raw',
          format: 'plain',
          data: data.zpl,
          options: { language: 'ZPLII' },
        },
      ];

      printJobs.push(qz.print(config, printData));
    }

    if (data.epl.length !== 0) {
      const printData = [
        {
          type: 'raw',
          format: 'plain',
          data: data.epl,
          options: { language: 'EPL' },
        },
      ];

      printJobs.push(qz.print(config, printData));
    }
  }

  return printJobs;
}

export function forceQzTrayConnection(forceQzConnection) {
  if (window.Cypress) {
    window.model = {};
    window.model.forceQzConnection = forceQzConnection;
  }
}

function generatePrintersTestData() {
  return [
    { name: 'printer1', isDefault: false, isFavourite: true },
    { name: 'printer2', isDefault: true, isFavourite: false },
    { name: 'printer3', isDefault: false, isFavourite: false },
  ];
}
