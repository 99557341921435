export const isProductionEnv = (): boolean =>
  process.env.NODE_ENV === 'production';

export const isLocalEnv = (): boolean => process.env.NODE_ENV !== 'production';

export const isTestingEnv = (): boolean =>
  process.env.NODE_ENV !== 'production' && process.env.NX_ENV === 'testing';

export const isDevelopEnv = (): boolean =>
  process.env.NODE_ENV !== 'production' && process.env.NX_ENV === 'develop';
