import { FC, Fragment, useCallback } from 'react';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import { IconHelper } from '@ds-web-iconhelper';
import { getHumanReadableFileSize } from '@ds-web/utils/functions/files';

import { IFileUploadDropzoneProps } from './types';
import { FileUploadButton } from '../file-upload-button/FileUploadButton';

const DEFAULT_SUPPORTED_TYPES = {
  'text/plain': ['.txt'],
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
};

const DEFAULT_LABELS = {
  dropHere: 'Drag and drop photo here',
  or: '- or -',
  supportedExtensions: 'Profile picture must be a ',
  lessThan: 'file smaller than 5 MB',
  chooseFile: 'Choose file',
};

/**
 * A wrapper class for the `react-dropzone: <Dropzone />` component with button too.
 *
 * @interface IFileUploadDropzoneProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const FileUploadDropzoneWithButton: FC<IFileUploadDropzoneProps> = ({
  value,
  onChange,
  onError,
  formatFileSize = getHumanReadableFileSize,
  labels = DEFAULT_LABELS,
  supportedTypes = DEFAULT_SUPPORTED_TYPES,
  supportedTypesAdditionalLabels = null,
  showFileSize = true,
  multiple = false,
  removeIcon = 'IconCircleX',
}) => {
  const { t } = useTranslation();

  const allExtensions = Object.keys(supportedTypes).flatMap(key => {
    const types = supportedTypes[key];
    if (types.length) return types;
    return [key];
  });

  const allExtensionsLabels = Object.values(supportedTypes).flatMap(types =>
    types.map(type => supportedTypesAdditionalLabels?.[type] || type),
  );

  const extensionsFormatted = useCallback(() => {
    if (allExtensionsLabels.length <= 1) {
      return allExtensionsLabels.join('');
    }
    const lastExtension = allExtensionsLabels.pop();
    return `${allExtensionsLabels.join(', ')} ${labels.or && labels.or} ${lastExtension}`;
  }, [allExtensionsLabels, labels.or]);

  const onFileUpload = useCallback(
    result => {
      if (result.length) {
        const compliantFiles: File[] = [];
        result.forEach(file => {
          const isSupportedType = allExtensions.some(fType =>
            file.name.endsWith(fType),
          );
          if (!isSupportedType) {
            onChange([]);
            if (onError) {
              onError(new Error('unsupported type'));
            }
          } else {
            compliantFiles.push(file);
          }
        });

        onChange(compliantFiles);
      }
    },
    [allExtensions, onChange, onError],
  );

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    multiple,
    accept: supportedTypes,
    onDrop: result => {
      onFileUpload(result);
    },
  });

  const inputProps = getInputProps();

  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      // prevent file selection if a file was already selected
      // (you must clear the existing value first)
      {...(value.length ? { onClick: e => e.preventDefault() } : undefined)}
    >
      <p className="text-[#7F858A]">
        {labels.supportedExtensions && (
          <>
            <span>{labels.supportedExtensions} </span>
            {extensionsFormatted()}
          </>
        )}
        {labels.lessThan && labels.lessThan}
      </p>
      <input {...inputProps} />
      {!value.length ? (
        <div
          className={classnames(
            'flex items-center flex-col justify-center py-1 min-h-[135px] cursor-pointer border-[1px]',
            '!border-accent !border-dashed rounded-2xl hover:!border-[color:--shp-color-genericui-primary]',
            {
              'bg-[color:--shp-color-bg-accent-highlight]': isDragAccept,
            },
          )}
        >
          {isDragAccept ? (
            <div className="font-bold text-accent">
              {t(translations.common.dropFilesHere)}
            </div>
          ) : (
            <>
              <p className="text-center text-accent pb-[0.5rem]">
                {labels.dropHere && (
                  <span className="font-bold">{labels.dropHere}</span>
                )}
              </p>
              <span>- {labels.or && labels.or} -</span>
              <FileUploadButton
                btnClassName="round bg-accent"
                labelClassName="text-white"
                label={labels.chooseFile && labels.chooseFile}
                onUpload={result => onFileUpload(result)}
                style={{ fontFamily: 'Roboto' }}
                dataTest="file-upload-btn"
              />
            </>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center flex-col relative">
          {value.map((file, i) => (
            <Fragment key={i}>
              <span className="rounded-lg cursor-pointer ml-auto right-0 top-0">
                <IconHelper
                  icon={removeIcon}
                  onClick={e => {
                    e.stopPropagation();
                    onChange(value.filter(f => f.name !== file.name));
                  }}
                />
              </span>
              <IconHelper icon="IconFile" size={64} className="pb-1" />
              <p className="text-lg text-center pb-1">{file.name}</p>
              {showFileSize && (
                <p className="text-center">({formatFileSize(file.size)})</p>
              )}
              {value.length > 1 && <hr className="!my-3" />}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
