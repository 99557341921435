import { Order, OrderTimeline, OrderCategory } from './order';
import { OrderRates, Rate, ErrorRate } from './rates';
import { Item, ItemToSelect } from './item';
import { Shipment } from './shipment';
import { Payment, CashOnDeliveryType } from './payment';
import { Parcel } from './parcel';
import { Documentation, ETDDocument, ETDDocumentType } from './documentation';
import { Note } from './note';
import { OrderManagedResult } from './management';
import {
  Cn22ContentType,
  Cn22Item,
  NonDeliveryOptionType,
  CustomInfo,
} from './cn22';
import {
  AdditionalInfo,
  AdditionalInfoAddressType,
  AdditionalInfoOrderType,
  AdditionalInfoName,
} from './additional-info';
import {
  AutoMergeAnalysisResult,
  MergeableOrder,
  MergeableOrdersList,
  OrderGroupsToMerge,
  OrderGroupNotMerged,
} from './merging';

// EXPORT TYPES
export type {
  Order,
  OrderTimeline,
  Item,
  ItemToSelect,
  Shipment,
  Payment,
  Parcel,
  Documentation,
  ETDDocument,
  Note,
  OrderRates,
  Rate,
  ErrorRate,
  OrderManagedResult,
  Cn22Item,
  CustomInfo,
  AdditionalInfo,
  AutoMergeAnalysisResult,
  MergeableOrdersList,
  MergeableOrder,
  OrderGroupsToMerge,
  OrderGroupNotMerged,
};

// EXPORT ENUMS
export {
  OrderCategory,
  Cn22ContentType,
  CashOnDeliveryType,
  ETDDocumentType,
  NonDeliveryOptionType,
  AdditionalInfoName,
  AdditionalInfoAddressType,
  AdditionalInfoOrderType,
};
