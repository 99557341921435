import DatePickerUI from 'react-datepicker';
import type { DatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './Datepicker.scss';

export const DatePicker = (props: DatePickerProps) => (
  <DatePickerUI {...props} />
);

export type {
  DatePickerProps,
  ReactDatePickerCustomHeaderProps,
} from 'react-datepicker';
