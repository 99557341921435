// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { browserLanguage } from '@web/utils/@translations/browser-language';

// ########################## [TYPES]
import { LanguageCode } from '@web/types/common/translations';
import { ITranslationsContextProps } from '@web/contexts/translations';

// ########################## [CONTEXT]
import ProfileDetailsContext from '@shippypro/foundation/settings/context/profile-details';

/**
 * [HOOK] This context hosts the translation logics managing the language set by the user or browser language
 * and exposes:
 * - the current language code
 * - the current language
 *
 * @return {IManageDisplayLanguageTranslation} - The display languages options and the current display language
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const useInstanceTranslations = (): ITranslationsContextProps => {
  // Language set on browser
  const language = browserLanguage;

  // Getting profile details
  const {
    profileDetails,
    methods: { setProfileDetails },
  } = useContext(ProfileDetailsContext)!;

  // Util fn to conver lang (it) in display language code (it_IT)
  const convertLangInDisplayLangCode = useCallback((language: string) => {
    return (
      Object.values(LanguageCode).find(languageCode =>
        languageCode.includes(language),
      ) ?? LanguageCode.EN
    );
  }, []);

  // Util fn to conver display language code (it_IT) in lang (it)
  const convertDisplayLangCodeInLang = useCallback(
    (displayLanguage: LanguageCode) => {
      return displayLanguage.split('_')[0];
    },
    [],
  );

  // Prepare set for current language code
  const [currentLanguageCode, setCurrentLanguageCode] = useState<LanguageCode>(
    LanguageCode.EN,
  );
  // Prepare set for current language
  const [currentLanguage, setCurrentLanguage] = useState<string>('en');

  // Everytime profileDetails changes, check if the user has set a display language
  useEffect(() => {
    if (profileDetails) {
      // If the display language is set
      if (profileDetails.display_language) {
        // update the current display language
        setCurrentLanguageCode(profileDetails.display_language);
      } else {
        // If the user hasn't set a display language
        // set it to the browser language, or english as a fallback
        const displayLanguageCode = convertLangInDisplayLangCode(language);
        setCurrentLanguageCode(displayLanguageCode);
        // and save it as profile detail
        setProfileDetails({ display_language: displayLanguageCode });
      }
    } else {
      // set it to the browser language, or english as a fallback
      const displayLanguageCode = convertLangInDisplayLangCode(language);
      setCurrentLanguageCode(displayLanguageCode);
    }
  }, [
    convertLangInDisplayLangCode,
    language,
    profileDetails,
    setProfileDetails,
  ]);

  // set i18n language based on the customer's preferences
  const { i18n } = useTranslation();
  // Every time the current language changes
  useEffect(() => {
    const language = convertDisplayLangCodeInLang(currentLanguageCode);
    // update the i18n language
    i18n.changeLanguage(language);
    // update the current language
    setCurrentLanguage(language);
  }, [i18n, currentLanguageCode, convertDisplayLangCodeInLang]);

  return useMemo<ITranslationsContextProps>(
    () => ({
      currentLanguageCode,
      currentLanguage,
    }),
    [currentLanguageCode, currentLanguage],
  );
};
