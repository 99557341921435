import { useEffect, useMemo, useRef } from 'react';

import { translations } from '@shippypro/translations';
import { useTranslation } from 'react-i18next';

import {
  showErrorToastr,
  showSuccessToastr,
} from '@shippypro/design-system-web/functions';

import { useGetHealthCheckQuery } from '@web/features/_global/api';

const TOASTR_CONFIG = { toastId: 'status' };

export const useGetHealthCheck = () => {
  const { data, isLoading, isError, refetch, ...rest } =
    useGetHealthCheckQuery();

  const hasShownError = useRef(false);

  const isPortalHealthy = useMemo(() => {
    if (isLoading) return undefined;
    return !isError && data === 'ok';
  }, [data, isLoading, isError]);

  const { t } = useTranslation(),
    trans = translations.common;

  // Perform a HealthCheck on the Portal API Gateway
  useEffect(() => {
    const healthCheckInterval = 10 * 1000;
    const healthCheck = setInterval(() => {
      refetch();
    }, healthCheckInterval);
    return () => clearInterval(healthCheck);
  }, [refetch]);

  // Show notifications when portal health status changes
  useEffect(() => {
    if (isPortalHealthy === undefined) return;

    if (!isPortalHealthy) {
      hasShownError.current = true;
      showErrorToastr(
        t(trans.errors.error),
        t(trans.errors.connectionLost),
        TOASTR_CONFIG,
      );
    } else if (hasShownError.current) {
      showSuccessToastr(
        t(trans.success_alt),
        t(trans.connectionBackUp),
        TOASTR_CONFIG,
      );
    }
  }, [t, trans, isPortalHealthy]);

  return useMemo(
    () => ({
      isLoading,
      isError,
      refetch,
      isPortalHealthy,
      ...rest,
    }),
    [isLoading, refetch, isError, rest, isPortalHealthy],
  );
};
