import { LanguageCode } from '@web/types/common/translations';

import { createContext } from 'react';

export interface ITranslationsContextProps {
  currentLanguageCode: LanguageCode;
  currentLanguage: string;
}

/**
 * This context will provide all the necessary data for translations
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const TranslationsContext = createContext<ITranslationsContextProps | null>(
  null,
);

export default TranslationsContext;
