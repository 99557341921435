import { useContext, useMemo } from 'react';

import { localeLinks } from '@web/utils/@links/links';

import TranslationsContext from '@web/contexts/translations';

/**
 * This hook fetch the preferred language of the client and returns
 * the right slug based on the link key passed as a prop.
 * If the user's language is not one of the list, it defaults to the english version
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 * @param {string} linkKey
 * @returns {string}
 */

const useGetLocaleLink = (linkKey: string) => {
  const { currentLanguage } = useContext(TranslationsContext)!;

  const slugs = localeLinks[linkKey];

  const slug = useMemo(() => {
    return currentLanguage in slugs ? slugs[currentLanguage] : slugs.en;
  }, [slugs, currentLanguage]);

  return slug;
};

export default useGetLocaleLink;
