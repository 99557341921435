import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { SetupStepButton } from './components/SetupStepButton';

import { goToLegacy } from '@shippypro/utils';

import { useGetSetupData } from '@web/features/_global/hooks/api/useGetSetupData';
import { useNavigate } from 'react-router-dom';
import useLDFlag from '@web/hooks/useLDFlags';
import { useGetDeviceSize } from '@shippypro/design-system-web/hooks';
import styled from 'styled-components';

/**
 * The setup banner, shows three buttons to let the user easily reach the corresponding setup step
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const SetupBanner = () => {
  const { t } = useTranslation();

  const { isTabletMini } = useGetDeviceSize();

  const { newExperienceFlow } = useLDFlag();
  const navigate = useNavigate();

  const {
    data: [, setup],
  } = useGetSetupData();

  return (
    setup &&
    !setup.progress.setupCompleted &&
    newExperienceFlow && (
      <StyledDiv>
        <div
          data-test="setup-banner"
          className="setup-banner flex justify-between items-center px-2 py-1 bg-[color:--shp-color-bg-accent] "
        >
          <div data-test="setup-banner-title" className="!text-white font-bold">
            {isTabletMini
              ? t(translations.banners.setup.smallTitle)
              : t(translations.banners.setup.title)}
          </div>
          <div data-test="setup-banner-buttons" className="flex gap-2">
            <SetupStepButton
              icon="IconBuildingStore"
              isCompleted={setup.progress.storeCompleted}
              onClick={
                /* istanbul ignore next */
                () => goToLegacy('marketplace.html')
              }
              title={t(translations.banners.setup.stepButtons.stores)}
              step="stores"
            />
            <SetupStepButton
              icon="IconTruck"
              isCompleted={setup.progress.carrierCompleted}
              onClick={
                /* istanbul ignore next */
                () => goToLegacy('my-couriers.html')
              }
              title={t(translations.banners.setup.stepButtons.carriers)}
              step="carriers"
            />
            <SetupStepButton
              icon="IconSend"
              isCompleted={setup.progress.shipCompleted}
              onClick={
                /* istanbul ignore next */
                () => navigate('/ship')
              }
              title={t(translations.banners.setup.stepButtons.ship)}
              step="ship"
            />
          </div>
        </div>
      </StyledDiv>
    )
  );
};

const StyledDiv = styled.div`
  .setup-banner {
    box-shadow: 0px 5px 15px 0px #7349dd78;
  }
`;
