import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { DatePicker, DatePickerProps } from '@shippypro/design-system-web';

import { getCurrentLocaleObj } from '@web/utils/@date-fns/locale';

import Header from './ControlledDatePicker.Header';
import * as S from './ControlledDatePicker.styles';
import { getDatePickerValues } from './ControlledDatePicker.utils';

export type ControlledDatePickerProps<
  FormType extends FieldValues = FieldValues,
> = {
  name: Path<FormType>;
  control: Control<FormType>;
  datePickerProps?: Pick<DatePickerProps, 'maxDate' | 'minDate'>;
  className?: string;
  disabled?: boolean;
  dataTest?: string;
};

export const ControlledDatePicker = ({
  name,
  control,
  datePickerProps,
  className = '',
  disabled = false,
  dataTest = 'controlled-date-picker',
}: ControlledDatePickerProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => {
        const { selected, startDate, endDate } = getDatePickerValues(value);

        return (
          <DatePicker
            {...datePickerProps}
            calendarClassName={className}
            data-test={dataTest ?? name}
            inline
            selectsRange
            disabledKeyboardNavigation
            onBlur={onBlur}
            onChange={dates => onChange(dates.filter(Boolean))}
            selected={selected}
            startDate={startDate}
            endDate={endDate}
            disabled={disabled}
            locale={getCurrentLocaleObj()}
            useWeekdaysShort
            renderCustomHeader={Header}
          />
        );
      }}
    />
  );
};
