import styled from 'styled-components';
import { Row } from 'reactstrap';

const activeBgColor = '#fff';
const borderColor = '#7f858a';

const StyledContainer = styled(Row)<{
  $fullHeight: boolean;
  $unpadCards: boolean;
}>`
  height: 100%;
  width: 100%;

  padding: 0 !important;
  margin: 0 !important;

  .nav-tabs {
    width: 100%;

    margin-bottom: 0;

    .filler {
      border-bottom: 1px solid ${borderColor};
    }

    .nav-item,
    .btn-group {
      flex-grow: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      padding: 0.3rem 1rem;

      transition: background-color 0.1s ease-in-out;
      background-color: transparent;

      border-bottom: 1px solid ${borderColor};

      &:has(.active) {
        background-color: ${activeBgColor};
        border-radius: 6px 6px 0px 0px;
        border: 1px solid ${borderColor};
        border-bottom: none !important;

        .nav-link.active {
          font-weight: 700 !important;
        }
        .active-nav-link {
          font-weight: 700 !important;
          padding: 0 !important;
          color: var(--shp-color-genericui-primary);
        }
      }
    }

    .nav-link {
      margin-right: 0;
      font-weight: 500 !important;
      color: var(--shp-color-text-tab-title);

      &.active:after {
        transform: translate3d(10px, 10px, 10px);
      }
    }
  }

  .wrapper-tab-content {
    background-color: ${activeBgColor};
  }
`;

export default StyledContainer;
