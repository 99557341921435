import { useEffect, useMemo, useState } from 'react';

import { CategoricalChartState } from 'recharts/types/chart/types';

type HandleLineClickType = (data: {
  date: string;
  segment: string;
  payload: any;
}) => void;

export const useGetMultiAreaClickedRow = (
  handleLineClick?: HandleLineClickType,
) => {
  const [singlePointClicked, setSinglePointClicked] = useState<{
    index: number;
    dataKey: string | null;
  }>();

  const [graphDataClicked, setGraphDataClicked] =
    useState<CategoricalChartState>();

  useEffect(() => {
    if (!singlePointClicked || !graphDataClicked) return;

    const data = {
      date: graphDataClicked?.activeLabel ?? '',
      segment:
        singlePointClicked.dataKey ??
        graphDataClicked?.activePayload?.[singlePointClicked.index].dataKey,
      payload: graphDataClicked?.activePayload?.[singlePointClicked.index],
    };

    handleLineClick && handleLineClick(data);
    setSinglePointClicked(undefined);
    setGraphDataClicked(undefined);
  }, [singlePointClicked, graphDataClicked, handleLineClick]);

  return useMemo(
    () => ({
      setSinglePointClicked,
      setGraphDataClicked,
    }),
    [setSinglePointClicked, setGraphDataClicked],
  );
};
