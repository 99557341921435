export type ShipWithModalSelectionTrigger = 'skip' | 'confirm';

export enum PrintLabelMode {
  PDF = 'pdf',
  GLS_ITALY = 'glsitaly',
  BRT = 'brt',
  ZPL = 'zpl',
  PRINT_NODE = 'printnode',
}

export enum PrintLabelTrigger {
  ROW = 'row',
  DETAIL_PAGE = 'detail-page',
  DROPDOWN = 'dropdown',
}

export type CreateInvoiceOrderStatus = 'ToShip' | 'Shipped';
export type CreateInvoiceVariant = 'standard' | 'paperless';
